import { default as DeaLogo } from "../../assets/dea_logo.png";
import { default as DotLogo } from "../../assets/dot_logo.png";
import { default as EpaLogo } from "../../assets/epa_logo.png";
import { default as FldbprLogo } from "../../assets/fldbpr_logo.png";
import { default as NabpSeal } from "../../assets/nabp_seal.png";
import { default as NCPA } from "../../assets/ncpa.jpeg";
import { default as Arkansas_license } from "../../assets/Arkansas_license.jpg";
import { default as texas_License } from "../../assets/texas_License.jpg";
import { default as Wisconsin_license } from "../../assets/Wisconsin_license.pdf";
import { default as Illinois_License } from "../../assets/Illinois_License.pdf";
import { default as MICHIGAN_LICENSE } from "../../assets/MICHIGAN_WHOLESALE_DISTRIBUTOR_CONTROLLED_LICENSE.pdf";
import { default as New_York_Licenses } from "../../assets/New_York_Licenses.pdf";
import { default as NoLicense } from "../../assets/NoLicense.pdf";
import { default as NJ_Drug_Registration_Verification } from "../../assets/NJ_Drug_Registration_Verification.pdf";

export const logos = [
  {
    altText: "what",
    height: "auto",
    image: DeaLogo,
    width: "auto",
  },
  {
    altText: "what",
    height: "auto",
    image: DotLogo,
    width: "auto",
  },
  {
    altText: "what",
    height: "auto",
    image: EpaLogo,
    width: "auto",
  },
  // {
  //     altText: 'what',
  //     height: 'auto',
  //     image: FldbprLogo,
  //     width: 'auto'
  // },
  // {
  //     altText: 'what',
  //     height: 'auto',
  //     image: NabpSeal,
  //     width: 'auto'
  // }
];
export const ncpLogos = [
  {
    altText: "what",
    height: "auto",
    image: NCPA,
    width: "auto",
  },
];

export const states = [
  {
    text: "New York",
    licenseLink: New_York_Licenses,
  },
  {
    text: "New Jersey",
    licenseLink: NJ_Drug_Registration_Verification,
  },
  {
    text: "Arkansas",
    licenseLink: Arkansas_license,
  },
];
export const mapStates = [
  {
    text: "Alabama",
    requireLicense: true,
    licenseLink: null,
  },
  {
    text: "Alaska",
    requireLicense: false,
    licenseLink: NoLicense,
  },
  {
    text: "Arizona",
    requireLicense: true,
    licenseLink: null,
  },
  {
    text: "Arkansas",
    requireLicense: true,
    licenseLink: Arkansas_license,
  },
  {
    text: "California",
    requireLicense: true,
    licenseLink: null,
  },
  {
    text: "Colorado",
    requireLicense: false,
    licenseLink: NoLicense,
  },
  {
    text: "Connecticut",
    requireLicense: true,
    licenseLink: null,
  },
  {
    text: "Delaware",
    requireLicense: true,
    licenseLink: null,
  },
  {
    text: "District of Columbia",
    requireLicense: true,
    licenseLink: null,
  },
  {
    text: "Florida",
    requireLicense: true,
    licenseLink: null,
  },
  {
    text: "Georgia",
    requireLicense: true,
    licenseLink: null,
  },
  {
    text: "Hawaii",
    requireLicense: false,
    licenseLink: NoLicense,
  },
  {
    text: "Idaho",
    requireLicense: false,
    licenseLink: NoLicense,
  },
  {
    text: "Illinois",
    requireLicense: true,
    licenseLink: Illinois_License,
  },
  {
    text: "Indiana",
    requireLicense: true,
    licenseLink: null,
  },
  {
    text: "Iowa",
    requireLicense: true,
    licenseLink: null,
  },
  {
    text: "Kansas",
    requireLicense: true,
    licenseLink: null,
  },
  {
    text: "Kentucky",
    requireLicense: true,
    licenseLink: null,
  },
  {
    text: "Louisiana",
    requireLicense: true,
    licenseLink: null,
  },
  {
    text: "Maine",
    requireLicense: false,
    licenseLink: NoLicense,
  },
  {
    text: "Maryland",
    requireLicense: true,
    licenseLink: null,
  },
  {
    text: "Massachusetts",
    requireLicense: false,
    licenseLink: NoLicense,
  },
  {
    text: "Michigan",
    requireLicense: true,
    licenseLink: MICHIGAN_LICENSE,
  },
  {
    text: "Minnesota",
    requireLicense: true,
    licenseLink: null,
  },
  {
    text: "Mississippi",
    requireLicense: true,
    licenseLink: null,
  },
  {
    text: "Missouri",
    requireLicense: true,
    licenseLink: null,
  },
  {
    text: "Montana",
    requireLicense: true,
    licenseLink: null,
  },
  {
    text: "Nebraska",
    requireLicense: false,
    licenseLink: NoLicense,
  },
  {
    text: "Nevada",
    requireLicense: true,
    licenseLink: null,
  },
  {
    text: "New Hampshire",
    requireLicense: true,
    licenseLink: null,
  },
  {
    text: "New Jersey",
    requireLicense: true,
    licenseLink: NJ_Drug_Registration_Verification,
  },
  {
    text: "New Mexico",
    requireLicense: true,
    licenseLink: null,
  },
  {
    text: "New York",
    requireLicense: true,
    licenseLink: New_York_Licenses,
  },
  {
    text: "North Carolina",
    requireLicense: true,
    licenseLink: null,
  },
  {
    text: "North Dakota",
    requireLicense: true,
    licenseLink: null,
  },
  {
    text: "Ohio",
    requireLicense: true,
    licenseLink: null,
  },
  {
    text: "Oklahoma",
    requireLicense: true,
    licenseLink: null,
  },
  {
    text: "Oregon",
    requireLicense: true,
    licenseLink: null,
  },
  {
    text: "Pennsylvania",
    requireLicense: true,
    licenseLink: null,
  },
  {
    text: "Rhode Island",
    requireLicense: true,
    licenseLink: null,
  },
  {
    text: "South Carolina",
    requireLicense: true,
    licenseLink: null,
  },
  {
    text: "South Dakota",
    requireLicense: true,
    licenseLink: null,
  },
  {
    text: "Tennessee",
    requireLicense: true,
    licenseLink: null,
  },
  {
    text: "Texas",
    requireLicense: true,
    licenseLink: texas_License,
  },
  {
    text: "Utah",
    requireLicense: true,
    licenseLink: null,
  },
  {
    text: "Vermont",
    requireLicense: false,
    licenseLink: NoLicense,
  },
  {
    text: "Virginia",
    requireLicense: true,
    licenseLink: null,
  },
  {
    text: "Washington",
    requireLicense: true,
    licenseLink: null,
  },
  {
    text: "West Virginia",
    requireLicense: true,
    licenseLink: null,
  },
  {
    text: "Wisconsin",
    requireLicense: true,
    licenseLink: Wisconsin_license,
  },
  {
    text: "Wyoming",
    requireLicense: true,
    licenseLink: null,
  },
];
