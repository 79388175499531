import { Box, Grid, Typography } from "@material-ui/core";
import React from "react";
import { FormFieldAtomProps } from "../../atoms/FormField/FormFieldAtomTypes";
import { TextBox } from "../../atoms/Text Box/TextBox";
import {
  UnderlinedInputField,
  UnderlinedInputFieldProps,
} from "../../atoms/UnderlinedInputField/UnderlinedInputField";
import { largeFontSize } from "../../utilities/style";
import FormFieldAtom from './../../atoms/FormField/FormFieldAtom';
import { useStyles } from "./UnderlinedInputFieldSectionStyle";
import { Wrapper } from "./UnderlinedInputFieldSectionStyle";

export interface UnderlinedInputFieldSectionProps {
  title?: string;
  fields: Array<UnderlinedInputFieldProps | FormFieldAtomProps>;
  control: any;
  isEditMode: boolean;
  formTitle?: string;
  disableAccountNumber?: boolean;
}

export const UnderlinedInputFieldSection: React.FC<UnderlinedInputFieldSectionProps> = (
  props: UnderlinedInputFieldSectionProps
) => {
  const { title, fields, control, isEditMode = false, formTitle, disableAccountNumber } = props;

  const classes = useStyles(props);

  const loadFields = () => {
    return fields.map((field, i) => {
      if ('discriminator' in field) {
        field = field as FormFieldAtomProps;
        const label = createLabelBasedOn(field.controllingName)
        return loadFormFieldAtom(field, label, i);
      } else {
        field = field as any as UnderlinedInputFieldProps;
        return loadUnderlinedInputField(field, i);
      }
    });
  };

  const createLabelBasedOn = (controllingName: string) => {
    if (controllingName.includes('companyType')) {
      return "Company Type"
    }
    if (controllingName.includes('state')) {
      return "State"
    }
    return "";
  }

  const loadFormFieldAtom = (field: FormFieldAtomProps, label: string, index: number) => {
    const isAccountNumberField = field.controllingName === "accountNumber";

    return (
      <Grid key={index} item sm={6}>
        <Wrapper>
          <Typography
            align="left"
            className={classes.label}
          >
            {label}
          </Typography>
          <FormFieldAtom
            {...field}
            control={control}
            controllingName={formTitle + "." + field.controllingName}
            disabled={isAccountNumberField ? disableAccountNumber : !isEditMode}
            className={{ root: classes.rootSelect, select: classes.select }}
          />
        </Wrapper>
      </Grid>
    );
  }

  const loadUnderlinedInputField = (field: UnderlinedInputFieldProps, index: number) => {
    const isDisabled = !isEditMode || (disableAccountNumber ?? false) && field.controlingName === 'accountNumber';

    return (
      <Grid key={index} item sm={6}>
        <UnderlinedInputField
          discriminator="underlinedInputField"
          disabled={isDisabled}
          control={control}
          controlingName={formTitle + "." + field.controlingName}
          label={field.label}
          value={field.value}
          rules={field.rules}
        />
      </Grid>
    );
  }

  return (
    <Box width={"100%"}>
      {title ? (
        <TextBox
          title={title}
          headerFontSize={largeFontSize.fontSize}
          titleColor={"#A61C14"}
        />
      ) : (
        ""
      )}
      <br />
      <Grid container spacing={3} justifyContent={"space-around"}>
        {loadFields()}
      </Grid>
    </Box>
  );
};
