import React, { useState, useEffect, useRef } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useForm } from "react-hook-form";
import moment from 'moment';
import { useMutation } from "react-query";
import { useStyles, SelectWrapper } from './CreateLabelPageStyles';
import { CallToActionButton } from '../../atoms/callToActionButton/CallToActionButton';
import { largeButton } from '../../utilities/style';
import SelectAtom from '../../atoms/InputSelect/Select';
import Label from '../../atoms/Label/Label';
import { Typography, Link, Checkbox } from '@material-ui/core';
import DatePickerAtom from '../../atoms/DatePicker/DatePicker';
import useApiService from "../../utilities/useApiService";
import { startLoading, stopLoading } from "../../context/actions/loaderActions";
import { useLoaderDispatch, useLoaderState } from '../../context/LoaderContext';
import Loader from '../../atoms/Loader/LoaderAtom';
import { default as Terms } from "../../assets/TermsConditions.pdf";
import { default as ServiceFees } from "../../assets/ServiceFees.pdf";
import { default as RxMaxServiceAgreement } from "../../assets/RXMAXSERVICEAGREEMENT.pdf";
import { default as PharmaceuticalServicesAgreement } from "../../assets/PharmaceuticalServicesAgreementUpdated.pdf";
import {  nextDays, isSameDay } from './ShipmentDateLogic';

export interface CreateLabelPageProps { }

enum Labels {
    maxNumber = 10
}

const getOptionsForLabelsSelect = (maxNumOfLabels: number) => {
    let options: Array<{ value: string, label: string }> = [];
    for (let i = 1; i <= maxNumOfLabels; i++) {
        options.push({ value: `${i}`, label: `${i}` });
    }
    return options;
}

const CreateLabelPage: React.FC<CreateLabelPageProps> = (
    props: CreateLabelPageProps
) => {
    const history = useHistory();
    const params: any = useParams();
    const { postMethod } = useApiService();
    const { pharmacyId, requestId } = params;
    const { control, handleSubmit, setValue } = useForm();
    const classes = useStyles(props);
    const [date, setDate] = useState<Date>();
    const [firstAgreement, setFirstAgreement] = useState(false);
    const [secondAgreement, setSecondAgreement] = useState(false);
    const firstRender = useRef(true);
    const loaderDispatch = useLoaderDispatch();
    const { loading } = useLoaderState();


    let options: Array<{ value: string, label: string }> = getOptionsForLabelsSelect(Labels.maxNumber);

    const postCreateShipments = async (data: any) => {
        let response;
        startLoading(loaderDispatch);
        response = await postMethod(`/pharmacies/${pharmacyId}/returnrequests/${requestId}/shipments`, data);
        stopLoading(loaderDispatch);
        return response;
    }
    const { mutate: createShipments } = useMutation(postCreateShipments, {
        onSuccess: (data: any) => {
            console.log("DATA:: ", data)
            history.push(`/pharmacies/${pharmacyId}/returnrequests/${requestId}/shipments`);
        }
    });

    useEffect(() => {
        if (firstRender.current) {
            setValue("labels", "1");
            firstRender.current = false;
        }
    }, [])

    const handleClick = handleSubmit((data) => {
        const formattedDate = moment(date).format('YYYY-MM-DD');
        const dataForPost = {
            packages: parseInt(data.labels),
            pickupDate: formattedDate,
        }
        createShipments(dataForPost);
    })

      
    return loading ? (
        <Loader />
    ) : (
        <div>
            <form className={classes.form}>
                <div className={classes.InputWithLabelWrapper}>
                    <div className={classes.InputWithLabel}>
                        <Label label="Number of Labels" className={classes.label} />
                        <SelectWrapper>
                            <SelectAtom
                                control={control}
                                controllingName="labels"
                                // placeHolder={options[0].label}
                                options={options}
                                className={{
                                    root: classes.rootSelect,
                                    select: classes.select
                                }}
                                rules={{
                                    required: "this field is required",
                                }}
                            />
                        </SelectWrapper>
                    </div>
                </div>
                <div className={classes.InputWithLabelWrapper}>
                    <div className={classes.InputWithLabel}>
                        <Label label="Pickup Date" className={classes.label} />
                            <DatePickerAtom
                                value={date}
                                onChange={(date: Date) => {
                                    setDate(date);
                                }}
                                minDate={nextDays[0]}
                                maxDate={nextDays[6]}
                                filterDate={(date) => 
                                    nextDays.some(enabledDate => isSameDay(enabledDate, date))}
                            />
                    </div>
                </div>
                <Typography variant='h6' style={{ marginBottom: "0.5rem" }}>*Pickup will occur between 10:00 AM and 3:00 PM</Typography>
                <div className={classes.agreement}>
                    <Checkbox
                        color="primary"
                        inputProps={{ "aria-label": "secondary checkbox" }}
                        onChange={() => setFirstAgreement(!firstAgreement)}
                        checked={firstAgreement}
                        className={classes.checkbox}
                    />
                    <Typography>
                        I have read and agreed to the{" "}
                        <Link href={Terms} target="_blank" className={classes.link} underline="always">Terms & Conditions</Link>
                        ,{" "}
                        <Link href={ServiceFees} target="_blank" className={classes.link} underline="always">Service Fees{"\n"}</Link>
                        ,{" "}
                        <Link href={RxMaxServiceAgreement} target="_blank" className={classes.link} underline="always">RxMax Services Agreement</Link>{" "}
                        and{" "}
                        <Link href={PharmaceuticalServicesAgreement} target="_blank" className={classes.link} underline="always">Pharmaceutical Services Agreement</Link>
                    </Typography>
                </div>
                <div className={classes.agreement}>
                    <Checkbox
                        color="primary"
                        inputProps={{ "aria-label": "secondary checkbox" }}
                        onChange={() => setSecondAgreement(!secondAgreement)}
                        checked={secondAgreement}
                        className={classes.checkbox}
                    />
                    <Typography>
                        I will not ship controlled substances to RxMaxReturns
                    </Typography>
                </div>
                <Typography style={{ marginTop: "0.5rem" }}>
                    *RxMax LLC is required to notify the DEA of any receipt of unsolicited controlled substances
                </Typography>
            </form>
            <div style={{ marginTop: 20, marginBottom: 20 }}>
                <CallToActionButton
                    height={'80px'}
                    text='Create Shipments'
                    width={largeButton.width}
                    onClick={handleClick}
                    disabled={!firstAgreement || !secondAgreement}
                />
            </div>
        </div >
    )
};

export default CreateLabelPage;
