import React, { useReducer } from 'react';
import { initialState, AuthReducer, IUser } from './reducers/authReducer';


type ContextProps = {
    user: IUser,
    token: string,
    loading: boolean,
    errorMessage: string
};

const AuthStateContext = React.createContext<Partial<ContextProps>>({});
const AuthDispatchContext = React.createContext<Partial<any>>({})

export function useAuthState() {
    const context = React.useContext(AuthStateContext)
    if (context === undefined) {
        throw new Error('useAuthState must be used within a AuthProvider');
    }

    return context;
}

export function useAuthDispatch() {
    const context = React.useContext(AuthDispatchContext);
    if (context === undefined) {
        throw new Error('useAuthDispatch must be used within a AuthProvider');
    }

    return context;
}

export const AuthProvider: React.FC = ({ children }) => {
    const [user, dispatch] = useReducer(AuthReducer, initialState);

    return (
        <AuthStateContext.Provider value={user}>
            <AuthDispatchContext.Provider value={dispatch}>
                {children}
            </AuthDispatchContext.Provider>
        </AuthStateContext.Provider>
    );
};