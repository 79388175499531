import React from "react";
import { TextBoxProps } from "../../atoms/Text Box/TextBox";
import { CardProps } from "../../molecules/Card/CardMolecule";
import { CarouselMolecule } from "../../molecules/Carousel/CarouselMolecule";
import { AboutUsOrganism } from "../../organisms/landingPage/aboutUs/AboutUsOrganism";
import { OurServicesOrganism } from "../../organisms/landingPage/ourServices/OurServicesOrganism";
import { Container } from "./LandingPageStyles";
import { default as Group300 } from "../../assets/pharma.jpeg";
import { RedStrip } from '../../organisms/landingPage/RedStrip/RedStrip';
import { stripConstants } from '../../StringConstants';
import { firstCards, secondCards, whatDoWeDoCards, textBoxes } from './LandingPageConstants';
import * as strings from "../../utilities/strings";

export interface LandingPageProps {
    firstCards: Array<CardProps>;
    secondCards: Array<CardProps>;
    textBoxes: Array<TextBoxProps>;
}

export const LandingPage: React.FC<LandingPageProps> = (props: LandingPageProps) => {

    return (
        <Container>
            <CarouselMolecule cards={whatDoWeDoCards} version={1} title="What do we do ?" />
            <OurServicesOrganism
                title="How Do We Do It ?"
                image={Group300}
                textBoxes={textBoxes}
            />
            <CarouselMolecule cards={firstCards} version={1} title="What are we ?" />
            <AboutUsOrganism
                title={strings.whoWeAre}
                image={Group300}
                paragraphs={[
                    strings.landAboutUs
                ]}
            />
            <CarouselMolecule cards={secondCards} version={2} title="Licenses" />
            <RedStrip
                text={stripConstants.text}
                buttonText={stripConstants.buttonText}
            ></RedStrip>

        </Container>
    );
};

//export default withRouter(LandingPage);

