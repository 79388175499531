import React, { forwardRef, useImperativeHandle, useRef } from "react";
import ReactToPrint from "react-to-print";
import { CallToActionButton } from "../callToActionButton/CallToActionButton";

export interface PrintButtonProps {
  labelRef: React.RefObject<HTMLDivElement>,
  buttonText: string, 
  buttonWidth?: string,
  buttonHeight?: string
}

export interface PrintButtonHandle {
  triggerPrint: () => void;
}

export const PrintButton = forwardRef<PrintButtonHandle, PrintButtonProps>((props, ref) => {
  const { labelRef, buttonText, buttonWidth, buttonHeight } = props;
  const reactToPrintRef = useRef<ReactToPrint>(null);
  const hasTriggeredRef = useRef(false);

  useImperativeHandle(ref, () => ({
    triggerPrint: () => {
      if (reactToPrintRef.current && !hasTriggeredRef.current) {
        reactToPrintRef.current.handlePrint();
        hasTriggeredRef.current = true;
      }
    },
  }));

  return (
    <>
        <ReactToPrint
          ref={reactToPrintRef}
          trigger={() => <></>}
          content={() => labelRef?.current ?? null}
        />
        <CallToActionButton
          width={buttonWidth}
          height={buttonHeight}
          text={buttonText}
          onClick={() => {
            hasTriggeredRef.current = false;
            reactToPrintRef.current?.handlePrint();
          }}
        />
      </>
  );
});
