import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { RouteComponentProps, useHistory, withRouter } from "react-router-dom";
import { default as LoginImage } from "../../assets/login_pic.png";
import IPage from "../../config/interfaces/page";
import { useAuthDispatch } from "../../context/AuthContext";
import { useLoaderDispatch, useLoaderState } from "../../context/LoaderContext";
import { loginAdmin } from "../../network/Services";
import AdminLoginFormOrganism from "../../organisms/AdminLoginForm/AdminLoginFormOrganism";
import * as strings from "../../utilities/strings";
import { Container, CardsWrapper, ImageWrapper } from "./AdminLoginPageStyle";
import Loader from "../../atoms/Loader/LoaderAtom";
import { toastFail } from "../../utilities/toast";
import AdminFooter from "../../organisms/adminFooter/AdminFooterOrganism";
export interface AdminLoginPageProps {}

const AdminLogin: React.FC<
  AdminLoginPageProps & RouteComponentProps<any> & IPage
> = (props) => {
  const { handleSubmit, control, watch } = useForm();
  const username = watch(strings.username);
  const password = watch(strings.password);
  const dispatch = useAuthDispatch();
  const { loading } = useLoaderState();
  const loaderDispatch = useLoaderDispatch();
  const history = useHistory();

  const { mutate } = useMutation(
    () => loginAdmin({ username, password, dispatch, loaderDispatch, history }),
    {
      // onSuccess: (res) => {
      //   if (!res) {
      //     toastFail("Wrong Email or Password");
      //   }
      // },
    }
  );
  const onSubmit = handleSubmit((data) => {
    mutate();
  });

  useEffect(() => {
    const listener = (event: any) => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        event.preventDefault();
        onSubmit();
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, [onSubmit]);

  return (
    <Container style={{ height: window.innerHeight }}>
      {loading ? (
        <Loader />
      ) : (
        <CardsWrapper>
          <ImageWrapper>
            <img src={LoginImage} alt="login" />
          </ImageWrapper>
          <AdminLoginFormOrganism
            onForgetPasswordClick={() => {
              history.push("/admin/forget/password");
            }}
            onSubmit={onSubmit}
            control={control}
          />
        </CardsWrapper>
      )}
      <AdminFooter/>
    </Container>
  );
};

export default withRouter(AdminLogin);
