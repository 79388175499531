import moment from "moment";
import { IWholesaler } from "../../network/models/Interfaces";

export const createChooseWholesalerForm = (wholesalers: Array<IWholesaler>) => {
   return [
      [
         {
            controllingName: "wholesaler",
            placeHolder: "Choose wholesaler",
            isSelect: true,
            rules: {
               required: "This field is required",
            },
            options: getOptions(wholesalers)
         },
      ]
   ]
}

const getOptions = (wholesalers: Array<IWholesaler>) => {
   if(wholesalers) {
      return wholesalers.map((wholesaler: IWholesaler) => ({
         value: wholesaler.id.toString(),
         label: wholesaler.name
      }))
   }
}

export const createSelectDateForm = () => {
   return [
      [
         {
           controllingName: "preferredDate",
           type: "date",
           placeHolder: "Select your preferred date",
           minDate: moment().format("YYYY-MM-DD"),
           rules: {
             required: "This field is required",
           },
         },
       ]
   ]
}