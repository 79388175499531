import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { mediumFontSize, smallFontSize } from "../../utilities/style";
import { FooterOrganismProps } from "./FooterOrganism";

export interface FooterOrganismStylesProps {}
export const useStyles = makeStyles<Theme, FooterOrganismProps>(
  (theme: Theme) =>
    createStyles({
      container: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        background:
          "transparent linear-gradient(180deg, #323A87 0%, #211D57 100%) 0% 0% no-repeat padding-box",
      },
      root: {
        minWidth: 275,
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: 466,
        [theme.breakpoints.down("xs")]: {
          flexDirection: "column",
          height: "auto",
        },
      },
      divider: {
        color: "#FFFFFF",
        maxHeight: 236,
        backgroundColor: "#FFFFFF",
        [theme.breakpoints.down("xs")]: {
          height: 1,
          width: "60%",
        },
      },
      subtitle: {
        fontSize: mediumFontSize.fontSize,
        fontWeight: "bold",
        textAlign: "left",
        color: "#ffffff",
      },
      sectionLogo: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        margin: theme.spacing(5),
        flexDirection: "column",
        maxHeight: 200,
      },
      sectionContent: {
        display: "flex",
        alignItems: "right",
        justifyContent: "space-evenly",
        margin: theme.spacing(5),
        flexDirection: "column",
        color: "#ffffff",
        [theme.breakpoints.down("xs")]: {},
      },
      sectionLinks: {
        display: "flex",
        alignItems: "left",
        justifyContent: "flex-start",
        margin: theme.spacing(5),
        flexDirection: "column",
        color: "#ffffff",
        width: 340,
        marginTop: -20,
        marginLeft: theme.spacing(12),
        [theme.breakpoints.down("xs")]: {
          marginLeft: 50,
          alignSelf: "start",
          marginTop: theme.spacing(5),
        },
      },
      content: {
        fontSize: smallFontSize.fontSize,
        textTransform: "capitalize",
        borderRadius: 15,
        color: "#ffffff",
        justifyContent: "flex-start",
        "&:hover": {
          background: "transparent",
        },
        "&:active": {
          background: "transparent",
        },
      },
      entry: {
        display: "flex",
        alignItems: "center",
        alignContent: "left",
        justifyContent: "flex-start",
        textAlign: "left",
        margin: theme.spacing(1),
      },
      icon: {
        marginRight: theme.spacing(2),
      },
      button: {
        width: 280,
        height: 58,
        border: "2px solid #ffffff",
        color: "#ffffff",
        textTransform: "capitalize",
        borderRadius: 15,
        fontSize: mediumFontSize.fontSize,
      },
      socials: {
        display: "flex",
        justifyContent: "center",
        margin: theme.spacing(1),
      },
      epaLogo: {
        marginBottom: theme.spacing(4),
        "&:hover": {
          cursor: "pointer",
        },
      },
      copyrightContainer: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        color: "#ffffff",
        margin: "20px"
      },
    })
);
