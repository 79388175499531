import React, { useState } from "react";
import { useQuery } from "react-query";
import { CallToActionButton } from "../../atoms/callToActionButton/CallToActionButton";
import TableOrganism from "../../organisms/Table/TableOrganism";
import { createHeaderRow } from "../../utilities/CreateHeaderRow";
import { useStyles } from "./AccountManagmentPageStyles";
import { createAccountManagmentRows } from "./AccountManagmentTable";
import { useHistory, useParams } from "react-router-dom";
import { useLoaderDispatch, useLoaderState } from "../../context/LoaderContext";
import { startLoading, stopLoading } from "../../context/actions/loaderActions";
import Loader from "../../atoms/Loader/LoaderAtom";
import { largeButton } from "../../utilities/style";
import useApiService from "../../utilities/useApiService";
import { UserRole } from "../../utilities/Enums";
import { isAdmin, isWholesalerUser } from '../../utilities/checkUserRole';
import { useAuthState } from "../../context/AuthContext";
import { usePharmacyEnabledStatus } from '../../context/PharmacyEnabledStatusContext';


export interface AccountManagmentPageProps { }

export const AccountManagmentPage: React.FC<AccountManagmentPageProps> = (
  props
) => {
  const { user } = useAuthState();
  const userRole = user!.role;
  const history = useHistory();
  const { getMethod, postMethod } = useApiService();
  const loaderDispatch = useLoaderDispatch();
  const { loading } = useLoaderState();
  const params: any = useParams();
  const classes = useStyles(props);

  const [showAddPharmacyButton, setShowAddPharmacyButton] = useState<boolean>(false)
  const { pharmacyEnabledStatusMap, setInitialValues } = usePharmacyEnabledStatus();

  const fetchTable = async () => {
    startLoading(loaderDispatch);
    let response;
    switch (userRole) {
      case UserRole.Admin:
      case UserRole.Warehouse:
      case UserRole.FieldProcessor:
        response = await getMethod(`/pharmacies/users/${params.userId}/pharmacies`);
        break;
      case UserRole.WholesalerUser:
        response = await getMethod(`/pharmacies/management-wholesaleruser`);
        break;
      default:
        response = await getMethod("/pharmacies/management");
        Array.isArray(response.data) && setInitialValues(response.data);
    }
    stopLoading(loaderDispatch);
    return response.data;
  };
  const { data } = useQuery("accountManagmentTable", fetchTable, {
    onSuccess: (data) => {
      if (data === '') {
        setShowAddPharmacyButton(true)
      }
    }
  });
  
  const getHeaderTitles = () => {
    let headerTitles = ["Pharmacy", "Returns"];
    if (!isWholesalerUser(userRole)) {
      headerTitles.push("Start Return");
    }
    return headerTitles;
  }

  const rows: Array<any> = data
    ? createAccountManagmentRows(data, history, userRole, postMethod, pharmacyEnabledStatusMap)
    : [];

  const renderAddPharmacyButton = () => {
    if (showAddPharmacyButton) {
      return (
        <div className={classes.buttonWrapper}>
          <CallToActionButton
            height={"80px"}
            text="Add Pharmacy"
            width={largeButton.width}
            onClick={() =>
              history.location.pathname.indexOf("admin") !== -1
                ? history.push(`/admin/users/${params.userId}/pharmacy/add`)
                : history.push("/pharmacy/add")
            }
          />
        </div>
      )
    }
  }

  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <>
          {renderAddPharmacyButton()}
          <TableOrganism header={createHeaderRow(getHeaderTitles())} rows={rows} />
        </>
      )}
    </div>
  );
};
