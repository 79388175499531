import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { smallFontSize } from '../../utilities/style';
import { SmallBlueButtonAtomProps } from './SmallBlueButtonAtom';



export const useStyles = makeStyles<Theme, SmallBlueButtonAtomProps>((theme: Theme) =>
    createStyles({
        button: () => ({

            color: '#323A87',
            fontWeight: 'bold',
            '&:hover': {
                background: 'transparent'
            },
            '&:active': {
                background: 'transparent'
            },
            alignSelf: "start",
            underline: "always",
            fontSize: smallFontSize.fontSize
        })
    }),
);