import { SelectOption, ReportTypeOptions } from "../../utilities/SelectOptions";

export const getReportForm = (wholesalerSelectOptions: Array<SelectOption>) => {
  return [
    [
      {
        controllingName: "type",
        placeHolder: "Choose Report Type",
        isSelect: true,
        options: ReportTypeOptions,
        rules: {
          required: "This field is required",
        },
      },
    ],
    [
      {
        controllingName: "description",
        placeHolder: "Description",
        rules: {
          required: "This field is required",
        },
      },
    ],
    [
      {
        controllingName: "wholesaler",
        placeHolder: "Choose wholesaler",
        isSelect: true,
        options: wholesalerSelectOptions,
        rules: {
          required: "This field is required",
        },
      },
    ],
  ];
};
