import styled from "styled-components";

export const Container = styled.div`
  margin: 0 40px 0 40px;
  .label {
    font: normal normal bold 28px/34px Roboto;
    color: #a61c14;
    margin-bottom: 40px;
    display: flex;
  }
`;

export const HeaderWrapper = styled.div`
  margin-top: 74px;
  margin-bottom: 54px;
`;
