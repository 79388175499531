import { TableRowProps } from "../../molecules/TableRow/TableRowAtom";
import { WholesalerUsersTable } from "../../network/models/TableInterfaces";
import { createRow } from '../../utilities/CreateRow';
import {
  createInfoCell,
  createClickableCell
} from "../../utilities/CreateCell";

export const createWholesalerUsersTable = (
  data: Array<WholesalerUsersTable>,
  setOpenConfirmDelete: any,
  setToBeDeletedObject: any,
  setOpenEditWholesalerUserDialog: any,
  setCurrentWholesalerUser: any
) => {
  const rows: Array<TableRowProps> = [];
  data.forEach((wholesalerUser) => {
    const row: TableRowProps = createRow(
      createInfoCell(wholesalerUser.user.id.toString()),
      createInfoCell(wholesalerUser.user.username),
      createInfoCell(wholesalerUser.wholesaler.name),
      createClickableCell(() => {
        setCurrentWholesalerUser(wholesalerUser)
        setOpenEditWholesalerUserDialog(true);
      }, "Edit"),  
      createClickableCell(() => {
        setOpenConfirmDelete(true);
        setToBeDeletedObject({ userId: wholesalerUser.user.id });
      }, "Remove"),
    )
    rows.push(row);
  })
  return rows;
}