import React from 'react'
import { Button } from "@material-ui/core";
import { useStyles } from "./HeaderButtonStyles";



export interface ButtonProps {
    label: string,
    textColor: string,
    width: string,
    icon: string,
    background: string,
    fontSize: number,
    onClick: React.MouseEventHandler
}

export const HeaderButton: React.FC<ButtonProps> = (props: ButtonProps) => {
    const { label = 'Button', icon, onClick } = props
    const classes = useStyles(props)
    
    return (
        <Button onClick={onClick} variant="text" startIcon={<img src={icon} alt="" />} className={classes.button} >
            {label}
        </Button>
    );
}