import React from 'react'
import { withRouter } from 'react-router-dom';
import { TextBox } from '../../atoms/Text Box/TextBox';
import * as strings from '../../utilities/strings'
import { mediumFontSize, smallFontSize } from '../../utilities/style';
export interface ComingSoonPageProps {

}

export const ComingSoonPage: React.FC<ComingSoonPageProps> = (props: ComingSoonPageProps) => {

    return (
        <TextBox
        headerFontSize={mediumFontSize.fontSize}
        bodyFontSize={smallFontSize.fontSize}
        title={''}
        paragraphs={[strings.comingSoonText]}
        image={''}
        titleColor={'#403F3F'}
        newLine={true}
    />
    );
}

export default withRouter(ComingSoonPage)