export const printStyles = `
.print-header {
  margin:10px;
  display: flex;
  justify-content: space-between;
  font-size: 20px;
  margin-bottom: 20px;
  color: #A61C14; 
}
.total {
  font-size: 20px;
  text-align: right;
  margin-right: 35px;
  color: #323a87; 
}
@media print {
  body {
    width: fit-content;
  }
  @page {
    size: landscape;
  }
  .print-title {
    text-align: center;
    margin-bottom: 20px;
    color: #A61C14;   
  }
  .exclude-print {
    display: none;
  }
  .print-table {
    width: 100%;
    border-collapse: collapse;
    transform: scale(0.9)
  }
  .print-table th, .print-table td {
    padding: 8px;
  }

  .print-table td:nth-child(1) {
    white-space: nowrap;
  }

  .print-table th:nth-child(7), td:nth-child(7) {
    display: none;
  }

}

@media screen {
  .print-title{
    display: none;
  }
}
`;