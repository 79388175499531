import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { BaseCSSProperties } from '@material-ui/core/styles/withStyles';
import { LogoProps } from "./Logo";

export interface LogoStylesStyleProps {
    image: BaseCSSProperties   
}
export const useStyles = makeStyles<Theme, LogoProps>((theme: Theme) =>
    createStyles({
        image: (props: LogoProps) => ({
           width: props.width,
           height: props.height
        }),
    }),
);