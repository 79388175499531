import React from "react";
import { useStyles, Container } from "./RegisterPageStyle";
import { useForm } from "react-hook-form";
import {
  BigRedButton,
  ButtonVersion,
} from "../../atoms/BigRedButton/BigRedButtonAtom";
import { registerUser } from "../../network/Services";
import { useHistory, withRouter } from "react-router-dom";
import FormMolecule from "../../molecules/Form/FormMolecule";
import { useEffect } from "react";
import { useAuthState } from "../../context/AuthContext";
import { useLoaderDispatch, useLoaderState } from "../../context/LoaderContext";
import Loader from "../../atoms/Loader/LoaderAtom";

export interface SignInPageProps {}
export const RegisterPage: React.FC<SignInPageProps> = (props) => {
  const { handleSubmit, control, getValues } = useForm();
  const history = useHistory();
  const { token } = useAuthState();
  const loaderDispatch = useLoaderDispatch();
  const { loading } = useLoaderState();
  const onSubmit = handleSubmit((data) => {
    registerUser(
      {
        username: data.firstName,
        password: data.password,
        email: data.email,
      },
      history,
      loaderDispatch
    );
  });
  const classes = useStyles(props);
  useEffect(() => {
    if (token) {
      history.push("/");
    }
  }, [token, history]);
  return loading ? (
    <Loader />
  ) : (
    <Container>
      <form onSubmit={onSubmit}>
        <div className={classes.cardStyle}>
          <FormMolecule
            control={control}
            list={[
              [
                {
                  controllingName: "firstName",
                  placeHolder: "User Name",
                  rules: {
                    required: "This Field is Required",
                  },
                },
              ],
              [
                {
                  controllingName: "email",
                  placeHolder: "Email",
                  rules: {
                    required: "This Field is Required",
                    validate: (val: string) => {
                      if (/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(val))
                        return true;
                      return "Please enter a valid email";
                    },
                  },
                },
              ],
              [
                {
                  controllingName: "password",
                  placeHolder: "password",
                  type: "password",
                  rules: {
                    required: "This Field is Required",
                    validate: (val: string) => {
                      if(val.trim().length>=6){
                        return true;
                      }
                      return "Password should have a minimum length of 6 characters";
                    },
                  },
                },
              ],
              [
                {
                  controllingName: "confirmPassword",
                  placeHolder: "Password Confirmation",
                  type: "password",
                  rules: {
                    required: "This Field is Required",
                    validate: () =>
                      getValues("confirmPassword") !== getValues("password")
                        ? "password does not match !"
                        : true,
                  },
                },
              ],
            ]}
          />
          <div className={classes.outerMarginSecondCard}>
            <BigRedButton
              version={ButtonVersion.LARGE}
              text={"Register"}
              type="submit"
              onClick={() => {
                console.log("submitted");
              }}
            />
          </div>
        </div>
      </form>
    </Container>
  );
};

export default withRouter(RegisterPage);
