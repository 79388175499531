import { TextField } from "@material-ui/core";
import React from "react";
import { Controller } from "react-hook-form";
import { useStyles } from "./UnderlinedInputFieldStyle";

export interface UnderlinedInputFieldProps {
  discriminator: 'underlinedInputField';
  control?: any;
  controlingName: string;
  placeHolder?: string;
  type?: string;
  label: string;
  value: string;
  disabled: boolean;
  rules?: any;
}

export const UnderlinedInputField: React.FC<UnderlinedInputFieldProps> = ({
  type,
  placeHolder,
  value,
  label,
  controlingName,
  control,
  disabled,
  children,
  rules,
  ...props
}) => {
  const classes = useStyles(props);
  return (
    <Controller
      control={control}
      name={controlingName}
      defaultValue={value}
      rules={rules}
      render={({ field, fieldState: { error } }) => (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <TextField
            key={controlingName}
            className={classes.textField}
            variant="standard"
            InputLabelProps={{
              className: classes.label,
              shrink: true,
            }}
            InputProps={{
              className: classes.input,
            }}
            {...field}
            placeholder={placeHolder}
            type={type}
            label={label}
            disabled={disabled}
          >
            {children}
          </TextField>
          <span className={classes.errorMessage}>{error && error.message}</span>
        </div>
      )}
    />
  );
};
