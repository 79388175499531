import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { BaseCSSProperties } from '@material-ui/core/styles/withStyles';
import { UnderlinedInputFieldSectionProps } from './UnderlinedInputFieldSection';
import styled from "styled-components";

export const useStyles = makeStyles<Theme, UnderlinedInputFieldSectionProps>((theme: Theme) =>
    createStyles({
        label: (props: any) => ({
            color: props.isEditMode ? "rgba(0, 0, 0, 1)" : "rgba(0, 0, 0, 0.38)"
        }),
        rootSelect: (props: any) => ({
            border: "1px solid",
            borderRadius: 15,
            borderColor: props.isEditMode ? "rgba(0, 0, 0, 0.60)" : "rgba(0, 0, 0, 0.23)",
            backgroundColor: "white",
            "&:focus": {
                borderColor: props.isEditMode ? "rgba(0, 0, 0, 0.60) !important" : "rgba(0, 0, 0, 0.23) !important",
                borderRadius: 15,
                backgroundColor: "white",
            },
            [theme.breakpoints.down(430)]: {
                borderRadius: 10,
            }
        }),
        select: (props: any) => ({
            padding: "0 !important",
            paddingLeft: "18px !important",
            width: "75%",
            font: "normal normal normal 20px/34px Roboto, Arial",
            color: props.isEditMode ? "rgba(0, 0, 0, 1)" : "rgba(0, 0, 0, 0.38)",
            height: "40px",
            [theme.breakpoints.down(430)]: {
                fontSize: "18px"
            },
        }),
    }),
);

export const Wrapper = styled.div`
  .MuiInputBase-root {
    width: 100%;
  }

  .MuiSvgIcon-root {
    position: relative;
    right: 2rem
  }
`;

