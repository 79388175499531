import React from "react";
import { useQuery } from "react-query";
import { useHistory, useParams } from "react-router-dom";
import useApiService from "../../utilities/useApiService";
import { toastFail, toastSuccess } from "../../utilities/toast";

export interface ActivateAccountPageProps {}

const ActivateAccountPage: React.FC<ActivateAccountPageProps> = (
  props
) => {
  const history = useHistory();
  const { getMethod } = useApiService();
  const params: any = useParams();
  const { token } = params;
  const getActivateAccount = async () => {
      const response = await getMethod(`/activateaccount?token=${token}`);
      console.log("Response", response)
      return response.data;
  };
  const { data, isSuccess } = useQuery("ActivateAccount", getActivateAccount);
  if(isSuccess) {
    history.push('/signin');
    toastSuccess("Your account has been activated you can login now");
  } else {
    history.push('/');
    toastFail("Account activation failed");
  }

  return <></>;
};

export default ActivateAccountPage;