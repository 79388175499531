import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { largeButton, mediumFontSize } from "../../utilities/style";
import { BigRedButtonAtomProps } from "./BigRedButtonAtom";

export const useStyles = makeStyles<Theme, BigRedButtonAtomProps>(
  (theme: Theme) =>
    createStyles({
      buttonLarge: {
        width: largeButton.width,
        [theme.breakpoints.down("sm")]: {
          width: largeButton.width,
          height: 45,
          textAlign: "center",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        },
        height: "15%",
        borderRadius: "15px",
        textTransform: "capitalize",
        color: "white",
        padding: 10,
        fontSize: mediumFontSize.fontSize,

        // background: " transparent linear-gradient(90deg, ##A61C14 20%, ##530E0A 100%)",
        background:
          "transparent linear-gradient(90deg, #A61C14 80%, #530E0A 100%) 0% 0% no-repeat padding-box;",
      },
      buttonSmall: {
        width: "100%",
        [theme.breakpoints.down("sm")]: {
          width: "auto",
        },
        height: "15%",
        borderRadius: "15px",
        textTransform: "capitalize",
        color: "white",
        padding: 10,
        fontSize: mediumFontSize.fontSize,

        // background: " transparent linear-gradient(90deg, ##A61C14 20%, ##530E0A 100%)",
        background:
          "transparent linear-gradient(90deg, #A61C14 80%, #530E0A 100%) 0% 0% no-repeat padding-box;",
      },
    })
);
