import React, { useState, useRef } from 'react';
import { useQuery } from "react-query";
import Loader from '../../atoms/Loader/LoaderAtom';
import { useLoaderDispatch, useLoaderState } from '../../context/LoaderContext';
import { startLoading, stopLoading } from "../../context/actions/loaderActions";
import { useHistory, useParams } from 'react-router-dom';
import useApiService from '../../utilities/useApiService';
import { useStyles } from './LabelPageStyles';
import { CallToActionButton } from '../../atoms/callToActionButton/CallToActionButton';
import { mediumButton } from '../../utilities/style';
import { useReactToPrint } from 'react-to-print';


export interface LabelPageProps { }

const LabelPage: React.FC<LabelPageProps> = (
    props: LabelPageProps
) => {
    const { getMethod } = useApiService();
    const history = useHistory();
    const params: any = useParams();
    const classes = useStyles(props);
    const { pharmacyId, requestId, shipmentId } = params;
    const loaderDispatch = useLoaderDispatch();
    const { loading } = useLoaderState();
    const labelRef = useRef(null);

    const handlePrint = useReactToPrint({
        content: () => labelRef.current
      });

    const fetchShippingLabel = async () => {
        let response;
        startLoading(loaderDispatch);
        response = await getMethod(`/pharmacies/${pharmacyId}/returnrequests/${requestId}/shipments/${shipmentId}/label`);
        stopLoading(loaderDispatch);
        return response.data;
    }
    const { data: shippingLabel } = useQuery('shippingLabel', fetchShippingLabel);

    return loading ? (
        <Loader />
    ) : (
        <div>
            <div className={classes.container}>
                <div className={classes.rotate}>
                    <img ref={labelRef} src={`data:image/jpeg;base64,${shippingLabel}`} alt={`UPS Label of shipment ${shipmentId}`} className={classes.label} />
                </div>
            </div>
            <CallToActionButton
                height={'80px'}
                text='Print Label'
                width={mediumButton.width}
                onClick={handlePrint}
            />
        </div>
    );
};

export default LabelPage;
