import { Box, Container, Grid, Link, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react'
import { TextBox } from '../../../atoms/Text Box/TextBox';
import { useStyles } from './AboutUsOrganismStyles';
import ArrowRightIconBlue from '../../../assets/right-arrow-blue.svg';
import { default as Group300 } from "../../../assets/Group 300.png";
import { useHistory } from 'react-router-dom';
import { smallFontSize } from '../../../utilities/style';
import * as strings from "../../../utilities/strings"
export interface AboutUsOrganismProps {
    backgroundColor?: '#FBFBFB' | 'white',
    title?: string,
    image?: string,
    paragraphs: Array<string>
}

export const AboutUsOrganism: React.FC<AboutUsOrganismProps> = (props: AboutUsOrganismProps) => {

    const { title = strings.whoWeAre, image = Group300, paragraphs } = props

    let history = useHistory()

    const classes = useStyles(props)

    const [windowSize, setWindowSize] = useState<boolean>(window.screen.availWidth > 500);
    useEffect(() => {
        const handleWindowResize = () => setWindowSize(window.innerWidth > 500 ? true : false);
        window.addEventListener("resize", handleWindowResize);


        return () => window.removeEventListener("resize", handleWindowResize);
    }, []);

    return (
        <Container className={classes.containerGrey} maxWidth='xl'>
            <Grid container justifyContent={'flex-start'}>
                <Grid item sm={6} xs={12}>
                    <TextBox
                        bodyFontSize={smallFontSize.fontSize}
                        headerFontSize={40}
                        image={''}
                        paragraphs={paragraphs} title={title} titleColor={'#403F3F'} />
                    <Box className={classes.readmoreBlue}>
                        <Link underline='none' onClick={() => history.push('about')} component="button">
                            <Typography className={classes.subtitleBlue}>
                                Read More
                                <img className={classes.arrowBlue} src={ArrowRightIconBlue} alt="" />
                            </Typography>
                        </Link>

                    </Box>
                </Grid>
                {windowSize ? (<Grid item sm={6} xs={12}>
                    <img src={image} className={classes.img} alt="" />
                </Grid>) : <div></div>}
            </Grid>
        </Container>

    );
}