import { MouseEventHandler } from "react";
import { SelectOption } from "./SelectOptions";

const createCell = (
  bold: boolean,
  clickable: boolean,
  header: boolean,
  onClick: MouseEventHandler | undefined,
  text: string,
  isSwitch?: boolean,
  checked?: boolean,
  children?: any,
  isRounded?: boolean,
  isSelect?: boolean,
  haveBorder?: boolean,
  selectValue?: any,
  selectOnChange?: (event: any) => void,
  selectOptions?: SelectOption[],
  isCustom?: boolean,
  shouldSort?: boolean,
  handleSort?: () => void,
  isCheckBox?: boolean,
) => {
  return {
    bold: bold,
    clickable: clickable,
    header: header,
    onClick: onClick,
    text: text,
    isSwitch: isSwitch,
    checked: checked,
    children: children,
    isRounded: isRounded,
    isSelect: isSelect,
    haveBorder: haveBorder,
    selectValue: selectValue,
    selectOnChange: selectOnChange,
    selectOptions: selectOptions,
    isCustom: isCustom,
    shouldSort,
    handleSort,
    isCheckBox
  };
};

export const createSelectCell = (
  selectOptions: SelectOption[],
  selectValue: any,
  selectOnChange: (event: any) => void
) => {
  return createCell(
    false,
    false,
    false,
    undefined,
    '',
    false,
    false,
    undefined,
    false,
    true,
    false,
    selectValue,
    selectOnChange,
    selectOptions,
    false,
    false,
    undefined,
    false
  );
}



export const createClickableCell = (
  onClick: MouseEventHandler,
  text: string
) => {
  return createCell(
    false,
    true,
    false,
    onClick,
    text,
    false,
    false,
    undefined,
    false,
    false,
    false,
    undefined,
    undefined,
    undefined,
    false,
    false,
    undefined,
    false
  );
};

export const createInfoCell = (text: string) => {
    return createCell(
      false,
      false,
      false,
      undefined,
      text,
      false,
      false,
      undefined,
      false,
      false,
      false,
      undefined,
      undefined,
      undefined,
      false,
      false,
      undefined,
      false
    );
};

export const createBoldInfoCell = (text: string) => {
  return createCell(
    true,
    false,
    false,
    undefined,
    text,
    false,
    false,
    undefined,
    false,
    false,
    false,
    undefined,
    undefined,
    undefined,
    false,
    false,
    undefined,
    false
  );
};

export const createHeaderCell = (
  text: string,
  shouldSort?: boolean,
  handleSort?: () => void
) => {
  return createCell(
    true,
    false,
    true,
    undefined,
    text,
    false,
    false,
    undefined,
    false,
    false,
    false,
    undefined,
    undefined,
    undefined,
    false,
    shouldSort,
    handleSort,
    false
  );
};

export const createSwitchCell = (
  checked: boolean,
  pharmacyId: string,
  onClick: any
) => {
  return createCell(false, false, false, onClick, pharmacyId, true, checked);
};

export const createCellWithChildren = (children: any) => {
  return createCell(
    false,
    false,
    false,
    undefined,
    '',
    false,
    false,
    children,
    false,
    false,
    false,
    undefined,
    undefined,
    undefined,
    true,
    false,
    undefined,
    false
  );
};


export const createCheckCell = (
  checked: boolean,
  onClick: any
) => {
  return createCell(
    false,
    false,
    false,
    onClick,
    '',
    false,
    checked,
    undefined,
    false,
    false,
    false,
    undefined,
    undefined,
    undefined,
    false,
    false,
    undefined,
    true
  );
};
