
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { BaseCSSProperties } from '@material-ui/core/styles/withStyles';
import { mediumFontSize, smallFontSize } from '../../utilities/style';
import { CardProps } from './CardMolecule';

export interface CardStylesStyleProps {
    cardCarousel: BaseCSSProperties,
    title: BaseCSSProperties,
    paragraph: BaseCSSProperties
}
export const useStyles = makeStyles<Theme, CardProps>((theme: Theme) =>
    createStyles({
        cardCarousel: (props:CardProps) => ({
            textAlign: 'left',
            backgroundColor: '#FFFFFF',
            padding: theme.spacing(1),
            height: props.height ? props.height : 'auto'
        }),
        title: {
            fontSize: mediumFontSize.fontSize,
            color: '#403F3F',
            fontWeight: 'bold',
            marginBottom: 18
        },
        paragraph: {
            fontSize: smallFontSize.fontSize,
            color: '#707070',
        },
    }),
);