export function loginUserAttempt(dispatch: any) {
  dispatch({ type: "REQUEST_LOGIN" });
}

export function loginUserPassed(dispatch: any, data?: any) {
  dispatch({ type: "LOGIN_SUCCESS", payload: data });
  return data;
}

export function loginUserFailed(dispatch: any, data?: any) {
  dispatch({ type: "LOGIN_ERROR", error: data });
  return;
}

export async function logout(dispatch: any) {
  dispatch({ type: "LOGOUT" });
  localStorage.setItem("direction", "logout");
  localStorage.removeItem("currentUser");
  localStorage.removeItem("token");
}

