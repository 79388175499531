import React from 'react'
import { useStyles } from './PictureStyles';

export interface PictureAtomProps {
    height?: string,
    width?: string,
    image: string,
    altText: string
}

export const PictureAtom: React.FC<PictureAtomProps> = (props: PictureAtomProps) => {

    const { image, altText} = props

    const classes = useStyles(props)

    return (
        <div className={classes.root}>
            <img src={image} alt={altText} />
        </div>
    );
}