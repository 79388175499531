export const callUsFormData = [
  [
    {
      controllingName: "pharmacyName",
      placeHolder: "Pharmacy Name",
      rules: {
        required: "This field is required",
      },
    },
    {
      controllingName: "email",
      placeHolder: "Email",
      rules: {
        required: "This field is required",
        validate: (val: string) => {
          if (/^[a-zA-Z0-9-.]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(val)) return true;
          return "Please enter a valid email";
        }
      },
    },
  ],
  [
    {
      controllingName: "name",
      placeHolder: "Contact name",
      rules: {
        required: "This field is required",
      },
    },
    {
      controllingName: "phoneNumber",
      placeHolder: "Phone Number",
      rules: {
        required: "This field is required",
        validate: (val: string) => {
          // Assuming a simple check for 10-digit numeric phone number
          if (/^\d{10}$/.test(val))  return true;
          return "Please enter a valid 10-digit phone number";
        }
      }
    },
  ],
  [
    {
      controllingName: "address",
      placeHolder: "Address",
      rules: {
        required: "This field is required",
      },
    },
    {
      controllingName: "body",
      placeHolder: "Message",
      rules: {
        required: "This field is required",
      },
      isMultiline: true,
      maxRows: 7
    },
  ],

];