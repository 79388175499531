import { IconButton } from '@material-ui/core';
import React from 'react'

interface IconProps {
    icon: string,
    onClick: React.MouseEventHandler
}

export const Icon: React.FC<IconProps> = (props: IconProps) => {
    const { icon, onClick } = props

    return (
        <IconButton onClick={onClick}>
            <img src={icon} alt="" />
        </IconButton>
    );
}