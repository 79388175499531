import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { RouteComponentProps, useHistory, withRouter } from "react-router-dom";
import { default as LoginImage } from "../../assets/WholesalerUserLogin.png";
import IPage from "../../config/interfaces/page";
import { useAuthDispatch } from "../../context/AuthContext";
import { useLoaderDispatch, useLoaderState } from "../../context/LoaderContext";
import { loginWholesalerUser } from "../../network/Services";
import * as strings from "../../utilities/strings";
import {
  Container,
  CardsWrapper,
  ImageWrapper,
  FormWrapper,
} from "./WholesalerUserLoginPageStyle";
import Loader from "../../atoms/Loader/LoaderAtom";
import AdminFooter from "../../organisms/adminFooter/AdminFooterOrganism";
import FormMolecule from "../../molecules/Form/FormMolecule";
import { formForWholesalerUserLogin } from "./FormData";
import { ReactComponent as LogoAlternate } from "../../assets/logoAlternate.svg";
import Label from "../../atoms/Label/Label";
import {
  BigRedButton,
  ButtonVersion,
} from "../../atoms/BigRedButton/BigRedButtonAtom";

export interface WholesalerUserLoginPageProps {}

const WholesalerUserLoginPage: React.FC<
  WholesalerUserLoginPageProps & RouteComponentProps<any> & IPage
> = (props) => {
  const { handleSubmit, control } = useForm();
  const dispatch = useAuthDispatch();
  const { loading } = useLoaderState();
  const loaderDispatch = useLoaderDispatch();
  const history = useHistory();

  const callLoginApi = async ({
    username,
    password,
  }: {
    username: string;
    password: string;
  }) => {
    loginWholesalerUser({
      username,
      password,
      dispatch,
      loaderDispatch,
      history,
    });
  };
  const { mutate: login } = useMutation(callLoginApi);

  const onSubmit = handleSubmit((data) => {
    const { username, password } = data;
    login({ username, password });
  });

  useEffect(() => {
    const listener = (event: any) => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        event.preventDefault();
        onSubmit();
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, [onSubmit]);

  return (
    <Container style={{ height: window.innerHeight }}>
      {loading ? (
        <Loader />
      ) : (
        <CardsWrapper>
          <FormWrapper>
            <LogoAlternate />
            <Label label="Wholesaler User Login" />
            <form>
              <FormMolecule
                control={control}
                list={formForWholesalerUserLogin}
              />
            </form>
            <BigRedButton
              text="Login"
              version={ButtonVersion.LARGE}
              type="submit"
              onClick={onSubmit}
            />
          </FormWrapper>
          <ImageWrapper>
            <img src={LoginImage} alt="Wholesaler User Login" />
          </ImageWrapper>
        </CardsWrapper>
      )}
      <AdminFooter />
    </Container>
  );
};

export default withRouter(WholesalerUserLoginPage);
