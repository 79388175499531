import "./App.css";
import { Switch, BrowserRouter } from "react-router-dom";
import { ReactQueryDevtools } from "react-query/devtools";
import { QueryClient, QueryClientProvider } from "react-query";
import ScrollToTop from "./utilities/ScrollToTop";
import { RXRouter } from "./config/router";
import { AuthProvider } from "./context/AuthContext";
import { LoaderProvider } from "./context/LoaderContext";
import { PharmacyEnabledStatusProvider } from "./context/PharmacyEnabledStatusContext";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { JobStatusProvider } from "./context/JobStatusContext";
import { NotificationCountProvider } from "./context/NotificationContext";

const queryClient = new QueryClient();
queryClient.setDefaultOptions({
  queries: {
    refetchOnWindowFocus: false,
  },
});
const App = () => {
  return (
    <LoaderProvider>
      <AuthProvider>
        <NotificationCountProvider>
          <PharmacyEnabledStatusProvider>
            <QueryClientProvider client={queryClient}>
              <div className="App">
                <ToastContainer
                  className={"toast"}
                />
                <BrowserRouter>
                  <ScrollToTop />
                  <Switch>
                    <JobStatusProvider>
                      <RXRouter />
                    </JobStatusProvider>
                  </Switch>
                </BrowserRouter>
              </div>
              <ReactQueryDevtools initialIsOpen={false} />
            </QueryClientProvider>
          </PharmacyEnabledStatusProvider>
        </NotificationCountProvider>
      </AuthProvider>
    </LoaderProvider>
  );
};

export default App;
