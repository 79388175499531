import { Box, Container, Grid, Link, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react'
import { useStyles } from './OurServicesOrganismStyles';
import ArrowRightIconRed from '../../../assets/right-arrow-red.svg';
import { FourTextPapers } from '../../../molecules/FourTextPapers/FourTextPapers';
import { TextBoxProps } from '../../../atoms/Text Box/TextBox';
import { useHistory } from 'react-router-dom';


export interface OurServicesOrganismProps {
    backgroundColor?: string
    title?: string,
    image?: string,
    textBoxes: Array<TextBoxProps>
}

export const OurServicesOrganism: React.FC<OurServicesOrganismProps> = (props: OurServicesOrganismProps) => {

    const { image, title, textBoxes } = props

    let history = useHistory()

    const classes = useStyles(props)

    const [windowSize, setWindowSize] = useState<boolean>(window.screen.availWidth > 500);
    useEffect(() => {
        const handleWindowResize = () => setWindowSize(window.innerWidth > 500 ? true : false);
        window.addEventListener("resize", handleWindowResize);


        return () => window.removeEventListener("resize", handleWindowResize);
    }, []);
    return (
        <Container className={classes.containerWhite} maxWidth='xl'>
            <Box display="flex" justifyContent="center">
                <Typography className={classes.title}>
                    {title}
                </Typography>
            </Box>
            <Grid spacing={0} container justifyContent="center">
                {windowSize ? <Grid className={classes.imageWrapper} item sm={6}>
                    <img className={classes.image} src={image} alt="" />
                </Grid> : <div></div>}
                <FourTextPapers textBoxes={textBoxes} />
                <Grid item sm={6}></Grid>
                <Grid item sm={6} xs={12} className={classes.readmoreRed}>
                    <Link underline='none' onClick={() => history.push('/services')} component="button">
                        <Typography className={classes.subtitleRed}>
                            Read More
                            <img className={classes.arrow} src={ArrowRightIconRed} alt="" />
                        </Typography>
                    </Link>
                </Grid>
            </Grid>
        </Container>
    );
}