import styled from "styled-components";

export const FourTextWrapper = styled.div`
  margin-top: 40px;
  .MuiGrid-grid-sm-6 p {
      margin-top: 18px;
  }
  @media screen and (min-width: 600px) {
    .MuiGrid-grid-sm-6 {
      max-width: 100% !important;
    }
  }
`;
