import { Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useStyles, Wrapper, Container } from "./HeaderOrganismStyles";
import { default as Banner } from "../../assets/banner.webp";
import {
  TextButton,
  TextButtonVersion,
} from "../../atoms/TextButton/TextButton";
import { HeaderMolecule } from "../../molecules/Header/HeaderMolecule";
import { Background } from "../../atoms/HeaderContent/Background/Background";
import { HeaderNavbar } from "../../molecules/HeaderContent/HeaderLogo+Menu/HeaderNavbar";
import { MenuItemProps } from "../../atoms/HeaderContent/MenuItem/MenuItem";
import { useHistory } from "react-router-dom";
import * as strings from '../../utilities/strings'

export interface HeaderOrganismProps {
  menuItems?: Array<MenuItemProps>;
}

export const HeaderOrganism: React.FC<HeaderOrganismProps> = (
  props: HeaderOrganismProps
) => {
  let history = useHistory();
  const [zIndex, setZIndex] = useState(9999);
  const [width, setWidth] = useState("0%");

  const classes = useStyles(props);

  useEffect(() => {
    document.body.style.overflow = "unset";
  });

  const openNav = () => {
    setWidth("100%");
    document.body.style.overflow = "hidden";
    setZIndex(-1);
  };

  const closeNav = () => {
    setWidth("0%");
    document.body.style.overflow = "unset";
    setZIndex(9999);
  };

  return (
    <Container>
      <HeaderMolecule openNav={openNav} closeNav={closeNav} width={width} />
      <Wrapper style={{ zIndex }}>
        <Background imageUrl={Banner}>
          <div className={classes.logoIcon}>
            <HeaderNavbar
              showBurger={false}
              openNav={openNav}
              closeNav={closeNav}
              width={width}
            />
          </div>
          <div className={classes.mainContent}>
            <Typography style={{ zIndex }} className={classes.mainContentText}>
              {[strings.slogan]}
            </Typography>
            <TextButton
              zIndex={zIndex}
              onClick={() => {
                localStorage.getItem('token') ? history.push("/pharmacymanagement") : history.push("/signin")
              }}
              text={strings.startReturnButton}
              version={TextButtonVersion.LARGE}
            />
          </div>
        </Background>
      </Wrapper>
    </Container>
  );
};
