import { createStyles, makeStyles, Theme } from "@material-ui/core";
import styled from "styled-components";
import { mediumFontSize } from "../../utilities/style";

export interface ContactUsPageStylesProps {}
export const useStyles = makeStyles<Theme, ContactUsPageStylesProps>(
  (theme: Theme) =>
    createStyles({
      paper: {
        padding: theme.spacing(2),
        textAlign: "left",
        color: theme.palette.text.secondary,
        display: "flex",
      },
      icon: {
        color: "#323A87",
      },
      info: {
        fontSize: mediumFontSize.fontSize,
        color: "#403F3F",
        marginLeft: 8,
      },
    })
);

export const ImageWrapper = styled.div`
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;
