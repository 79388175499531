import React, { useState, useEffect, useRef } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import TableOrganism from "../../organisms/Table/TableOrganism";
import { createHeaderRow } from "../../utilities/CreateHeaderRow";
import Loader from "../../atoms/Loader/LoaderAtom";
import { useLoaderDispatch, useLoaderState } from "../../context/LoaderContext";
import { startLoading, stopLoading } from "../../context/actions/loaderActions";
import { useParams } from "react-router-dom";
import { TableRowProps } from "../../molecules/TableRow/TableRowAtom";
import { createReportsRows } from "./CreateReportsTable";
import { CallToActionButton } from "../../atoms/callToActionButton/CallToActionButton";
import { largeButton } from "../../utilities/style";
import useApiService from "../../utilities/useApiService";
import CreateReportDialog from "./CreateReportDialog";
import download from "downloadjs";
import AreYouSure from "../../molecules/AreYouSure/AreYouSure";
import { useAuthState } from "../../context/AuthContext";
import { isAdmin, isNormalUser } from "../../utilities/checkUserRole";
import { IReport } from "../../network/models/Interfaces";
import { usePharmacyEnabledStatus } from "../../context/PharmacyEnabledStatusContext";

export interface ReportsPageProps {}

export const ReportsPage: React.FC<ReportsPageProps> = (
  props: ReportsPageProps
) => {
  const { user } = useAuthState();
  const userRole = user!.role;
  const { getMethod, deleteMethod } = useApiService();
  const queryClient = useQueryClient();
  const params: any = useParams();
  const { pharmacyId, requestId } = params;
  const loaderDispatch = useLoaderDispatch();
  const { loading } = useLoaderState();

  const [openCreateReport, setOpenCreateReport] = useState<boolean>(false);
  const [currentReport, setCurrentReport] =
    useState<{ id: number; originalFileName: string } | undefined>(undefined);
  const [openConfirmDelete, setOpenConfirmDelete] = useState<boolean>(false);
  const [toBeDeletedObject, setToBeDeletedObject] = useState({});
  const { pharmacyEnabledStatusMap } = usePharmacyEnabledStatus();
  const isReportIdDefined = useRef(false);

  const listReportsApi = async () => {
    let response;
    startLoading(loaderDispatch);
    response = await getMethod(
      `/pharmacies/${pharmacyId}/returnrequests/${requestId}/reports`
    );
    stopLoading(loaderDispatch);
    const data: Array<IReport> = response.data;
    return data;
  };
  const { data: reports } = useQuery("reports", listReportsApi);

  const downloadReportApi = async ({
    id,
    originalFileName,
  }: {
    id: number;
    originalFileName: string;
  }) => {
    let response;
    startLoading(loaderDispatch);
    response = await getMethod(
      `/pharmacies/${pharmacyId}/returnrequests/${requestId}/reports/${id}`,
      {
        responseType: "blob",
      }
    );
    stopLoading(loaderDispatch);
    return { fetchedFile: response.data, originalFileName };
  };

  const { refetch: downloadFile } = useQuery(
    ["download-file", currentReport],
    () => downloadReportApi(currentReport!),
    {
      enabled: false,
      onSuccess: ({ fetchedFile, originalFileName }) => {
        download(fetchedFile, originalFileName, "application/pdf");
      },
    }
  );

  const deleteReportApi = async ({ id }: any) => {
    startLoading(loaderDispatch);
    await deleteMethod(
      `/admin/pharmacies/${pharmacyId}/returnrequests/${requestId}/reports/${id}`
    );
    stopLoading(loaderDispatch);
    return id;
  };
  const { mutate: deleteReport } = useMutation(deleteReportApi, {
    onSuccess: (id) => {
      const newReports = [...reports!];
      newReports.forEach((item, i) => {
        if (item.id === id) {
          newReports.splice(i, 1);
          return;
        }
      });
      queryClient.setQueryData("reports", newReports);
    },
  });

  useEffect(() => {
    if (isReportIdDefined.current) {
      downloadFile();
    } else {
      isReportIdDefined.current = true;
    }
  }, [currentReport]);

  const getHeaderTitles = () => {
    let headerTitles = ["Type", "Description"];
    if (isAdmin(userRole)) {
      headerTitles.push("Wholesaler");
    }
    headerTitles.push("File");
    if (isAdmin(userRole)) {
      headerTitles.push("");
    }
    return headerTitles;
  };

  const rows: Array<TableRowProps> = reports
    ? createReportsRows(
        reports,
        userRole,
        setOpenConfirmDelete,
        setToBeDeletedObject,
        setCurrentReport
      )
    : [];

  const renderCreateReportButton = () => {
    if (isAdmin(userRole)) {
      return (
        <div style={{ marginTop: 20, marginBottom: 20 }}>
          <CallToActionButton
            height={"80px"}
            text="Create New Report"
            width={largeButton.width}
            onClick={() => {
              setOpenCreateReport(true);
            }}
            disabled={!pharmacyEnabledStatusMap[params.pharmacyId]}
          />
        </div>
      );
    }
  };

  const renderCreateReportDialog = () => {
    if (openCreateReport) {
      return (
        <CreateReportDialog
          open={openCreateReport}
          onClose={() => setOpenCreateReport(false)}
        />
      );
    }
  };

  const renderConfirmDeleteDialog = () => {
    if (openConfirmDelete) {
      return (
        <AreYouSure
          open={openConfirmDelete}
          onClose={() => {
            setOpenConfirmDelete(false);
          }}
          deleteObject={deleteReport}
          toBeDeletedObject={toBeDeletedObject}
        />
      );
    }
  };

  return loading ? (
    <Loader />
  ) : (
    <div>
      <TableOrganism header={createHeaderRow(getHeaderTitles())} rows={rows} />
      {renderCreateReportButton()}
      {renderCreateReportDialog()}
      {renderConfirmDeleteDialog()}
    </div>
  );
};

export default ReportsPage;
