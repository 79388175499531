import { HeaderOrganism } from "../organisms/header/HeaderOrganism";
import SmallHeaderOrganism from "../organisms/smallheader/SmallHeaderOrganism";
import IRoute from "./interfaces/route";
import routes from "./routes";
import { default as LoginImage } from "../assets/loginHeaderImage.png";
import { Redirect, Route, RouteComponentProps } from "react-router-dom";
import { FooterOrganism } from "../organisms/footer/FooterOrganism";
import { useAuthState } from "../context/AuthContext";
import AdminHeader from "../organisms/adminHeader/AdminHeaderOrganism";
import { toastFail, toastSuccess } from "../utilities/toast";
import AdminFooter from "../organisms/adminFooter/AdminFooterOrganism";
import { useHistory } from "react-router-dom";
import { isWholesalerUser } from "../utilities/checkUserRole";

export const RXRouter: React.FC = (props) => {
  const renderHeader = (route: IRoute, children: any) => {
    if (route.name === "Landing Page") {
      return (
        <div>
          <HeaderOrganism />
          {children}
          <FooterOrganism />
        </div>
      );
    } else if (
      route.path.indexOf("changePassword") !== -1 ||
      route.path.indexOf("admin/login") !== -1 ||
      route.path.indexOf("admin/forget/password") !== -1 ||
      route.path.indexOf("/wholesaler-user/login") !== -1
    ) {
      return <>{children}</>;
    } else if (route.path.indexOf("admin") !== -1) {
      return (
        <div>
          <div style={{ minHeight: "75vh" }}>
            <AdminHeader />
            {children}
          </div>
          <AdminFooter />
        </div>
      );
    } else {
      return (
        <>
          <SmallHeaderOrganism title={route.name} image={LoginImage}>
            {children}
          </SmallHeaderOrganism>
          <FooterOrganism />
        </>
      );
    }
  };

  const handleLogoutAndUnauthenticatedAccessToPrivatePages = () => {
    if (localStorage.getItem('direction') == 'logout') {
      localStorage.removeItem('direction');
    } else {
      toastFail("You are not logged in ");
    }
  }

  const redirectBasedOnUserRole = (routePath: string) => {
    console.log("Route path: ", routePath);
    const isWholesalerUser = routePath.indexOf("wholesaler-user") !== -1;
    if (isWholesalerUser) {
      return (
        <Redirect to={{ pathname: "/wholesaler-user/login" }} />
      );
    }
    return (
      <Redirect to={{ pathname: "/signin" }} />
    );
  }

  const userDetails = useAuthState();

  return (
    <div>
      {routes.map((route, index) => {
        return (
          <Route
            key={index}
            path={route.path}
            exact={route.exact}
            render={(props: RouteComponentProps<any>) => {
              if (route.isPrivate && !Boolean(userDetails.token)) {
                handleLogoutAndUnauthenticatedAccessToPrivatePages();
                return redirectBasedOnUserRole(route.path);
              }
              return (
                <div>
                  {renderHeader(
                    route,
                    <route.component {...props} {...route.props} />
                  )}
                </div>
              )
            }}
          />
        );
      })}
    </div>
  );
};
