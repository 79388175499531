import { useHistory } from "react-router-dom";
import { logout } from "../context/actions/authActions";
import { useAuthDispatch } from "../context/AuthContext";
import axios from "../network/ApiConfig";
import { toastFail } from "./toast";
import { AxiosRequestConfig } from "axios";

const useApiService = () => {
  const dispatch = useAuthDispatch();
  const history = useHistory();
  const putMethod = async (url: any, payload?: any) => {
    const res = await axios.put(url, payload).catch((err) => {
      if (err.response?.status === 401) {
        toastFail("Please login");
        logout(dispatch);
      } else if (err.response?.status === 403) {
        toastFail("You are not allowed to enter here !");
        history.push("/");
      }
      return err;
    });
    return res;
  };

  const getMethod = async (url: any, payload?: any) => {
    const res = await axios.get(url, payload).catch((err) => {
      if (err.response.status === 401) {
        toastFail("Please login");
        logout(dispatch);
      } else if (err.response.status === 403) {
        toastFail("You are not allowed to enter here !");
        history.push("/");
      }
      return err;
    });
    return res;
  };

  const postMethod = async (url: any, payload: any, config?: AxiosRequestConfig) => {
    const res = axios.post(url, payload, config).catch((err) => {
      if (err.response.status === 401) {
        toastFail("Please login");
        logout(dispatch);
      } else if (err.response.status === 403) {
        toastFail("You are not allowed to enter here !");
        history.push("/");
      }
      return err;
    });
    return res;
  };

  const deleteMethod = async (url: any) => {
    const res = await axios.delete(url).catch((err) => {
      if (err.response.status === 401) {
        toastFail("Please login");
        logout(dispatch);
      } else if (err.response.status === 403) {
        toastFail("You are not allowed to enter here !");
        history.push("/");
      }
      return err;
    });
    return res;
  };

  return {
    putMethod,
    getMethod,
    postMethod,
    deleteMethod,
  };
};

export default useApiService;
