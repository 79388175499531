import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { PharmacyInfoSectionProps } from "./PharmacyInfoSection";

export const useStyles = makeStyles<Theme, PharmacyInfoSectionProps>(
  (theme: Theme) =>
    createStyles({
      tag: {
        marginBottom: theme.spacing(2),
      },
    })
);
