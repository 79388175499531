import { Container } from "@material-ui/core";
import React from "react";
import { TextBox, TextBoxProps } from "../../atoms/Text Box/TextBox";
import { FourTextWrapper } from "./OurServicesStyles";
import { FourTextPapers } from "../../molecules/FourTextPapers/FourTextPapers";
import { default as SelfService } from "../../assets/SelfService.svg";
import { default as RxDisposal } from "../../assets/RxDisposal.svg";
import { default as FullService } from "../../assets/FullService.svg";
import { default as ExpressService } from "../../assets/ExpressService.svg";
import { default as Inventory } from "../../assets/Inventory.svg"
import * as strings from "../../utilities/strings";
import { mediumFontSize, smallFontSize } from "../../utilities/style";
import { textBoxes } from "../landingpage/LandingPageConstants";

export interface OurServicesPageProps {
  textBoxes: Array<TextBoxProps>;
}

export const OurServicesPage: React.FC<OurServicesPageProps> = (
  props: OurServicesPageProps
) => {

  return (
    <Container>
      <TextBox
        title={""}
        paragraphs={[strings.serviceText]}
        image={""}
        titleColor={"#403F3F"}
        bodyFontSize={18}
        headerFontSize={40}
      />
      <FourTextWrapper>
        <FourTextPapers textBoxes={textBoxes} />
      </FourTextWrapper>
    </Container>
  );
};
