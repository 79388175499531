import React from "react";
import {
  Container,
  IconWrapper,
  TextContainer,
  Label,
} from "./TableWidgetAtomStyle";
export interface TableWidgetAtomProps {
  label: string;
  number: string;
  backgroundColor: string;
  iconBackgroundColor: string;
  icon: any;
  onClick: (value: any) => void;
  buzzer?: JSX.Element
}

const TableWidget: React.FC<TableWidgetAtomProps> = (props) => {
  const {
    label,
    backgroundColor,
    iconBackgroundColor,
    icon,
    onClick,
    buzzer 
  } = props;

  return (
    <Container onClick={onClick} style={{ backgroundColor, marginTop: "1rem" }}>
      <IconWrapper style={{ backgroundColor: iconBackgroundColor }}>
        {icon}
      </IconWrapper>
      <TextContainer>
        <Label>{label}</Label>
        {buzzer}
      </TextContainer>
    </Container>
  );
};
export default TableWidget;
