import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { BaseCSSProperties } from '@material-ui/core/styles/withStyles';
import { TextBoxProps } from './TextBox';

export interface TextBoxStylesProps {
    paper: BaseCSSProperties,
    title: BaseCSSProperties,
    paragraph: BaseCSSProperties
}
export const useStyles = makeStyles<Theme, TextBoxProps>((theme: Theme) =>
    createStyles({
        paper: (props: TextBoxProps) => ({
            textAlign: 'left',
            backgroundColor: props.backgroundColor,
            [theme.breakpoints.down('xs')]: {

            }
        }),
        title: (props: TextBoxProps) => ({
            fontSize: props.headerFontSize,
            color: props.titleColor,
            fontWeight: 'bold',
            [theme.breakpoints.down('xs')]: {
                fontSize: props.headerFontSize ? (props.headerFontSize * 62 / 100) : ''
            }
        }),
        paragraph: (props: TextBoxProps) => ({
            fontSize: props.bodyFontSize,
            fontWeight: props.isTextBold ? 'bold' : 'normal',
            color: props.bodyTextColor != undefined ? props.bodyTextColor : props.titleColor === '#A61C14' ? "#403F3F" : "#707070",
            [theme.breakpoints.down('xs')]: {
                fontSize: props.bodyFontSize ? (props.bodyFontSize * 80 / 100) : ''
            }
        }),
        subTitle: (props: TextBoxProps) => ({
            fontSize: props.headerFontSize,
            color: props.titleColor,
            [theme.breakpoints.down('xs')]: {
                fontSize: props.headerFontSize ? (props.headerFontSize * 30 / 60) : ''
            }
        }),

    }),
);