import styled from "styled-components";
import { mediumFontSize } from "../../utilities/style";

export const FormWrapper = styled.form`
  display: flex;
  width: 60%;
  flex-direction: column;
  margin-top: 60px;
  margin-bottom: 100px;
  margin-left: 100px;
  .MuiTextField-root {
    align-self: flex-start;
    width: 80%;
  }
  > svg {
    margin-left: -10px;
  }
  .label {
    font-size: ${mediumFontSize.fontSize}px;
    color: #323a87;
    margin-top: 29px;
    margin-bottom: 29px;
    font-weight: bold;
    align-self: start;
  }
  .MuiLink-button {
    margin-bottom: 29px;
  }
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 80%;
  margin-top: 20px;
  button {
    width: 45%;
    padding: 0 10px 0 10px;
    height: 60px;
  }
`;

export const Text = styled.span`
  font: normal normal normal 21px/25px Roboto;
  letter-spacing: 0px;
  color: #403f3f;
  opacity: 1;
  margin-bottom: 24px;
  align-self: start;
`;
