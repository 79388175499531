import { Box, Divider, Grid, Paper, SvgIcon, Typography } from '@material-ui/core';
import React from 'react'
import { PictureAtom } from '../../atoms/Picture/PictureAtom';
import { useStyles, ImageWrapper } from './ContactUsPageStyles';
import { default as GoogleMap } from "../../assets/map-location.png";
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import PhoneIcon from '@material-ui/icons/Phone';
import EmailIcon from '@material-ui/icons/Email';
import { withRouter } from 'react-router-dom';
import * as strings from "../../utilities/strings";

export interface ContactUsPageProps {

}

export const ContactUsPage: React.FC<ContactUsPageProps> = (props: ContactUsPageProps) => {

    const classes = useStyles(props)

    return (
        <div>
            <ImageWrapper>
                <PictureAtom altText={"auto"} height={"auto"} image={GoogleMap} width={"auto"} />
            </ImageWrapper>
            <Grid container>
                <Grid item sm={4}>
                    <Paper className={classes.paper} elevation={0}>
                        <SvgIcon component={BusinessCenterIcon} className={classes.icon} />
                        <Typography className={classes.info}>
                            {strings.contactAddress}
                        </Typography>
                    </Paper>
                </Grid>
                <Grid item sm={4}>
                    <Paper className={classes.paper} elevation={0}>
                        <SvgIcon component={BusinessCenterIcon} className={classes.icon} />
                        <Typography className={classes.info}>
                            {strings.contactMobile}
                        </Typography>
                    </Paper>
                </Grid>
                <Grid item sm={4}>
                </Grid>
                <Grid item sm={4}>
                    <Paper className={classes.paper} elevation={0}>
                        <SvgIcon component={PhoneIcon} className={classes.icon} />
                        <Typography className={classes.info}>
                            {strings.contactFax}
                        </Typography>
                    </Paper>
                </Grid>
                <Grid item sm={4}>
                    <Paper className={classes.paper} elevation={0}>
                        <SvgIcon component={EmailIcon} className={classes.icon} />
                        <Typography className={classes.info}>
                            {strings.contactEmail}
                        </Typography>
                    </Paper>
                </Grid>
            </Grid>
            <Box paddingBottom='40px' />
            <Divider />
        </div>
    );
}

export default withRouter(ContactUsPage);