import { Button } from "@material-ui/core";
import React from "react";
import { useStyles } from "./CallToActionButtonStyles";

export interface CallToActionButtonProps {
  text: string;
  width?: string;
  height?: string;
  type?: any;
  onClick?: any;
  disabled?: boolean;
  backgroundStyle?: string;
}

export const CallToActionButton: React.FC<CallToActionButtonProps> = (
  props: CallToActionButtonProps
) => {
  const {text, type, onClick, disabled = false } = props;

  const classes = useStyles(props);

  return (
    <Button
      type={type}
      className={classes.button}
      disableElevation
      variant="contained"
      onClick={onClick}
      disabled={disabled}
    >
      {text}
    </Button>
  );
};
