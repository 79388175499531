import { Button } from "@material-ui/core";
import React from "react";
import { useStyles } from "./TextButtonStyles";

export interface TextButtonProps {
  text: string;
  version: TextButtonVersion; /// very bad approach @ ahmed
  onClick: React.MouseEventHandler;
  zIndex?: any;
  disabled?: boolean;
}
export enum TextButtonVersion {
  SMALL,
  MEDIUM,
  LARGE,
  PRIMARY,
}

export const TextButton: React.FC<TextButtonProps> = (props) => {
  const { version, text, onClick, zIndex, disabled } = props;

  const classes = useStyles(props);
  let className: string = "";
  if (version === TextButtonVersion.SMALL) {
    className = classes.mainContentButton;
  } else if (version === TextButtonVersion.LARGE) {
    className = classes.mainContentButton;
  } else if (version === TextButtonVersion.PRIMARY) {
    className = classes.buttonPrimary;
  } else {
    className = classes.button;
  }
  return (
    <Button
      disabled={disabled}
      style={{ zIndex }}
      onClick={onClick}
      disableRipple
      variant="text"
      className={className}
    >
      {text}
    </Button>
  );
};
