import {
  createStyles,
  makeStyles,
  Theme,
  withStyles,
  StepConnector,
} from "@material-ui/core";
import styled from "styled-components";

export interface CreateAccountStylesProps { }
export const useStyles = makeStyles<Theme, CreateAccountStylesProps>(
  (theme: Theme) =>
    createStyles({
      root: {
        width: "100%",
      },
      button: {
        marginRight: theme.spacing(1),
      },
      instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
      },
      link: {
        fontWeight: "bold",
        color: "#323A87",
        "&:hover": {
          color: "#4551ba"
        },
      },
      sameAddressCheck: {
        display: "flex",
        alignItems: "center"
      }
    })
);

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 20px 0 20px;
`;

export const TermsWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: "#ccc",
    zIndex: 1,
    color: "#fff",
    width: 50,
    height: 50,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
  },
  active: {
    backgroundImage:
      "linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  },
  completed: {
    backgroundImage:
      "linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)",
  },
});

export const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 22,
  },
  active: {
    "& $line": {
      backgroundImage:
        "linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)",
    },
  },
  completed: {
    "& $line": {
      backgroundImage:
        "linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)",
    },
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: "#eaeaf0",
    borderRadius: 1,
  },
})(StepConnector);

export const Container = styled.div`
  .MuiStepLabel-root {
    cursor: pointer;
  }
  .MuiStepLabel-active,
  .MuiStepLabel-completed {
    color: #a61c14;
  }
  .MuiStepLabel-labelContainer {
    margin-top: 10px;
  }
  .MuiFormLabel-root {
    display: flex;
    margin-top: 10px;
    margin-bottom: 10px;
    color: black;
  }
  .MuiFormGroup-root{
    width: fit-content;
  }
`;

export const ButtonContainer = styled.div`
  display: none;

  @media screen and (max-width: 700px) {
    display: flex;
    justify-content: space-between;
  }
`;

export const ButtonWrapper = styled.div`
  .MuiButton-root {
    width: 100%;
    height: 90px;
    margin-top: 30px;
  }
  button:first-child {
    margin-right: 25px;
  }
  .MuiButton-text {
    margin-left: 25px;
    margin-right: 0px !important;
  }
  width: 45%;
  @media screen and (max-width: 600px) {
    button:first-child {
      margin-right: 10px;
      font-size: 20px;
      height: 50px;
    }
    .MuiButton-text {
      margin-left: 10px;
      font-size: 20px;
    }
  }
`;

export const ProceedWrapper = styled.div`
  @media screen and (max-width: 700px) {
    display: none;
  }
`;

export const ListWrapper = styled.div`
  margin-bottom: 3rem;
`

export const SmallBlueButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-top: 1rem;
  margin-bottom: 2rem;
`
