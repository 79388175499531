export const loginQueryKey = "login";
export const adminLoginQueryKey = "adminLogin"
export const createPharmacyQueryKey = "createPharmacy";
export const addItemQueryKey = "addItem";
export const username = "firstName";
export const password = "password";
export const usernamePlaceholder = "User Name";
export const forgetPasswordText = "Forgot your password?";
export const loginText = "Login";
export const DontHaveAccountText = "Don't have an account?";
export const RegisterHereText = "Register here";

// landing page strings will start with "land"

export const landAboutUs =
  " With a team that has over a decade of experience in the pharmaceutical industry, RxMax is the fruit of their labour from which stems our ability to recognize the challenges pharmacies face every day. RxMax’s first priority was addressing those challenges and facilitating the expired pharmaceutical return process as much as we can. At RxMax, we are a team of experienced pharmacy professionals committed to helping our partners reduce pharmacy expense, elevate clinical performance, and maximize patient safety. We rely on our transparency and integrity to deliver the best possible service and help you achieve your highest potential.";
export const landServiceCardLabel1 = "Give Credit for Outdates:";
export const landServiceCardLabel2 = "Healthcare Pharmacy Physical Inventory:";
export const landServiceCardLabel3 = "Disposal";
// export const landServiceCardLabel4 = " Lorem ipsum dolor sit amet";
export const landServiceCardLabel4 = " Inventory:";
export const landServiceCardText1_1 = "This service provides our clients with the complete package. Our certified processor goes right to your doorstep then begins to scan the expired medications, controls, or hazards. Next he packs them with UPS tags and gives a fully detailed report of the findings. After receiving your outdates on site we begin your return request with the right parties.";
export const landServiceCardText1_2 = "How this service works is that the client requests UPS shipping labels through our website then ships the outdates to us. The full scanning and packing procedure happens on company grounds, which means no hassle whatsoever for the client on site. As part of this service, you will be provided a full inventory of the Controlled products, as well as all required DEA 222 forms.";
export const landServiceCardText2 = " We send our licensed team of experts right to your doorstep, delivering the needed inventory data with your specifications and needs in mind. Data is validated against your cost file which we obtain directly from your supplier. We provide flexible scheduling as well as a detailed report including a location report and a previous comparison report. The counting process is performed in your pharmacy from start to finish and the results are immediately available, archived and easily accessed.";
export const landServiceCardText3 = " Busy schedule? No problem. Our third type of service allows our client to scan their outdates through our website and print the required UPS shipping labels, which in turn ships them to us.Afterwards, we’ll take care of the rest of the return process for you. This option is most suitable in the case where there aren’t many products to return to manufacturers on a regular basis.";
// export const landServiceCardText4 = " Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem. Nulla consequat massa quis enim. Donec pede justo, fringilla vel, aliquet nec, vulputate eget, arcu. In enim justo, rhoncus ut, imperdiet a, venenatis vitae, justo. Nullam dictum felis eu pede mollis pretium. Integer tincidunt. Cras dapibus. Vivamus elementum semper nisi. Aenean vulputate eleifend tellus. Aenean leo ligula, porttitor eu, consequat vitae, eleifend ac, enim. Aliquam lorem ante, dapibus in, viverra quis, feugiat a, tellus.";
export const landServiceCardText4 = "RxMax offers secure handling of pharmaceutical waste. We manage pharmaceutical returns after processing and ensure proper disposal. We determine whether the items are hazardous or nonhazardous and provide environmentally sound disposal options. Once items arrive at our licensed facilities, we secure them until we safely dispose of them. We can handle the complete service and disposal of these items in full compliance with all Federal and State agencies. Our service provides you with the responsible, safe and secure destruction of your waste pharmaceuticals. And using our customer returns portal you can create your inventory, request a DEA Form 222, print shipping labels and submit payment."
// footer component strings will start with  "foot"

export const footAddress = "330 Franklin Turnpike, Mahwah NJ 07430";
export const footMobile = "(201) 252-8841";
export const footFax = " (201) 252-8842";
export const footEmail = "info@rxmaxreturns.com";

// contact us page strings will start with "contact"

export const contactAddress = "330 Franklin Turnpike, Mahwah NJ 07430";
export const contactMobile = "(201) 252-8842";
export const contactFax = " (201) 252-8841";
export const contactEmail = "info@rxmaxreturns.com";

// why choose us page strings will start with "why"

export const whyText =
  " Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem. Nulla consequat massa quis enim. Donec pede justo, fringilla vel, aliquet nec, vulputate eget, arcu. In enim justo, rhoncus ut, imperdiet a, venenatis vitae, justo. Nullam dictum felis eu pede mollis pretium. Integer tincidunt. Cras dapibus. Vivamus elementum semper nisi. Aenean vulputate eleifend tellus. Aenean leo ligula, porttitor eu, consequat vitae, eleifend ac, enim. Aliquam lorem ante, dapibus in, viverra quis, feugiat a, tellus.";
export const whyCardLabel1 = " Lorem ipsum dolor sit amet";
export const whyCardLabel2 = " Lorem ipsum dolor sit amet";
export const whyCardLabel3 = " Lorem ipsum dolor sit amet";
export const whyCardLabel4 = " Lorem ipsum dolor sit amet";
export const whyCardLabel5 = " Lorem ipsum dolor sit amet";
export const whyCardLabel6 = " Lorem ipsum dolor sit amet";
export const whyCardText1 =
  " Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem. Nulla consequat massa quis enim. Donec pede justo, fringilla vel, aliquet nec, vulputate eget, arcu. In enim justo, rhoncus ut, imperdiet a, venenatis vitae";
export const whyCardText2 =
  " Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem. Nulla consequat massa quis enim. Donec pede justo, fringilla vel, aliquet nec, vulputate eget, arcu. In enim justo, rhoncus ut, imperdiet a, venenatis vitae";
export const whyCardText3 =
  " Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem. Nulla consequat massa quis enim. Donec pede justo, fringilla vel, aliquet nec, vulputate eget, arcu. In enim justo, rhoncus ut, imperdiet a, venenatis vitae";
export const whyCardText4 =
  " Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem. Nulla consequat massa quis enim. Donec pede justo, fringilla vel, aliquet nec, vulputate eget, arcu. In enim justo, rhoncus ut, imperdiet a, venenatis vitae";
export const whyCardText5 =
  " Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem. Nulla consequat massa quis enim. Donec pede justo, fringilla vel, aliquet nec, vulputate eget, arcu. In enim justo, rhoncus ut, imperdiet a, venenatis vitae";
export const whyCardText6 =
  " Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem. Nulla consequat massa quis enim. Donec pede justo, fringilla vel, aliquet nec, vulputate eget, arcu. In enim justo, rhoncus ut, imperdiet a, venenatis vitae";

// compliance page strings will start with "comp"

export const compText =
  "RxMax is federally licensed by the DEA, EPA and DOT as well as the State of New Jersey and individual states as required. We have undergone a criteria compliance review, including a rigorous review of our operating policies and procedures, licensure verification, facility survey and operations, background checks and screening.";
export const compSubtext =
  " Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem. Nulla consequat massa quis enim. Donec pede justo, fringilla vel, aliquet nec, vulputate eget, arcu. In enim justo, rhoncus ut, imperdiet a, venenatis vitae, justo. Nullam dictum felis eu pede mollis pretium. Integer tincidunt. Cras dapibus. Vivamus elementum semper nisi. Aenean vulputate eleifend tellus. Aenean leo ligula, porttitor eu, consequat vitae, eleifend ac, enim. Aliquam lorem ante, dapibus in, viverra quis, feugiat a, tellus.";

// about us page strings will start with "about"

export const aboutText =
  " With a team that has over 20 years of experience in the pharmaceutical industry, RxMax is the fruit of their labour from which stems our ability to recognize the challenges pharmacists and technicians face every day. RxMax’s first priority was addressing those challenges and facilitating the expired pharmaceutical return process as much as we can. At RxMax, we are a team of experienced pharmacy professionals committed to helping our partners reduce pharmacy expense, elevate clinical performance, and maximize patient safety. We rely on our transparency and integrity to deliver the best possible service and help you achieve your highest potential.";

// coming soon page strings will start with "about"

export const comingSoonText =
  "We are getting ready!";

// our services  page strings will start with "service"

export const serviceText =
  " The process of expired pharmaceutical returns can be overwhelming and tedious, especially since it needs regular follow ups. But that doesn’t mean it should be ignored, as these outdates laying around on your shelves could bring you significant revenue. RxMax is here to take that weight off your shoulders and help you focus completely on your own business. That’s why we’ve simplified this process for you by categorizing our services into 3 options, so choose the one that is most convenient to you and give us a call!";
export const serviceCardLabel1 = "Give Credit for Outdates:";
export const serviceCardLabel2 = "Healthcare Pharmacy Physical Inventory:";
// export const serviceCardLabel3 = "Self service:";
export const serviceCardLabel3 = "Disposal:";
export const serviceCardText1 =
  "This service provides our clients with the complete package. Our certified processor goes right to your doorstep then begins to scan the expired medications, controls, or hazards. Next he packs them with UPS tags and gives a fully detailed report of the findings. After receiving your outdates on site we begin your return request with the right parties.";
export const serviceCardText2 =
  "How this service works is that the client requests UPS shipping labels through our website then ships the outdates to us. The full scanning and packing procedure happens on company grounds, which means no hassle whatsoever for the client on site. As part of this service, you will be provided a full inventory of the Controlled products, as well as all required DEA 222 forms.";
// export const serviceCardText3 =
//   " Busy schedule? No problem. Our third type of service allows our client to scan their outdates through our website and print the required UPS shipping labels, which in turn ships them to us.Afterwards, we’ll take care of the rest of the return process for you. This option is most suitable in the case where there aren’t many products to return to manufacturers on a regular basis.";
export const serviceCardText3 =
  "RxMax offers secure handling of pharmaceutical waste. We manage pharmaceutical returns after processing and ensure proper disposal. We determine whether the items are hazardous or nonhazardous and provide environmentally sound disposal options. Once items arrive at our licensed facilities, we secure them until we safely dispose of them. We can handle the complete service and disposal of these items in full compliance with all Federal and State agencies. Our service provides you with the responsible, safe and secure destruction of your waste pharmaceuticals. And using our customer returns portal you can create your inventory, request a DEA Form 222, print shipping labels and submit payment.";
export const serviceCardText2dash =
  "We send our licensed team of experts right to your doorstep, delivering the needed inventory data with your specifications and needs in mind. Data is validated against your cost file which we obtain directly from your supplier. We provide flexible scheduling as well as a detailed report including a location report and a previous comparison report.The counting process is performed in your pharmacy from start to finish and the results are immediately available, archived and easily accessed.";
export const slogan =
  "Relax, and leave your outdates to RxMax !"

export const startReturnButton =
  "Start Your Return Process"

export const subheaderService1 =
  "Full service:"
export const subheaderService2 =
  "Express service:"
export const homeTitle = "Home"
export const whatAreWeTitle = "What Do We Do?"
export const whoWeAre = "Who Are We ?"
export const compliance = "Compliance"

export const firstCardTitle = "Transparent"
export const firstCardText = "Our clients are most welcome to follow up on their return process at any time through phone, as we are available 24/7."
export const secondCardTitle = "Passionate"
export const secondCardText = "Having been part of this industry for over a decade, our main objective is to provide our clients with the best service there is."
export const thirdCardTitle = "Focused"
export const thirdCardText = "We specialize in independent pharmacies and regional chains and health systems nationwide."
export const fourthCardTitle = "Close"
export const fourthCardText = "RxMax is right next door, our headquarters are situated in New Jersey, making it the perfect location to reach the Tri-state area; ergo: less shipping time!"
export const fifthCardTitle = "Collaborative"
export const fifthCardText = "At RxMax, we embrace teamwork, and consider our clients part of our team, since we all share the same goal. Which is to ensure patient safety."
export const sixthCardTitle = "Fast"
export const sixthCardText = "When doing such a routine process, speed matters. RxMax guarantees you the fastest procedure in the industry. Your outdates are processed and categorized within 24 hours upon receiving."


export const whatDoWeDoGiveCreditTitle = "Give credit for Outdates"
export const whatDoWeDoGiveCreditContent = "We are committed to helping you reduce pharmacy expense, elevate clinical performance and maximize patient safety."

export const whatDoWeDoGiveDisposalTitle = "Disposal"
export const whatDoWeDoGiveDisposalContent = "We handle controls and hazardous meds. And dispose of them according to the ASHP Guidelines. "

export const whatDoWeDoGiveInventoryTitle = "Inventory"
export const whatDoWeDoGiveInventoryContent = "We take a thorough look at your shelves and handle the tedious task of doing inventory for you."
