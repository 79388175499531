import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { largeFontSize } from "../../../utilities/style";
import { AboutUsOrganismProps } from "./AboutUsOrganism";

export interface AboutUsOrganismStylesProps {}
export const useStyles = makeStyles<Theme, AboutUsOrganismProps>(
  (theme: Theme) =>
    createStyles({
      containerGrey: (props: AboutUsOrganismProps) => ({
        backgroundColor: props.backgroundColor,
        padding: theme.spacing(8),
        paddingLeft: theme.spacing(27),
        paddingRight: theme.spacing(27),
        [theme.breakpoints.down("xs")]: {
          padding: theme.spacing(3),
          paddingLeft: theme.spacing(3),
          paddingRight: theme.spacing(4),
          paddingTop: theme.spacing(2),
        },
      }),
      arrowBlue: {
        paddingLeft: theme.spacing(2),
        [theme.breakpoints.down("xs")]: {
          paddingRight: theme.spacing(12),
        },
      },
      subtitleBlue: {
        fontSize: largeFontSize.fontSize,
        color: "#323A87",
        fontWeight: "bold",
      },
      readmoreBlue: {
        display: "flex",
        marginTop: 10,
        [theme.breakpoints.down("sm")]: {
          justifyContent: "center",
        },
      },
      img: {
        width: "80%",
      },
    })
);
