import styled from "styled-components";

interface ContainerProps {
  width?: string;
}
export const Container = styled.div<ContainerProps>`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  > div {
    width: ${(props) => props.width};
    @media screen and (max-width: 840px) {
      width: 100%;
    }
  }
  .MuiTextField-root {
    width: 100%;
  }
  .MuiInput-root {
    width: 100%;
  }
  .MuiInput-underline:before {
    content: "";
    border-bottom: 0px;
  }
  .MuiInput-underline:hover:not(.Mui-disabled):before {
    border-bottom: 0px;
  }

  @media screen and (max-width: 840px) {
    flex-direction: column;
    .MuiTextField-root {
      width: 100%;
    }
    .MuiInput-root {
      width: 100%;
    }
  }

  .radio-wrapper {
    display: flex;
    flex-direction: column;
    width: 50%;
    margin-inline-start: -2.5px;
  }

  .radio-border {
    border: 1px solid rgba(0, 0, 0, 0.25);
    border-radius: 15px;
    padding: 0;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .legend {
    padding: 0;
    position: absolute;
    top: 2px;
    left: 6px;
  }

  .custom-radio-group {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .custom-radio {
    padding: 0;
    color: rgba(0, 0, 0, 0.25);
    margin-right: 5px;
  }

  .MuiFormGroup-root {
    flex-direction: row;
    padding: 15px 0px 15px 20px;
    gap: 20px;
  }

  .custom-label {
    color: rgba(0, 0, 0, 0.38);
    font-size: 14px;
  }

  .radioGroupTitle {
    float: right;
    position: absolute;
    top: -13px;
    background-color: #fff;
    padding: 0 5px;
    text-align: center;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  .title {
    font: normal normal bold 28px/34px Roboto, Bold;
    color: #a61c14;
    margin-bottom: 20px;
    align-self: flex-start;
  }
`;
