import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import styled from "styled-components";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: theme.spacing(2),
      opacity: 1,
      width: "70%",
      height: "20%",
      margin: "0 !important",
      marginBottom: 20,
      alignSelf: "center",
      border: "1px solid",
      borderRadius: 15,
      display: "flex",
      alignItems: "center",
      font: "normal normal normal 28px/34px Roboto",
      color: "#403F3F",
      borderColor: "rgba(0, 0, 0, 0.23)",
      "&:focus": {
        borderColor: "rgba(0, 0, 0, 0.23) !important",
        borderRadius: 15,
        backgroundColor: "white",
      },
    },
    errorMessage: {
      color: "red",
      marginTop: 8,
    },
    wrapper: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
    },
    select: {
      padding: "0 !important",
      paddingLeft: "18px !important",
      width: "100%",
      font: "normal normal normal 28px/34px Roboto, Regular",
      color: "#403F3F",
      height: "58px",
    },
  })
);

export const SelectWrapper = styled.div`
  .MuiInput-underline:before {
    content: unset;
  }
  .MuiInput-underline:after {
    border-bottom: 0;
  }
  .MuiNativeSelect-selectMenu	{
    background-color: red !important;
  }
`;
