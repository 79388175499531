import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 277,
      background: "#EDEDED 0% 0% no-repeat padding-box",
      borderRadius: 10,
    },
    nested: {
      paddingLeft: theme.spacing(4),
    },
    iconRoot: {
      minWidth: "unset",
      marginRight: 10,
    },
    itemListRoot: {
      flex: "unset",
      font: "normal normal normal 21px/25px Roboto",
      color: "#403F3F",
      marginRight: 60,
    },
  })
);