import { makeStyles } from "@material-ui/core";
import styled from "styled-components";
import { smallFontSize } from "../../utilities/style";

export const useStyles = makeStyles({
  root: {
    opacity: 1,
    width: "70%",
    height: "20%",
    marginBottom: 20,
    alignSelf: "center",
    "&:focus": {
      borderColor: "#000000",
    },
    "&:disabled": {
      color: "black",
    },
  },
  errorMessage: {
    color: "red",
    marginTop: -15,
    marginBottom: "0.5rem",
  },
  hintText: {
    color: "#212121",
    opacity: 0.5,
    marginTop: -15,
    marginBottom: "1rem",
    fontSize: "0.85rem"
  },
  wrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    width: "100%",
  },
  input: {
    color: "#212121",
    opacity: 1,
    fontSize: smallFontSize.fontSize,
    letterSpacing: 0,
    paddingLeft: "4%",
    borderRadius: 15,
    borderColor: "#000000",
    "&$focused": {
      borderColor: "#000000",
    },
    manualRoot: {
      opacity: 1,
      // width: props.width,
      height: "20%",
      marginBottom: 20,
      alignSelf: "center",
      "&:focus": {
        borderColor: "#000000",
      },
    },
  },
});

export const Container = styled.div`
  input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0px 1000px white inset;
    box-shadow: 0 0 0px 1000px white inset;
  }
  .MuiInputBase-root.Mui-disabled {
    color: black;
  }
  input[type="month"]::-webkit-calendar-picker-indicator {
    background: transparent;
    bottom: 0;
    color: transparent;
    cursor: pointer;
    height: auto;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: auto;
  }
  input[type="date"]::-webkit-calendar-picker-indicator {
    background: transparent;
    bottom: 0;
    color: transparent;
    cursor: pointer;
    height: auto;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: auto;
  }
`;
