import { createStyles, makeStyles, Theme } from "@material-ui/core";
import styled from "styled-components";

export interface RegisterPageInterface {}
export const useStyles = makeStyles<Theme, RegisterPageInterface>(
  (theme: Theme) =>
    createStyles({
      containerStyle: {
        flex: 1,
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      },
      outerMargin: {
        marginBottom: 20,
        display: "flex",
        justifyContent: "start",
        marginLeft: "15%",
      },
      outerMarginSecondCard: {
        marginBottom: 10,
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "row",
        display: "flex",
        marginTop: 20
      },
    })
);

export const Container = styled.div`
  form > div > div:first-child {
    align-items: center;
    > div {
      width: 70%;
      margin-top: 10px;
    }
  }
`;
