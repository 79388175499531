import styled from "styled-components";
import { largeFontSize } from "../../utilities/style";

export const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  margin: 7.5rem 5rem 5rem;

  > svg {
    align-self: center;
  }

  .label {
    align-self: center;
    font-size: ${largeFontSize.fontSize}px;
    font-weight: 600;
    color: #323a87;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .MuiButtonBase-root {
    width: 100%;
    height: 4rem;
    margin-top: 2rem
  }
`

export const ImageWrapper = styled.div`
  width: 50%;
  flex-direction: column;
  display: flex;
  align-items: flex-end;
  > img {
    width: 100%;
    height: 100%;
    object-fit: cover
  }
`;

export const CardsWrapper = styled.div`
  flex-direction: row;
  display: flex;
  width: 80%;
  background: #fbfbfb 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 25px;
  height: 80%;
  overflow: hidden;
  .label {
    text-align: left;
    margin-left: -10px;
  }
  @media screen and (max-width: 850px) {
    width: 100%;
    flex-direction: column;
    align-items: center;
    height: auto;
    > div {
      width: 80%;
    }
    > form {
      margin-left: 0px;
      align-items: center;
      .MuiFormControl-root {
        align-self: center;
        width: 100%;
      }
      .MuiTypography-root {
        align-self: center;
      }
    }
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  >div:nth-child(2){
    margin-top: 0px;
    @media screen and (max-width: 500px){
      padding-bottom: 30px;
      font-size: 15px;
    }
  }
`;
