import { TableCell, TableSortLabel } from "@material-ui/core";
import { Button } from "@material-ui/core";
import React, { MouseEventHandler } from "react";
import {
  useStyles,
  Rounded,
  RoundedSelectWrapper,
  DeleteIcon,
  DeleteIconWrapper,
  DownloadIcon,
  DownloadIconWrapper,
  GenerateIconWrapper
} from "./TableCellAtomStyles";
import GreenSwitchAtom from "../GreenSwitch/GreenSwitchAtom";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { useState } from "react";
import Checkbox from "@material-ui/core/Checkbox";
import { SelectOption } from "../../utilities/SelectOptions";
import { ReactComponent as GenerateExcelIcon } from "../../assets/generateExcel.svg";
import { ReactComponent as GeneratePDFIcon } from "../../assets/generatePDF.svg";

export interface TableCellAtomProps {
  text: string;
  clickable: boolean;
  onClick: React.MouseEventHandler | undefined | any;
  header: boolean;
  bold: boolean;
  isSwitch?: boolean;
  checked?: boolean;
  isCustom?: boolean;
  children?: any;
  isRounded?: boolean;
  isSelect?: boolean;
  haveBorder?: boolean;
  selectValue?: any;
  selectOptions?: SelectOption[];
  shouldSort?: any;
  handleSort?: () => void;
  selectOnChange?: (event: any) => void;
  isCheckBox?: boolean;
}

export const TableCellAtom: React.FC<TableCellAtomProps> = (props) => {
  const {
    header,
    text,
    clickable,
    onClick,
    isSwitch = false,
    checked = false,
    isCustom = false,
    children,
    isRounded = false,
    isSelect = false,
    selectOnChange = () => { },
    selectValue,
    selectOptions = [],
    haveBorder = false,
    shouldSort = false,
    handleSort = () => { },
    isCheckBox = false,
  } = props;
  const [sort, setSort] = useState(true);
  const classes = useStyles(props);

  const renderClickableCellContent = () => {
    switch (text) {
      case "Remove":
        return (
          <DeleteIconWrapper onClick={onClick}>
            <DeleteIcon />
          </DeleteIconWrapper>
        );
      case "Download":
        return (
          <DownloadIconWrapper onClick={onClick}>
            <DownloadIcon />
          </DownloadIconWrapper>
        );
      case "Generate Excel":
        return (
          <GenerateIconWrapper onClick={onClick}>
            <GenerateExcelIcon title="Generate Excel"/>
          </GenerateIconWrapper>
        );
      case "Generate PDF":
        return (
          <GenerateIconWrapper onClick={onClick}>
            <GeneratePDFIcon title="Generate PDF"/>
          </GenerateIconWrapper>
        );
      default:
        return (
          <Button className={classes.button} onClick={onClick} disableRipple>
            {text}
          </Button>
        );
    }
  }

  if (isCheckBox) {
    return (
      <TableCell
        className={header ? classes.head : classes.body}
        align="center"
        style={{ borderRight: haveBorder ? "0.1px solid #bdb9b9" : "0" }}
      >
        <Checkbox checked={checked} onChange={onClick} />
      </TableCell>
    );
  }
  if (isSelect) {
    return (
      <TableCell
        className={header ? classes.head : classes.body}
        align="center"
        style={{ borderRight: haveBorder ? "0.1px solid #bdb9b9" : "0" }}
      >
        <div style={{ display: "flex", justifyContent: "center" }}>
          <RoundedSelectWrapper>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={selectValue}
              onChange={(event) => selectOnChange(event)}
            >
              {selectOptions.map(option => {
                return (
                  <MenuItem value={option.value}>{option.value}</MenuItem>
                )
              })}
            </Select>
          </RoundedSelectWrapper>
        </div>
      </TableCell>
    );
  }
  if (isRounded) {
    return (
      <TableCell
        className={header ? classes.head : classes.body}
        align="center"
        style={{ borderRight: haveBorder ? "0.1px solid #bdb9b9" : "0" }}
      >
        <Rounded>{text}</Rounded>
      </TableCell>
    );
  }

  if (isCustom) {
    return children;
  }
  if (isSwitch) {
    return (
      <TableCell
        className={header ? classes.head : classes.body}
        align="center"
      >
        <GreenSwitchAtom checked={checked} onClick={onClick} />
      </TableCell>
    );
  }
  if (clickable) {
    return (
      <TableCell
        className={header ? classes.head : classes.body}
        align="center"
      >
        <div style={{ display: "flex", justifyContent: "center" }}>
          {renderClickableCellContent()}
        </div>
      </TableCell>
    );
  }

  return (
    <TableCell
      className={header ? classes.head : classes.body}
      align="center"
    >
      {text}
      {shouldSort && (
        <TableSortLabel
          active={true}
          direction={sort ? "asc" : "desc"}
          onClick={() => {
            setSort(!sort);
            handleSort();
          }}
        ></TableSortLabel>
      )}
    </TableCell>
  );
};
