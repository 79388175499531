
export const formForWholesalerUserLogin = [
   [
      {
        controllingName: "username",
        placeHolder: "Username",
        rules: {
          required: "This field is required",
        }
      },
    ],
    [
      {
        controllingName: "password",
        placeHolder: "Password",
        rules: {
          required: "This field is required",
        },
        type: "password"
      },
    ],
]