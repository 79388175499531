import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { largeFontSize, mediumFontSize } from '../../../utilities/style';

export interface RedStripStylesProps {

}
export const useStyles = makeStyles<Theme, RedStripStylesProps>((theme: Theme) =>
    createStyles({
        redStrip: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-around',
            background: 'transparent linear-gradient(90deg, #A61C14 0%, #530E0A 100%) 0% 0% no-repeat padding-box',
            height: 162,
            [theme.breakpoints.down('xs')]: {
                flexDirection: 'column',
                height: 200
            }
        },
        stripText: {
            color: '#FFFFFF',
            fontSize: largeFontSize.fontSize,
            [theme.breakpoints.down('xs')]: {
                fontSize: mediumFontSize.fontSize,
            }
        },
    }),
);