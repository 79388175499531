import React from "react";
import { useStyles } from "./CheckBoxStyles";
import { Controller } from "react-hook-form";
import Checkbox from '@material-ui/core/Checkbox';
import Label from './../Label/Label';

interface CheckBoxProps {
  control: any;
  controllingName: string;
  value: string;
  label: string;
  onChange: any;
  rules?: any;
}

const CheckBoxAtom: React.FC<CheckBoxProps> = ({
  control,
  controllingName,
  value,
  label,
  onChange,
  rules
}) => {
  const classes = useStyles();

  return (
    <Controller
      control={control}
      name={controllingName}
      rules={rules}
      render={({ fieldState: { error } }) => (
        <div className={classes.wrapper}>
          <Checkbox
            value={value}
            onChange={onChange}
            color="primary"
            name={controllingName}
            className={classes.checkbox}
          />
          <Label
            label={label}
          />
        </div>
      )}
    />
  );
};

export default CheckBoxAtom;
