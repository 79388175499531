import { Paper, Typography } from "@material-ui/core";
import React from "react";
import { useStyles } from "./StatesStyles";

export interface StatesProps {
  text: string;
  licenseLink?: string;
  requireLicense: boolean;
}

export const States: React.FC<StatesProps> = (props: StatesProps) => {
  const { text, licenseLink, requireLicense } = props;
  const classes = useStyles(props);
  const licenseClass = requireLicense
    ? licenseLink
      ? classes.rxmaxLicense
      : classes.missingLicense
    : classes.noLicenseRequired;
  return (
    <Paper className={`${classes.paper} ${licenseClass}`} elevation={0}>
      <a
        className={classes.href}
        href={licenseLink}
        target="_blank"
        rel="noreferrer noopener"
      >
        <Typography className={classes.paperText}>{text}</Typography>
      </a>
    </Paper>
  );
};
