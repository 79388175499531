import React, { useReducer } from "react";
import { initialState, LoaderReducer } from "./reducers/loaderReducer";

type ContextProps = {
  loading: boolean;
};

const LoaderStateContext = React.createContext<Partial<ContextProps>>({});
const LoaderDispatchContext = React.createContext<Partial<any>>({});

export function useLoaderState() {
  const context = React.useContext(LoaderStateContext);
  if (context === undefined) {
    throw new Error("useAuthState must be used within a AuthProvider");
  }

  return context;
}

export function useLoaderDispatch() {
  const context = React.useContext(LoaderDispatchContext);
  if (context === undefined) {
    throw new Error("useLoaderDispatch must be used within a LoaderProvider");
  }

  return context;
}

export const LoaderProvider: React.FC = ({ children }) => {
  const [loaderState, dispatch] = useReducer(LoaderReducer, initialState);

  return (
    <LoaderStateContext.Provider value={loaderState}>
      <LoaderDispatchContext.Provider value={dispatch}>
        {children}
      </LoaderDispatchContext.Provider>
    </LoaderStateContext.Provider>
  );
};
