import { createStyles, makeStyles, Theme } from "@material-ui/core";
import styled from "styled-components";
import { CallUsOrganismProps } from "./CallUsOrganism";

export const useStyles = makeStyles<Theme, CallUsOrganismProps>(
  (theme: Theme) =>
    createStyles({
      centeredText: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },
      cardContent: {
        padding: theme.spacing(2),
      }
    })
);

export const Wrapper = styled.div`
  @media (max-width: 1430px) {
    > button {
      width: 100%;
    }
  }
  @media (max-width: 959.95px) {
    > button {
      width: 70%;
      height: 45px;
    }
  }
`;

export const FormWrapper = styled.div`
  margin: 5rem 0;

  //for placeholder in text area
  > div {
    > div:nth-child(3) {
      > div {
        > div {
          > div {
            > div {
              padding-left: 2.4rem;
              @media (max-width: 1700px) {
                padding-left: 2.2rem;
              }
              @media (max-width: 1500px) {
                padding-left: 2rem;
              }
              @media (max-width: 1300px) {
                padding-left: 1.8rem;
              }
              @media (max-width: 1000px) {
                padding-left: 1.6rem;
              }
              @media (max-width: 840px) {
                padding-left: 2.1rem;
              }
              @media (max-width: 700px) {
                padding-left: 1.8rem;
              }
              @media (max-width: 460px) {
                padding-left: 1.5rem;
              }
            }
          }
        }
      }
    }
  }
`;

export const Container = styled.div`
  @media (max-width: 959.95px) {
    .MuiGrid-container {
      align-items: center;
      justify-content: center;
      >div {
          width: 70%;
          >div >div {
              width: 100%;
          }
      }
    }

  }
`;
