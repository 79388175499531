import { useNotificationCount } from "../../context/NotificationContext";
import { buzzerStyles } from "./NotificationBuzzerStyle";


const NotificationBuzzer: React.FC = () => {
  const { notificationCount } = useNotificationCount();

  return (
    <>
      <style>{buzzerStyles}</style>
      <div className="notification-buzzer">
        {notificationCount > 0 && <div className="count">{notificationCount}</div>}
      </div>
    </>
  );
};
  
export default NotificationBuzzer;