export interface IUser {
  createdAt: string;
  firstName: string;
  id: number;
  lastName: string;
  phoneNumber: string;
  updatedAt: string;
  username: string;
  role: string;
}

let userString: string | null | undefined = localStorage.getItem("currentUser");
let user = null;
if (userString !== null && userString !== "undefined") {
  user = JSON.parse(userString);
}

export const initialState = {
  user: user,
  token: localStorage.getItem("token"),
  loading: false,
  errorMessage: null,
};

export const AuthReducer = (initialState: any, action: any) => {
  switch (action.type) {
    case "REQUEST_LOGIN":
      return {
        ...initialState,
        loading: true,
      };
    case "LOGIN_SUCCESS":
      return {
        ...initialState,
        user: action.payload.user,
        token: action.payload.token,
        loading: false,
      };
    case "LOGOUT":
      return {
        ...initialState,
        user: "",
        token: "",
      };

    case "LOGIN_ERROR":
      return {
        ...initialState,
        loading: false,
        errorMessage: action.error.errorMessage,
      };

    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};
