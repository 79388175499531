import React from "react";
import { Container, TextWrapper } from "./ModalWithTextStyle";
import Dialog from "@material-ui/core/Dialog";
import { TextBox } from "../../atoms/Text Box/TextBox";

export interface ModalWithTextProps {
  open: boolean;
  onClose: () => void;
}

const ModalWithText: React.FC<ModalWithTextProps> = ({
  open,
  onClose
}) => {

  return (
    <Container>
      <Dialog
        open={open}
        fullWidth={true}
        maxWidth="xs"
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <TextWrapper>
          <TextBox
            bodyFontSize={18}
            headerFontSize={24}
            image={""}
            paragraphs={[""]}
            title={"License ID: NJR000084566"}
            titleColor={"#A61C14"}
          />
        </TextWrapper>
      </Dialog>
    </Container>
  );
};

export default ModalWithText;
