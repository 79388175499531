import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { HeaderNavbarProps } from './HeaderNavbar';

export interface HeaderNavbarStylesStyleProps {

}
export const useStyles = makeStyles<Theme, HeaderNavbarProps>((theme: Theme) =>
    createStyles({
        menu: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            height: 100,
        },
        logoIcon: {
            zIndex: 7
        }
    }),
);