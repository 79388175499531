import React, { useRef, useLayoutEffect } from "react";
import { RouteComponentProps, useParams } from "react-router-dom";
import TableOrganism from "../../organisms/Table/TableOrganism";
import { createHeaderRow } from "../../utilities/CreateHeaderRow";
import { useQuery } from "react-query";
import { TableRowProps } from "../../molecules/TableRow/TableRowAtom";
import { createVerifiedItemsRows } from "./VerifiedItemsTable";
import IPage from "../../config/interfaces/page";
import { useLoaderDispatch, useLoaderState } from "../../context/LoaderContext";
import { startLoading, stopLoading } from "../../context/actions/loaderActions";
import Loader from "../../atoms/Loader/LoaderAtom";
import useApiService from "../../utilities/useApiService";
import { useAuthState } from "../../context/AuthContext";
import { mediumButton } from '../../utilities/style';
import { PrintButton, PrintButtonHandle } from "../../atoms/PrintButton/PrintButtonAtom";
import { printStyles } from "../../atoms/PrintButton/PrintButtonAtomStyles";

export interface VerifiedItemsProps {}

export const VerifiedItems: React.FC<
  VerifiedItemsProps & RouteComponentProps<any> & IPage
> = (props) => {
  const { user } = useAuthState();
  const userRole = user!.role;
  const { getMethod } = useApiService();
  
  const params: any = useParams();
  const { loading } = useLoaderState();
  const loaderDispatch = useLoaderDispatch();
  const labelRef = useRef<HTMLDivElement>(null);
  const printButtonRef = useRef<PrintButtonHandle | null>(null);
  const headerTitles = [
    "NDC",
    "Manufacturer",
    "Product",
    "PKG Size",
    // "Type",
    "Full Quantity",
    "Partial Quantity",
    "CSC",
    "Extended Price",
    "Extended Unit Price",
    "Expiration Date",
    "Lot Number",
    "Status",
    "Added at"
  ];

  const fetchItems = async () => {
    startLoading(loaderDispatch);
    let response;
    response = await getMethod(`/pharmacies/${params.pharmacyId}/returnrequests/${params.requestId}/items`);
    stopLoading(loaderDispatch);
    return response.data;
  };

  const { data } = useQuery("items", fetchItems);
  const rows: Array<TableRowProps> = data ? createVerifiedItemsRows(data) : [];

  useLayoutEffect(() => {
    const triggerPrint = () => {
      if (printButtonRef.current) {
        printButtonRef.current.triggerPrint();
      }
    };

    const delayedTriggerPrint = () => {
      setTimeout(triggerPrint, 1000);
    };

    delayedTriggerPrint();

    window.addEventListener("focus", delayedTriggerPrint);

    return () => {
      window.removeEventListener("focus", delayedTriggerPrint);
    };
  }, []);

  const totalSum = data ? data.reduce((sum: string, item: any) => sum + (item.extendedPrice || 0) + (item.extendedUnitPrice || 0), 0).toFixed(2) : '0.00';

  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <div style={{ marginBottom: 40 }} ref={labelRef}> 
        {data && data.length > 0 && (
        <div className="print-header">
            <span>
              {data[0].pharmacyDoingBusinessAs}
            </span>
            <span>
            {data[0].returnRequestCreatedAt}
          </span>
        </div>
          )}
          <h1 className="print-title">Expected Returns</h1>
          <style>{printStyles}</style>
            <TableOrganism
              header={createHeaderRow(headerTitles)}
              rows={rows}
              tableClassName="print-table"
            />
            <h2 className="total">
           Total: {totalSum}
          </h2>
        </div>
      )}
      <PrintButton
        labelRef={labelRef}
        buttonText={"Print"}
        buttonWidth={mediumButton.width} 
        buttonHeight={'80px'}
        ref={printButtonRef}
      />
    </div>
  );
};
