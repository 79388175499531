import { Control } from "react-hook-form";

export enum FieldType {
  Text,
  Select
}

interface Field {
  discriminator: FieldType;
  control: Control;
  controllingName: string;
}

export interface Text extends Field {
  placeholder: string;
  inputType?: 'text' | 'email' | 'number' | 'password' | 'date' | string;
  rules?: any;
  minDate?: string;
  helperText?: string;
  isMultiline?: boolean;
  maxRows?: number;
  hintText?: string;
  disabled?: boolean;
}

export interface Select extends Field {
  options: Array<Option>
  defaultValue?: string;
  placeholder?: string;
  rules?: any;
  disabled?: boolean;
  className?: {  
    root: string,
    select: string
  };
}

interface Option {
  value: string,
  label: string
}

export type FormFieldAtomProps = Text | Select;