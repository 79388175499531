import React from 'react'
import { CallUsOrganism } from '../../organisms/callUs/CallUsOrganism';

export interface CallUsPageProps {

}

export const CallUsPage: React.FC<CallUsPageProps> = (props: CallUsPageProps) => {
    return (
        <CallUsOrganism />
    );
}