import React from "react";
import { useHistory } from "react-router-dom";
import { AlternateLogo } from "../../../atoms/HeaderContent/AlternateLogo/AlternateLogo";
import { MenuItemProps } from "../../../atoms/HeaderContent/MenuItem/MenuItem";
import { useAuthState } from "../../../context/AuthContext";
import { HeaderMenu } from "../HeaderMenu/HeaderMenu";
import { useStyles } from "./HeaderNavbarStyles";
import * as strings from '../../../utilities/strings'
import { UserRole } from "../../../utilities/Enums";
import { isUserRoleEqual } from "../../../utilities/checkUserRole";
export interface HeaderNavbarProps {
  menuItems?: Array<MenuItemProps>;
  openNav: () => void;
  closeNav: () => void;
  width: string;
  showBurger?: boolean;
}

export const HeaderNavbar: React.FC<HeaderNavbarProps> = (
  props: HeaderNavbarProps
) => {
  let history = useHistory();
  const { token, user } = useAuthState();
  const {
    menuItems = [
      {
        text: strings.homeTitle,
        underline:
          history.location.pathname === "/" ||
            history.location.pathname === "/admin/home" ||
            history.location.pathname === "/pharmacymanagement" || 
            history.location.pathname === "/wholesaler-user/pharmacymanagement"
            ? "always"
            : "none",
        onClick: () => {
          if (token) {
            if (user?.role === "WHOLESALER") {
              history.push("/wholesaler-user/pharmacymanagement")
            } else if(isUserRoleEqual(user?.role, UserRole.Admin) || isUserRoleEqual(user?.role, UserRole.Warehouse) || isUserRoleEqual(user?.role, UserRole.FieldProcessor)) {
              history.push("/admin/home")
            } else {
              history.push("/pharmacymanagement")
            }
          } else {
            history.push("/")
          }
        }
      },
      {
        text: "Services",
        underline:
          history.location.pathname === "/services" ? "always" : "none",
        onClick: () => history.push("/services"),
      },
      {
        text: "About Us",
        underline: history.location.pathname === "/about" ? "always" : "none",
        onClick: () => history.push("/about"),
      },
    ],
  } = props;

  const { menu, logoIcon } = useStyles(props);
  return (
    <div className={menu}>
      <AlternateLogo height="100px" width="150px" />
      <div className={logoIcon}>
        <HeaderMenu
          showBurger={props.showBurger}
          openNav={props.openNav}
          closeNav={props.closeNav}
          items={menuItems}
          width={props.width}
        />
      </div>
    </div>
  );
};
