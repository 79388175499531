import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { mediumFontSize } from '../../utilities/style';

export interface AlternateCardStylesProps {

}
export const useStyles = makeStyles<Theme, AlternateCardStylesProps>((theme: Theme) =>
    createStyles({
        cardCustomerCarousel: {
            textAlign: 'center',
            backgroundColor: '#FFFFFF',
            padding: theme.spacing(1),
            marginRight: theme.spacing(4)
        }, 
        cardCarouselTitle: {
            color: '#323A87',
            fontSize: mediumFontSize.fontSize,
            fontWeight: 'bold',
            marginTop: 12,
            marginBottom: 36,

        },
        cardCarouselParagraph: {
            color: '#8A8A8A',
            fontSize: mediumFontSize.fontSize,

        },
    }),
);