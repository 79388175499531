import { Card, CardContent, Box, Typography } from '@material-ui/core';
import React from 'react'
import { useStyles } from './AlternateCardStyles';

export interface AlternateCardProps {

    image: string,
    title: string,
    quote: string
}

export const AlternateCard: React.FC<AlternateCardProps> = (props: AlternateCardProps) => {
    const { image, title, quote } = props

    const classes = useStyles(props)


    return (
        <div>
            <Card elevation={0} className={classes.cardCustomerCarousel}>
                <CardContent>
                    <Box display='flex' justifyContent='center' alignItems='center' flexDirection='column'>
                        <img src={image} alt="" />
                        <Typography className={classes.cardCarouselTitle}>
                            {title}
                        </Typography>
                        <Typography className={classes.cardCarouselParagraph}>
                            "{quote}"
                        </Typography>
                    </Box>
                </CardContent>
            </Card>
        </div>
    );
}