import { createStyles, makeStyles, Theme } from "@material-ui/core";
import styled from "styled-components";
import Delete from "@material-ui/icons/Delete";
import UploadFileIcon from '@mui/icons-material/UploadFile';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { TableCellAtomProps } from "./TableCellAtom";
import { smallFontSize } from "../../utilities/style";

export interface TableCellAtomStylesProps {}
export const useStyles = makeStyles<Theme, TableCellAtomProps>((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: "#323A87",
      color: theme.palette.common.white,
      fontSize: smallFontSize.fontSize,
      borderRight: "0.1px solid #bdb9b9",
    },
    body: (props: TableCellAtomProps) => ({
      fontSize: smallFontSize.fontSize,
      fontWeight: props.bold ? "bold" : undefined,
    }),
    button: (props: TableCellAtomProps) => ({
      color: props.header ? "red" : "red",
      textTransform: "capitalize",
      textDecoration: "underline",
    }),
    
  })
);

export const IconWrapper = styled.div`
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const DeleteIconWrapper = styled(IconWrapper)`
  :hover {
    background-color: #eae6e6;
  }
`;

export const DownloadIconWrapper = styled(IconWrapper)`
  :hover {
    background-color: #dcdce0;
  }
`;

export const GenerateIconWrapper = styled(DownloadIconWrapper)`
  :hover {
    cursor: pointer;
  }
`;

export const DeleteIcon = styled(Delete)`
  color: red;
  cursor: pointer;
`;

export const DownloadIcon = styled(FileDownloadIcon)`
  color: #323A87;
  cursor: pointer;
`;
export const Rounded = styled.span`
  border: 1px solid #808080;
  border-radius: 10px;
  padding: 12px 20px 12px 20px;
`;

export const RoundedSelectWrapper = styled.div`
  width: 100%;
  border: 1px solid #808080;
  padding: 5px;
  border-radius: 10px;
  .MuiInputBase-root {
    width: 100%;
  }
  .MuiInput-underline:before {
    content: unset;
  }
  .MuiInput-underline:after {
    border-bottom: 0;
  }
`;
