import { IWholesaler } from "../../network/models/Interfaces";

export const createFormForWholesalerUserUpdating = (wholesalers: Array<IWholesaler>, getValues: any) => {
   return [
      [
         {
            controllingName: "username",
            placeHolder: "Username",
            rules: {
              required: "This field is required",
            },
         }
      ],
      [
         {
           controllingName: "email",
           placeHolder: "Email Address",
           rules: {
             required: "This field is required",
             validate: (value: string) => {
               const validRegex = /^[a-zA-Z0-9-.]+@[a-zA-Z0-9]+\.[A-Za-z]+$/;
               if (!validRegex.test(value)) {
                  return "Please enter a valid email address"
               }
               return true;
             },
           },
         },
      ],
      [
         {
            controllingName: "wholesaler",
            placeHolder: "Choose wholesaler",
            isSelect: true,
            rules: {
               required: "This field is required",
            },
            options: getOptions(wholesalers)
         },
      ]
   ]
}

const getOptions = (wholesalers: Array<IWholesaler>) => {
   if(wholesalers) {
      return wholesalers.map((wholesaler: IWholesaler) => ({
         value: wholesaler.id.toString(),
         label: wholesaler.name
      }))
   }
}