import React from "react";
import TextFieldM, { TextFieldVersion } from "../InputText/TextFieldM";
import SelectAtom from "../InputSelect/Select";

import {
  FormFieldAtomProps,
  FieldType,
  Text,
  Select
} from "./FormFieldAtomTypes";

const FormFieldAtom: React.FC<FormFieldAtomProps> = (props: FormFieldAtomProps) => {

  const renderField = () => {
    const { discriminator } = props;
    switch (discriminator) {
      case FieldType.Text:
        return renderText();
      case FieldType.Select:
        return renderSelect();
    }
  }

  const renderText = () => {
    const {
      control,
      controllingName,
      placeholder,
      inputType,
      rules,
      minDate,
      helperText,
      isMultiline,
      maxRows,
      hintText,
      disabled
    } = props as Text;
    return (
      <TextFieldM
        version={TextFieldVersion.AUTO}
        control={control}
        controlingName={controllingName}
        placeHolder={placeholder}
        type={inputType}
        rules={rules}
        minDate={minDate}
        helperText={helperText}
        isMultiline={isMultiline}
        maxRows={maxRows}
        hintText={hintText}
        disabled={disabled}
      />
    );
  }

  const renderSelect = () => {
    const { control, controllingName, defaultValue, placeholder, options, rules, disabled, className } = props as Select;
    return (
      <SelectAtom
        control={control}
        controllingName={controllingName}
        defaultValue={defaultValue}
        placeHolder={placeholder}
        options={options}
        rules={rules}
        className={className}
        disabled={disabled}
      />
    );
  }

  return (
    renderField()
  );
};

export default FormFieldAtom;









