import React, { useState } from "react";
import TableOrganism from "../../organisms/Table/TableOrganism";
import { ReactComponent as NewIcon } from "../../assets/new.svg";
import { Container, HeaderWrapper } from "./AdminTableApprovePageStyles";
import AdminTablePageHeaderMolecule from "../../molecules/AdminTablePageHeader/AdminTablePageHeaderMolecule";
import PersonIcon from "@material-ui/icons/Person";
import Label from "../../atoms/Label/Label";

export interface AdminTableRequestsPageProps {}

const AdminTableApprovePage: React.FC<AdminTableRequestsPageProps> = (
  props
) => {
  const [rows] = useState(() => [
    {
      cells: [
        {
          bold: false,
          clickable: false,
          header: false,
          onClick: () => {},
          text: "",
          isSelect: true,
          selectValue: "awaitingCompletion",
          haveBorder: true,
        },
        {
          bold: false,
          clickable: false,
          header: false,
          onClick: () => {},
          text: "CII",
          isRounded: true,
          haveBorder: true,
        },
        {
          bold: false,
          clickable: false,
          header: false,
          onClick: () => {},
          text: "2",
          isRounded: true,
          haveBorder: true,
        },
        {
          bold: false,
          clickable: false,
          header: false,
          onClick: () => {},
          text: "8",
          isRounded: true,
          haveBorder: true,
        },
        {
          bold: false,
          clickable: false,
          header: false,
          onClick: () => {},
          text: "",
          isSelect: true,
          selectValue: "incomplete",
          haveBorder: true,
        },
        {
          bold: false,
          clickable: false,
          header: false,
          onClick: () => {},
          text: "",
          isSelect: true,
          selectValue: "incomplete",
          haveBorder: true,
        },
        {
          bold: false,
          clickable: false,
          header: false,
          onClick: () => {},
          text: "",
          isSelect: true,
          selectValue: "unavailable",
          haveBorder: true,
        },
        {
          bold: false,
          clickable: false,
          header: false,
          onClick: () => {},
          text: "",
          isSelect: true,
          selectValue: "unavailable",
          haveBorder: true,
        },
        {
          bold: false,
          clickable: false,
          header: false,
          onClick: () => {},
          text: "",
          isSelect: true,
          selectValue: "unavailable",
          haveBorder: true,
        },
        {
          bold: false,
          clickable: false,
          header: false,
          onClick: () => {},
          text: "2",
          isRounded: true,
          haveBorder: true,
        },
        {
          bold: false,
          clickable: false,
          header: false,
          onClick: () => {},
          text: "$ 0.00",
          isRounded: true,
          haveBorder: true,
        },
        {
          bold: false,
          clickable: false,
          header: false,
          onClick: () => {},
          text: "8.9%",
          isRounded: true,
          haveBorder: true,
        },
      ],
    },
  ]);

  const header = {
    cells: [
      {
        bold: false,
        clickable: false,
        header: true,
        onClick: () => {},
        text: "Number",
      },
      {
        bold: false,
        clickable: false,
        header: true,
        onClick: () => {},
        text: "Type",
      },
      {
        bold: false,
        clickable: false,
        header: true,
        onClick: () => {},
        text: "User Items",
      },
      {
        bold: false,
        clickable: false,
        header: true,
        onClick: () => {},
        text: "Verified Items",
      },
      {
        bold: false,
        clickable: false,
        header: true,
        onClick: () => {},
        text: "Status",
      },
      {
        bold: false,
        clickable: false,
        header: true,
        onClick: () => {},
        text: "UPS Label",
      },
      {
        bold: false,
        clickable: false,
        header: true,
        onClick: () => {},
        text: "Reports",
      },
      {
        bold: false,
        clickable: false,
        header: true,
        onClick: () => {},
        text: "ERV",
      },
      {
        bold: false,
        clickable: false,
        header: true,
        onClick: () => {},
        text: "Wholesaler Credit",
      },
      {
        bold: false,
        clickable: false,
        header: true,
        onClick: () => {},
        text: "Disbursements",
      },
      {
        bold: false,
        clickable: false,
        header: true,
        onClick: () => {},
        text: "Service Fee %",
      },
    ],
  };

  const [searchOptions] = useState([
    { title: "The Shawshank Redemption" },
    { title: "The Godfather" },
    { title: "The Godfather: Part II" },
    { title: "The Dark Knight" },
    { title: "12 Angry Men" },
    { title: "Schindler's List" },
    { title: "Pulp Fiction" },
  ]);

  return (
    <Container>
      <HeaderWrapper>
        <AdminTablePageHeaderMolecule
          searchOptions={searchOptions}
          selectOptions={[
            {
              onClick: () => {},
              label: "starred",
              icon: <NewIcon />,
            },
          ]}
          selectHeaderLabel="Account Name"
          selectHeaderIcon={<PersonIcon />}
        />
      </HeaderWrapper>
      <Label label="Approve Returns" />
      <TableOrganism rows={rows} header={header} />
    </Container>
  );
};

export default AdminTableApprovePage;
