import React from "react";
import { useStyles, ButtonWrapper, Container } from "./ForgotPasswordStyle";
import { useForm } from "react-hook-form";
import {
  BigRedButton,
  ButtonVersion,
} from "../../atoms/BigRedButton/BigRedButtonAtom";
import {
  TextButton,
  TextButtonVersion,
} from "../../atoms/TextButton/TextButton";
import Label from "../../atoms/Label/Label";
import FormMolecule from "../../molecules/Form/FormMolecule";
import { forgetPassword } from "../../network/Services";
import { useLoaderDispatch, useLoaderState } from "../../context/LoaderContext";
import Loader from "../../atoms/Loader/LoaderAtom";
export interface ForgotPasswordPageProps {}

export const ForgotPassword: React.FC<ForgotPasswordPageProps> = (props) => {
  const { handleSubmit, control } = useForm();
  const loaderDispatch = useLoaderDispatch();
  const { loading } = useLoaderState();
  const onSubmit = handleSubmit((data) => {
    forgetPassword({ email: data.email }, loaderDispatch);
  });
  const classes = useStyles(props);

  return (
    loading? <Loader/> :
    <Container>
      <Label
        className="label"
        label="Enter the email address associated with your account below"
      />
      <form onSubmit={onSubmit}>
        <div className={classes.cardStyle}>
          <FormMolecule
            control={control}
            list={[
              [
                {
                  controllingName: "email",
                  placeHolder: "Email Address",
                  rules: {
                    required: "This Field is Required",
                    validate: (val: string) => {
                      if (/^[a-zA-Z0-9-.]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(val))
                        return true;
                      return "Please enter a valid email";
                    },
                  },
                },
              ],
            ]}
          />
          <div className={classes.outerMargin}>
            <ButtonWrapper className={classes.marginInBetween}>
              <BigRedButton
                version={ButtonVersion.SMALL}
                text={"continue"}
                type="submit"
                onClick={() => {
                  console.log("submitted");
                }}
              />
            </ButtonWrapper>
            <ButtonWrapper className={classes.marginInBetween}>
              <TextButton
                text={"Cancel"}
                version={TextButtonVersion.PRIMARY}
                onClick={() => {}}
              ></TextButton>
            </ButtonWrapper>
          </div>
        </div>
      </form>
    </Container>
  );
};
