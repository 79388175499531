import styled from "styled-components";

export const DropzoneStyled = styled.div`
  > div:first-child{
    padding: 1rem;
    border: 1px dashed;
    text-align: center;
  
    &:hover{
      cursor: pointer;
      background-color: #f7f7f7;
    }
  }

  h4 {
    margin-bottom: 0;
  }

  h5 {
    font-weight: normal;
    margin-top: 0.5rem;
  }
`
export const ErrorWrapper = styled.div`
  color: red;
  margin-top: 1rem;
`