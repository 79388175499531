import React from "react";
import { useForm } from "react-hook-form";
import { default as ForgetImage } from "../../assets/forget_password_pic.png";
import Loader from "../../atoms/Loader/LoaderAtom";
import { useLoaderDispatch, useLoaderState } from "../../context/LoaderContext";
import { forgetPassword } from "../../network/Services";
import AdminFooter from "../../organisms/adminFooter/AdminFooterOrganism";
import AdminForgetPasswordForm from "../../organisms/AdminForgetPasswordForm/AdminForgetPasswordFormOrganism";
import {
  Container,
  CardsWrapper,
  ImageWrapper,
} from "./AdminForgetPasswordPageStyle";
export interface AdminForgetPasswordProps {}

type FormData = {
  email: string;
};

const AdminForgetPassword: React.FC<AdminForgetPasswordProps> = (props) => {
  const { handleSubmit, control } = useForm<FormData>();
  const loaderDispatch = useLoaderDispatch();
  const { loading } = useLoaderState();
  const onSubmit = handleSubmit((data) => {
    forgetPassword({ email: data.email }, loaderDispatch);
  });

  return (
    <Container style={{ height: window.innerHeight }}>
      <CardsWrapper>
        <ImageWrapper>
          <img src={ForgetImage} alt="forget" />
        </ImageWrapper>
        {loading ? (
          <Loader />
        ) : (
          <AdminForgetPasswordForm onSubmit={onSubmit} control={control} />
        )}
      </CardsWrapper>
      <AdminFooter/>  
    </Container>
  );
};
export default AdminForgetPassword;
