import { CircularProgress } from "@material-ui/core";
import React from "react";
import { Container } from "./LoaderStyles";
export interface LoaderAtomProps {}

const Loader: React.FC<LoaderAtomProps> = (props: LoaderAtomProps) => {
  return (
    <Container>
      <CircularProgress />
    </Container>
  );
};

export default Loader;
