import { createStyles, makeStyles, Theme } from "@material-ui/core";
import styled from "styled-components";

export const useStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    outerMargin: {
      marginBottom: 20,
      justifyContent: "start",
      marginLeft: "15%",
      marginRight: "15%",
      display: "flex",
      flexDirection: "row",
    },
    marginInBetween: {
      width: "50%",
      alignItems: "start",
      justifyContent: "start",
      flexDirection: "row",
      display: "flex",
    },
  })
);

export const ButtonWrapper = styled.div`
  .MuiButton-root {
    width: 100%;
    height: 90px;
    margin-top: 30px;
  }
  button:first-child {
    margin-right: 25px;
  }
  .MuiButton-text {
    margin-left: 25px;
    margin-right: 0px !important;
  }
  @media screen and (max-width: 600px) {
    button:first-child {
      margin-right: 10px;
      font-size: 20px;
      height: 50px;
    }
    .MuiButton-text {
      margin-left: 10px;
      font-size: 20px;
    }
  }
`;

export const Container = styled.div`
  .label {
    font: normal normal normal 28px/34px Roboto;
    letter-spacing: 0px;
    color: #403f3f;
    opacity: 1;
    margin-top: 90px;
    margin-bottom: 20px;
  }
  form > div > div:first-child {
    align-items: center;
    > div {
      width: 70%;
      margin-top: 10px;
    }
  }

  @media screen and (max-width: 850px) {
    .label {
      text-align: center;
    }
  }
`;
