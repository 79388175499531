import { Typography, Link } from '@material-ui/core';
import React from 'react'
import { IconAtom } from '../../atoms/Icon/IconAtom';
import { useStyles } from './TextIconStyles';

export interface TextIconProps {
    text: string,
    icon: string,
    altText: string,
    isTextLink?: boolean,
    link?: string
}

export const TextIcon: React.FC<TextIconProps> = (props: TextIconProps) => {

    const { text, icon, altText, isTextLink, link } = props

    const classes = useStyles(props)
    return (
        <div className={classes.entry}>
            <div className={classes.icon}>
                <IconAtom icon={icon} altText={altText}/>
            </div>
            <Typography className={classes.content}>
                {isTextLink ? <Link href={link} color={'inherit'}>{text}</Link> : text}
            </Typography>
        </div>
    );
}