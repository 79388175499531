import styled from "styled-components";
import { mediumFontSize } from "../../utilities/style";

export const FormWrapper = styled.form`
  display: flex;
  width: 60%;
  flex-direction: column;
  margin-top: 60px;
  margin-bottom: 100px;
  margin-left: 100px;
  .MuiTextField-root {
    align-self: flex-start;
  }
  .MuiButtonBase-root {
    width: 70%;
  }
  > svg {
    margin-left: -10px;
  }
  .label {
    font-size: ${mediumFontSize.fontSize}px;
    color: #323a87;
    margin-top: 19px;
    margin-bottom: 29px;
    font-weight: 600;
  }
  .MuiLink-button {
    margin-bottom: 29px;
  }
  .MuiButtonBase-root {
    height: 70px;
  }
`;
