import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { PictureAtomProps } from './PictureAtom';

export interface PictureStylesStyleProps {

}
export const useStyles = makeStyles<Theme, PictureAtomProps>((theme: Theme) =>
    createStyles({
        root: (props: PictureAtomProps) => ({
            width: `${props.width}`,
            height: `${props.height}`, 
        })
    }),
);