import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { mediumFontSize } from '../../../utilities/style';
import { MenuItemProps } from './MenuItem';

export interface MenuItemStylesProps {
    
}
export const useStyles = makeStyles<Theme, MenuItemProps>((theme: Theme) =>
    createStyles({
        menuItems: {
            color: '#ffffff',
            textTransform: 'capitalize',
            fontSize: mediumFontSize.fontSize,
            padding: theme.spacing(2),
            zIndex: 1,
            '&:active': {
                borderBottom: '3px sold blue',
                borderRadius: 3,
                '&::after': {
                    borderBottom: '3px sold blue',
                    borderRadius: 3,
                }
            },

        },
    }),
);