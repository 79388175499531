import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { largeFontSize } from '../../utilities/style';
import { CallToActionButtonProps } from './CallToActionButton';

export interface CallToActionButtonStylesProps {

}
export const useStyles = makeStyles<Theme, CallToActionButtonProps>((theme: Theme) =>
    createStyles({
        button: (props: CallToActionButtonProps) => ({
            width: props.width,
            height: props.height,
            background: props.backgroundStyle ? props.backgroundStyle : "transparent linear-gradient(90deg, #A61C14 0%, #530E0A 100%) 0% 0% no-repeat padding-box",
            borderRadius: '15px',
            textTransform: 'capitalize',
            color: 'white',
            fontSize: largeFontSize.fontSize,
            fontWeight: 'bold',
            [theme.breakpoints.down('sm')]:{
                width: 'auto'
            }
        })
    }),
);