import React from "react";
import { withRouter } from "react-router-dom";
import { TextBox } from "../../atoms/Text Box/TextBox";
import { States } from "../../atoms/States/States";
import { PictureAtom } from "../../atoms/Picture/PictureAtom";
import SvgMap from "../../assets/StatesMapChart/StatesMapChart.png";
import * as strings from "../../utilities/strings";
import {
  mediumFontSize,
  smallFontSize,
  xLargeFontSize,
} from "../../utilities/style";
import { Box } from "@material-ui/core";
import { Grid, Typography } from "@material-ui/core";
import { useStyles, Wrapper } from "../compliance/CompliancePageStyles";
import { logos, mapStates } from "../compliance/CompliancePageData";

export interface AboutUsPageProps {}

export const AboutUsPage: React.FC<AboutUsPageProps> = (
  props: AboutUsPageProps
) => {
  const classes = useStyles(props);

  const loadLogos = () => {
    return logos.map((logo) => {
      return (
        <Grid item sm={2} xs={6}>
          <PictureAtom
            image={logo.image}
            altText={logo.altText}
            height={logo.height}
            width={logo.width}
          />
        </Grid>
      );
    });
  };

  const loadStates = () => {
    return mapStates.map((state) => {
      return (
        <Box
          className={`${classes.flexContainer} ${classes.rowFlex} ${classes.fitContentWidth} ${classes.statesGap}`}
        >
          <States
            text={state.text}
            licenseLink={state.licenseLink}
            requireLicense={state.requireLicense}
          />
        </Box>
      );
    });
  };

  return (
    <div>
      <TextBox
        bodyFontSize={smallFontSize.fontSize}
        headerFontSize={xLargeFontSize.fontSize}
        image={""}
        paragraphs={[""]}
        title={"About Us"}
        titleColor={"#A61C14"}
        newLine={true}
        alignTitle={"center"}
      />
      <TextBox
        headerFontSize={mediumFontSize.fontSize}
        bodyFontSize={smallFontSize.fontSize}
        title={""}
        paragraphs={[strings.aboutText]}
        image={""}
        titleColor={"#403F3F"}
        newLine={true}
      />
      <TextBox
        bodyFontSize={smallFontSize.fontSize}
        headerFontSize={xLargeFontSize.fontSize}
        image={""}
        paragraphs={[""]}
        title={"Compliance"}
        titleColor={"#A61C14"}
        newLine={true}
        alignTitle={"center"}
      />
      <TextBox
        bodyFontSize={smallFontSize.fontSize}
        headerFontSize={35}
        image={""}
        paragraphs={[strings.compText]}
        title={""}
        titleColor={"#403F3F"}
      />
      <Box height="30px" />
      <TextBox
        bodyFontSize={smallFontSize.fontSize}
        headerFontSize={mediumFontSize.fontSize}
        image={""}
        paragraphs={[""]}
        title={"Federal Licenses"}
        titleColor={"#A61C14"}
      />
      <Box height="30px" />
      <Wrapper>
        <Grid container justifyContent={"space-around"}>
          {loadLogos()}
        </Grid>
      </Wrapper>
      <Box height="30px" />
      <TextBox
        bodyFontSize={smallFontSize.fontSize}
        headerFontSize={mediumFontSize.fontSize}
        image={""}
        paragraphs={[""]}
        title={"State Licenses"}
        titleColor={"#A61C14"}
      />
      <Box height="30px" />
      <Box
        className={`${classes.flexContainer} ${classes.colFlex} ${classes.fitContentWidth}`}
      >
        <Box>
          <img src={SvgMap} alt="svg" width={1000} height={700} />
        </Box>
        <Box className={`${classes.flexContainer} ${classes.rowFlex}`}>
          {loadStates()}
        </Box>
      </Box>
      {/* <Typography className={classes.disclaimer}>
                {strings.compSubtext}
            </Typography> */}
    </div>
  );
};

export default withRouter(AboutUsPage);
