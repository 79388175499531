import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 20px;
  > div {
    margin-left: 10px;
    margin-right: 10px;
    cursor: pointer;
  }
  @media screen and (max-width: 710px) {
    justify-content: center;
    > div {
      margin-bottom: 10px;
    }
  }
  @media screen and (max-width: 1754px) {
    > div {
      margin-bottom: 10px;
    }
  }
`;
