import { Link } from '@material-ui/core';
import React from 'react'
import { useStyles } from './SmallBlueButtonAtomStyles';

export interface SmallBlueButtonAtomProps {
    text: string,
    onClick: () => void
}

export const SmallBlueButtonAtom: React.FC<SmallBlueButtonAtomProps> = (props) => {

    const { text, onClick } = props

    const classes = useStyles(props)

    return (
        //<Button className={classes.button} disableRipple   >{text}</Button>
        <Link
            className={classes.button}
            component="button"
            variant="body2"
            underline="always"
            onClick={onClick}
            type="button"
        >
            {text}
        </Link>

    );
}