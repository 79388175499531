import { Paper, Typography } from '@material-ui/core';
import React from 'react'
import { useStyles } from './TextBoxStyles';

export interface TextBoxProps {
    image?: string,
    title?: string,
    subHeaders?: Array<string>,
    paragraphs?: Array<string>,
    titleColor?: '#403F3F' | '#A61C14' | undefined,
    bodyTextColor?: string | undefined,
    isTextBold?: boolean,
    bodyFontSize?: 18 | 20 | 28 | number | undefined,
    headerFontSize?: 40 | 24 | 21 | 32 | 28 | number,
    subHeaderFontSize?: 40 | 24 | 21 | 32 | 28 | number,
    backgroundColor?: string,
    newLine?: boolean,
    alignTitle?: 'left' | 'center' | 'right',
    alignBody?: 'left' | 'center' | 'right',
    style?: React.CSSProperties;
}

export const TextBox: React.FC<TextBoxProps> = (props: TextBoxProps) => {
    const { paragraphs, title, subHeaders, image, newLine, alignTitle, alignBody, style } = props

    const classes = useStyles(props)

    const loadParagraphs = () => {
        return paragraphs?.map((paragraph, index) => {
            return (
                <div style={style}>
                    {subHeaders?.length ? (
                        <Typography className={classes.subTitle} gutterBottom={false} align={alignTitle}>
                            {subHeaders[index]}
                        </Typography>
                    ) : ''}
                    <Typography className={classes.paragraph} gutterBottom={false} align={alignBody}>
                        {paragraph}
                    </Typography>
                    {newLine ? (<br />) : ''}
                </div>
            )
        })
    }
    return (
        <Paper elevation={0} className={classes.paper}>
            {image !== '' ? <img src={image} alt="" /> : ''}
            {title ? (
                <Typography className={classes.title} gutterBottom={false} align={alignTitle}>
                    {title}
                </Typography>
            ) : ''}
            {paragraphs?.length !== 0 ? loadParagraphs() : ''}
        </Paper>
    );
}