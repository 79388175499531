import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";

export const Container = styled.div`
  margin: 0 40px 0 40px;
`;

export const HeaderWrapper = styled.div`
  margin: 74px 40px 54px 40px;
`;

export const ButtonWrapper = styled.div`
  display: table-cell;
  border-bottom: 1px solid rgba(224, 224, 224, 1);
  border-top: 1px solid rgba(224, 224, 224, 1);
  vertical-align: middle;
  text-align: center;
  .createLabelButton {
    background: #323a87 0% 0% no-repeat padding-box;
    padding: 0.65rem;
  }
  .MuiButton-label {
    font: normal normal 16px/25px Roboto, Arial;
    color: #ffffff;
  }
`;

export const useStyles = makeStyles((theme) => ({
  paginationContainer: {
    display: "flex",
    justifyContent: "center",
    marginTop: theme.spacing(2),
  },
  searchField: {
    width: "30%",
    padding: "5px",
    borderRadius: "5px",
    border: "1px solid #323A87",
    fontSize: "16px",
    marginBottom: "5px",
    backgroundColor: "rgba(0, 0, 0, 0.04)",
  },
  filter: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "16px",
    marginBottom: "10px",

    "& label": {
      marginRight: "5px",
    },

    "& select, input": {
      marginRight: "10px",
      padding: "5px",
      fontSize: "16px",
      borderRadius: "5px",
      border: "1px solid #323A87",
      textAlign: "center",
      backgroundColor: "rgba(0, 0, 0, 0.04)",
    },

    "& .ClearButton": {
      padding: "5px",
      fontSize: "16px",
      borderRadius: "5px",
      border: "none",
      textAlign: "center",
      backgroundColor: "#323A87",
      color: "#FFF",
      cursor: "pointer",
      transition: "background-color 0.3s",
      marginRight: "10px",
    },

    "& .ClearButton:hover": {
      backgroundColor: "rgb(50, 58, 135, 0.1)",
      color: "#323A87",
    },
  },
}));
