import React from "react";
import fullServiceIcon from "../../assets/FullService.png";
import expressServiceIcon from "../../assets/ExpressService.png";
import selfServiceIcon from "../../assets/SelfService.png";
import {
  Container,
  Wrapper,
  ButtonWrapper,
  ImageWrapper,
  Title,
  Text,
} from "./ReturnTypeButtonsStyles";
import { ReturnRequestServiceType } from "../../utilities/Enums";

export interface ReturnTypesProps {
  value: ReturnRequestServiceType | undefined;
  onClick: (value: any) => void;
}

const ReturnTypeButtons: React.FC<ReturnTypesProps> = ({
  value,
  onClick,
}) => {
  return (
    <Container>
      <Wrapper>
        <ButtonWrapper
          style={{ border: value === ReturnRequestServiceType.FullService ? "2px solid red" : "0" }}
          onClick={() => onClick(ReturnRequestServiceType.FullService)}
        >
          <ImageWrapper>
            <img alt=" " src={fullServiceIcon}/>
          </ImageWrapper>
          <Title>Full Service</Title>
          <Text>
            This service provides our clients with the complete package. Our certified processor goes right to your doorstep then begins to scan the expired medications, controls, or hazards. Next he packs them with UPS tags and gives a fully detailed report of the findings. After receiving your outdates on site we begin your return request with the right parties.
          </Text>
        </ButtonWrapper>
        <ButtonWrapper
          style={{ border: value === ReturnRequestServiceType.ExpressService ? "2px solid red" : "0" }}
          onClick={() => onClick(ReturnRequestServiceType.ExpressService)}
        >
          <ImageWrapper>
            <img alt=" " src={selfServiceIcon}/>
          </ImageWrapper>
          <Title>Express Service</Title>
          <Text>
            How this service works is that the client requests UPS shipping labels through our website then ships the outdates to us. The full scanning and packing procedure happens on company grounds, which means no hassle whatsoever for the client on site. As part of this service, you will be provided a full inventory of the Controlled products, as well as all required DEA 222 forms.
          </Text>
        </ButtonWrapper>
      </Wrapper>
    </Container>
  );
};
export default ReturnTypeButtons;
