import styled from "styled-components";
import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles<Theme>(
  (theme: Theme) =>
    createStyles({
      title: {
        color: "#403F3F",
        fontSize: 35,
        fontWeight: "bold",
        paddingTop: theme.spacing(4),
      }
    })
);

export const Wrapper = styled.div`
  margin-bottom: 45px;
  .slick-prev {
    left: 2%;
  }
  .slick-next {
    right: 2%;
  }
  .MuiPaper-elevation0 {
    margin-right: 10%;
    margin-left: 10%;
  }
  .slick-list {
    margin-left: 5%;
    margin-right: 5%;
  }
  .MuiPaper-elevation3 {
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 22px;
    margin: 10px;
  }
  .slick-active .customDot {
    background-color: #323a87;
  }
`;

export const Dot = styled.div`
  background: #e5e5e5 0% 0% no-repeat padding-box;
  width: 24px;
  height: 24px;
  border-radius: 15px;
`;

