import React from 'react'
import { TextBox, TextBoxProps } from '../../atoms/Text Box/TextBox';

import { Grid } from '@material-ui/core';
import { mediumFontSize, smallFontSize } from '../../utilities/style';

export interface FourTextPapersProps {
    textBoxes: Array<TextBoxProps>
}

export const FourTextPapers: React.FC<FourTextPapersProps> = (props: FourTextPapersProps) => {
    const { textBoxes } = props


    const loadTextBox = () => {
        return (textBoxes.map((textBox) => {
            return (
                <Grid item sm={6}>
                    <TextBox subHeaderFontSize={textBox.subHeaderFontSize} subHeaders={textBox.subHeaders} bodyFontSize={smallFontSize.fontSize} headerFontSize={mediumFontSize.fontSize} image={textBox.image} paragraphs={textBox.paragraphs} title={textBox.title} titleColor={textBox.titleColor} />
                </Grid>
            )
        }))
    }

    return (

        <Grid spacing={3} container item sm={6} xs={12}>
            {loadTextBox()}
        </Grid>

    );
}