
export const createSelectUserForm = (options: Array<{ value: string, label: string }>) => {
   return [
      [
         {
            controllingName: "user",
            placeHolder: "Select user",
            isSelect: true,
            rules: {
               required: "This field is required",
            },
            options: options
         },
      ]
   ]
}