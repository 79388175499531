import { createContext, useContext, useState } from "react";

type PharmacyEnabledStatusContextType = {
  pharmacyEnabledStatusMap: Record<number, boolean>;
  setPharmacyEnabledStatus: (pharmacyId: number, enabled: boolean) => void;
  setInitialValues: (initial: any[]) => void
};

const PharmacyEnabledStatusContext =
  createContext<PharmacyEnabledStatusContextType>({
    pharmacyEnabledStatusMap: {},
    setPharmacyEnabledStatus: () => {},
    setInitialValues: () => {},
  });

export const PharmacyEnabledStatusProvider: React.FC = ({ children }) => {
  const [pharmacyEnabledStatusMap, setPharmacyEnabledStatusMap] = useState<
    Record<number, boolean>
  >({});

  const setPharmacyEnabledStatus = (pharmacyId: number, enabled: boolean) => {
    setPharmacyEnabledStatusMap((prevState) => ({
      ...prevState,
      [pharmacyId]: enabled,
    }));
  };
  const setInitialValues = (initial: any[]) => {
    let refObject = {} as Record<number | string, boolean>;
    initial.forEach((entry) => {
      refObject[entry.pharmacyId] = entry.enabled;
    });
    setPharmacyEnabledStatusMap(refObject)
  };
  return (
    <PharmacyEnabledStatusContext.Provider
      value={{
        pharmacyEnabledStatusMap,
        setPharmacyEnabledStatus,
        setInitialValues,
      }}
    >
      {children}
    </PharmacyEnabledStatusContext.Provider>
  );
};

export const usePharmacyEnabledStatus = () =>
  useContext(PharmacyEnabledStatusContext);
