import { default as SelfService } from "../../assets/SelfService.svg";
import { default as RxDisposal } from "../../assets/RxDisposal.svg";
import { default as FullService } from "../../assets/FullService.svg";
import { default as ExpressService } from "../../assets/ExpressService.svg";
import { default as Inventory } from "../../assets/Inventory.svg";
import { default as CustomerIcon } from "../../assets/customer.png";
import { CardProps } from "../../molecules/Card/CardMolecule";
import { TextBoxProps } from "../../atoms/Text Box/TextBox";
import * as strings from "../../utilities/strings";
import { mediumFontSize, smallFontSize } from "../../utilities/style";
import { logos, ncpLogos } from "../../pages/compliance//CompliancePageData";

export const firstCards: Array<CardProps> = [
  {
    title: strings.firstCardTitle,
    paragraphs: [
      strings.firstCardText,
    ],
    image: "",
    titleColor: "#403F3F",
  }, {
    title: strings.secondCardTitle,
    paragraphs: [
      strings.secondCardText,
    ],
    image: "",
    titleColor: "#403F3F",
  }, {
    title: strings.fourthCardTitle,
    paragraphs: [
      strings.fourthCardText,
    ],
    image: "",
    titleColor: "#403F3F",
  }, {
    title: strings.fifthCardTitle,
    paragraphs: [
      strings.fifthCardText,
    ],
    image: "",
    titleColor: "#403F3F",
  },
  {
    title: strings.sixthCardTitle,
    paragraphs: [
      strings.sixthCardText,
    ],
    image: "",
    titleColor: "#403F3F",
  },
];
export const secondCards: Array<CardProps> = [
  {
    title: "",
    paragraphs: [
      "We’re certified by the United States Drug Enforcement Administration",
    ],
    image: logos[0].image,
    titleColor: "#403F3F",
  },
  {
    title: "",
    paragraphs: [
      "We are a member of the National Association of Boards of Pharmacy",
    ],
    image: logos[1].image,
    titleColor: "#403F3F",
  },
  {
    title: "",
    paragraphs: [
      "We’re certified by the United States Environmental Protection Agency",
    ],
    image: logos[2].image,
    titleColor: "#403F3F",
  }
  // {
  //   title: "",
  //   paragraphs: [
  //     "We’re certified by the United States Environmental Protection Agency",
  //   ],
  //   image: logos[3].image,
  //   titleColor: "#403F3F",
  // },
  // {
  //   title: "",
  //   paragraphs: [
  //     "We’re proud to be a Carbon Neutral company",
  //   ],
  //   image: logos[4].image,
  //   titleColor: "#403F3F",
  // },
];


export const whatDoWeDoCards: Array<CardProps> = [
  {
    title: strings.whatDoWeDoGiveCreditTitle,
    paragraphs: [
      strings.whatDoWeDoGiveCreditContent,
    ],
    image: "",
    titleColor: "#403F3F",
  }, {
    title: strings.whatDoWeDoGiveDisposalTitle,
    paragraphs: [
      strings.whatDoWeDoGiveDisposalContent,
    ],
    image: "",
    titleColor: "#403F3F",
  }, {
    title: strings.whatDoWeDoGiveInventoryTitle,
    paragraphs: [
      strings.whatDoWeDoGiveInventoryContent,
    ],
    image: "",
    titleColor: "#403F3F",
  }
];
export const textBoxes: Array<TextBoxProps> =
  [
    {
      headerFontSize: mediumFontSize.fontSize,
      bodyFontSize: smallFontSize.fontSize,
      image: SelfService,
      paragraphs: [strings.serviceCardText1, strings.serviceCardText2],
      title: strings.serviceCardLabel1,
      titleColor: "#403F3F",
      subHeaders: [strings.subheaderService1, strings.subheaderService2]
    },
    {
      headerFontSize: mediumFontSize.fontSize,
      bodyFontSize: smallFontSize.fontSize,
      image: ExpressService,
      paragraphs: [strings.serviceCardText2dash],
      title: strings.serviceCardLabel2,
      titleColor: "#403F3F",

    },
    {
      headerFontSize: mediumFontSize.fontSize,
      bodyFontSize: smallFontSize.fontSize,
      image: Inventory,
      paragraphs: [strings.serviceCardText3],
      title: strings.serviceCardLabel3,
      titleColor: "#403F3F",
    },
  ];
