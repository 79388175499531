const trimFirstAndLastChars = (ndc: string) => {
  const ndcLength = ndc.length;
  return ndc.substring(1, ndcLength - 1);
};

const removeHyphens = (ndc: string) => ndc.replace(/-/g, "");

export const formatNdc = (ndc: string): string => {
  const trimmedNdc = ndc.trim();
  const noHyphensNdc = removeHyphens(trimmedNdc);
  const ndcLength = noHyphensNdc.length;
  let formattedNdc: string;
  if (ndcLength > 11) {
    formattedNdc = trimFirstAndLastChars(noHyphensNdc);
  } else {
    formattedNdc = noHyphensNdc;
  }
  return formattedNdc;
};
export const isNdcValid = (ndc: string) => {
  const formattedNdc = formatNdc(ndc);
  const formattedNdcLength = formattedNdc.length;

  if (formattedNdcLength < 9 || formattedNdcLength > 11) return false;
  return true;
};
