import styled from "styled-components";
export const Container = styled.div`
  margin: 0 40px 0 40px;
`;

export const TextWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 3rem 0;
`;

