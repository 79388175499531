import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { smallFontSize } from "../../utilities/style";
import { StatesProps } from "./States";

export interface StatesStylesProps {}
export const useStyles = makeStyles<Theme, StatesProps>((theme: Theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(2),
      textAlign: "center",
      justifyContent: "center",
      borderRadius: "5px",
      height: "10px",
      display: "flex",
      alignItems: "center",
      boxShadow: "2px 2px 5px grey;",
      "&:hover": {
        opacity: 0.85,
      },
      [theme.breakpoints.down("xs")]: {
        justifyContent: "center",
      },
    },
    noLicenseRequired: {
      background:
        "transparent linear-gradient(90deg, #211d57 80%, #333333 100%) 0% 0% no-repeat padding-box;",
    },
    rxmaxLicense: {
      background:
        "transparent linear-gradient(90deg, #A61C14 80%, #530E0A 100%) 0% 0% no-repeat padding-box;",
    },
    missingLicense: {
      background:
        "transparent linear-gradient(90deg, #6b6b6b 80%, #333333 100%) 0% 0% no-repeat padding-box;",
    },
    paperText: {
      fontSize: smallFontSize.fontSize,
      color: "white",
      [theme.breakpoints.down("xs")]: {
        paddingLeft: 0,
      },
    },
    href: {
      textDecoration: "none",
    },
  })
);
