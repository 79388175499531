import styled from "styled-components";

export const Container = styled.div`
  .slick-initialized {
    margin-top: 30px;
  }
  @media screen and (max-width: 1000px) {
    .MuiBox-root-52 {
      display: flex;
      justify-content: center;
    }
    .MuiGrid-grid-sm-6 {
      max-width: 100%;
      flex-basis: 100%;
    }
    .MuiContainer-maxWidthXl {
      padding: 0px;
    }
    .MuiGrid-container p {
      display: flex;
      align-items: center;
      margin-top: 10px;
      img {
        padding-right: 0px;
      }
    }
    .MuiContainer-maxWidthXl .MuiGrid-container {
      flex-direction: column;
      align-items: center;
      > div:first-child {
        display: flex;
        justify-content: center;
        flex-direction: column;
      }
      > div:nth-child(2) {
        align-items: flex-start;
        margin-top: 20px;
        > div > div {
          display: flex;
          flex-direction: column;
          align-items: center;
        }
      }
    }
  }
`;
