import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import useApiService from "../../utilities/useApiService";

const CheckToken: React.FC = (props) => {
  const history = useHistory();
  const { getMethod } = useApiService();
  useEffect(() => {
    const params = new URLSearchParams(history.location.search);
    const token = params.get("token");
    try {
      getMethod(`/password/changePassword?token=${token}`).then(() => {
        history.push({ pathname: "/password/resetPassword", state: token });
      });
    } catch (e) {
      console.log(e);
    }
  }, [history.location.search, history, getMethod]);
  return <div></div>;
};
export default CheckToken;
