import styled from "styled-components";

export const Container = styled.div`
  > button {
    margin-top: 20px;
  }
`;

export const HeaderWrapper = styled.div`
  margin-bottom: 1rem;
`

export const FormMoleculeWrapper = styled.div`
  margin-left: 10rem;
  margin-right: 10rem;

  @media (max-width: 600px) {
    margin-left: 5rem;
    margin-right: 5rem;
  }

  @media (max-width: 460px) {
    margin-left: 0rem;
    margin-right: 0rem;
  }
`
