import React from "react";
import { GreenSwitch } from "./GreenSwitchAtomStyles";

export interface GreenSwitchAtomProps {
  checked: boolean;
  onClick: React.MouseEventHandler | undefined;
}

const GreenSwitchAtom: React.FC<GreenSwitchAtomProps> = (
  props: GreenSwitchAtomProps
) => {
  const { checked, onClick } = props;

  return <GreenSwitch checked={checked} onClick={onClick} />;
};

export default GreenSwitchAtom
