import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import React from "react";
import Slider from "react-slick";
import { Box, Typography } from '@material-ui/core';
import { useStyles } from './CarouselStyles';
import { CardMolecule, CardProps } from "../Card/CardMolecule";
import { Wrapper, Dot } from "./CarouselStyles";
import { default as NextArrow } from "../../assets/arrow-next-carousel.svg";
import { default as PrevArrow } from "../../assets/arrow-prev-carousel.svg";
import { AlternateCard } from "../AlterenateCard/AlternateCard";

export interface CarouselProps {
  cards: Array<CardProps>;
  version: 1 | 2 | undefined;
  title?: string;
}

export const CarouselMolecule: React.FC<CarouselProps> = (
  props: CarouselProps
) => {
  const settingCardCarousel = {
    arrows: true,
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    initialSlide: 0,
    nextArrow: <img src={NextArrow} alt="nextArrow" />,
    prevArrow: <img src={PrevArrow} alt="prevArrow" />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 840,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 0,
        },
      },
      {
        breakpoint: 550,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const settingCustomerCarousel = {
    customPaging: () => <Dot className="customDot" />,
    arrows: true,
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    nextArrow: <img src={NextArrow} alt="nextArrow" />,
    prevArrow: <img src={PrevArrow} alt="prevArrow" />,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 0,
          dots: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
        },
      },
    ],
  };
  const { cards, version, title } = props;

  const classes = useStyles(props)

  const loadCards = () => {
    if (version === 1) {
      return cards.map((card) => {
        return (
          <CardMolecule
            titleColor={"#403F3F"}
            image={card.image}
            paragraphs={card.paragraphs}
            title={card.title}
            key={card.title}
          />
        );
      });
    } else if (version === 2) {
      return cards.map((card) => {
        return (
          <AlternateCard
            image={card.image}
            quote={card.paragraphs[0]}
            title={card.title}
            key={card.title}
          />
        );
      });
    }
  };

  return (
    <Wrapper>
      <Box display="flex" justifyContent="center">
          <Typography className={classes.title}>
          {title}
          </Typography>
      </Box>
      <Slider
        {...(version === 1
          ? { ...settingCardCarousel }
          : { ...settingCustomerCarousel })}
      >
        {loadCards()}
      </Slider>
    </Wrapper>
  );
};
