import React from "react";
import { useForm } from "react-hook-form";
import { CallToActionButton } from "../../atoms/callToActionButton/CallToActionButton";
import { TextBox } from "../../atoms/Text Box/TextBox";
import { ICallUsForm } from "../../network/models/FormInterfaces";
import { callUsForm } from "../../network/Services";
import { largeFontSize, smallFontSize } from "../../utilities/style";
import { useStyles, Wrapper, Container } from "./CallUsOrganismStyle";
import { callUsFormData } from "./CallUsFormData";
import { FormWrapper } from "./CallUsOrganismStyle";
import FormMolecule from "../../molecules/Form/FormMolecule";

export interface CallUsOrganismProps {}

export const CallUsOrganism: React.FC<CallUsOrganismProps> = (
  props: CallUsOrganismProps
) => {
  const { control, handleSubmit, setValue, getValues } = useForm();

  const classes = useStyles(props);

  const onSubmit = handleSubmit(async (data) => {
    callUsForm(getValues() as ICallUsForm).then((res) => {
      clearForm();
    });
  });

  const clearForm = () => {
    setValue("pharmacyName", "");
    setValue("email", "");
    setValue("name", "");
    setValue("title", "");
    setValue("body", "");
    setValue("address", "");
    setValue("phoneNumber", "");
  };

  return (
    <Container className={classes.cardContent}>
      <div className={classes.centeredText}>
        <TextBox
          bodyFontSize={smallFontSize.fontSize}
          headerFontSize={largeFontSize.fontSize}
          image={""}
          paragraphs={[""]}
          title={"Contact us"}
          titleColor={"#A61C14"}
        />
      </div>
      <TextBox
        bodyFontSize={largeFontSize.fontSize}
        headerFontSize={largeFontSize.fontSize}
        image={""}
        paragraphs={[
          "If you would like one of our sales representatives to call you, please fill out the form and submit, and we'll get in touch.",
        ]}
        title={""}
        titleColor={"#403F3F"}
        alignBody="center"
      />
      <form onSubmit={onSubmit}>
        <FormWrapper>
          <FormMolecule control={control} list={callUsFormData} />
        </FormWrapper>
        <TextBox
          bodyFontSize={largeFontSize.fontSize}
          headerFontSize={largeFontSize.fontSize}
          image={""}
          paragraphs={[
            "Rx max returns guarantee 100% privacy , your information will not be shared",
          ]}
          title={""}
          titleColor={"#403F3F"}
          alignBody="center"
          style={{ marginBottom: "50px", marginTop: "-30px" }}
        />

        <Wrapper>
          <CallToActionButton
            type="submit"
            height={"90px"}
            text={"Submit"}
            width={"950px"}
          />
        </Wrapper>
      </form>
    </Container>
  );
};
