import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { default as Banner } from "../../assets/banner.webp";
import { HeaderOrganismProps } from "./HeaderOrganism";
import styled from "styled-components";
import { largeFontSize, mediumFontSize, smallFontSize } from "../../utilities/style";
export interface HeaderOrganismStylesProps {}
export const useStyles = makeStyles<Theme, HeaderOrganismProps>(
  (theme: Theme) =>
    createStyles({
      root: {
        flexGrow: 1,
        height: 65,
        paddingBottom: 766,
        zIndex: 1,
      },
      menuButton: {
        marginRight: theme.spacing(2),
      },
      title: {
        display: "flex",
        alignItems: "center",
        color: "#FFFFFF",
        fontSize: smallFontSize.fontSize,
        textTransform: "capitalize",
        borderRadius: 0,
      },
      headphoneIcon: {
        paddingTop: 7,
      },
      navbar: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      },
      navbarContent: {
        display: "flex",
        justifyContent: "space-between",
        width: 340,
      },
      navbarMain: {
        paddingLeft: 24,
        color: "#ffffff",
        [theme.breakpoints.down("xs")]: {
          paddingLeft: 0,
        },
      },
      loginButton: {
        background:
          "transparent linear-gradient(107deg, #A61C14 0%, #530E0A 100%) 0% 0% no-repeat padding-box",
        height: 65,
        borderRadius: 0,
        width: 188,
        [theme.breakpoints.down("xs")]: {},
      },
      containerPicture: {
        background: `transparent url(${Banner}) 0% 0% no-repeat padding-box`,
        height: 866,
        position: "relative",
      },
      containerPictureHue: {
        background: "#211D57 0% 0% no-repeat padding-box",
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        mixBlendMode: "multiply",
        opacity: 0.5,
      },
      menu: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        height: 100,
      },
      mainContent: {
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        [theme.breakpoints.down("xs")]: {
          justifyContent: "flex-start",
        },
      },
      mainContentText: {
        fontSize: 50,
        fontWeight: "bold",
        color: "#ffffff",
        textShadow: "0px 3px 6px #000000DE",
        zIndex: 1,
        [theme.breakpoints.down("xs")]: {
          fontSize: largeFontSize.fontSize,
          textAlign: "center",
        },
      },
      mainContentButton: {
        border: "3px solid #FFFFFF",
        borderRadius: "15px",
        width: 400,
        height: 91,
        color: "#ffffff",
        fontSize: largeFontSize.fontSize,
        fontWeight: "bold",
        textTransform: "capitalize",
        [theme.breakpoints.down("xs")]: {
          marginTop: theme.spacing(3),
          width: 300,
          height: 70,
        },
      },
      menuContent: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },
      menuItems: {
        color: "#ffffff",
        textTransform: "capitalize",
        fontSize: mediumFontSize.fontSize,
        padding: theme.spacing(2),
        zIndex: 1,
        "&:active": {
          borderBottom: "3px sold blue",
          borderRadius: 3,
          "&::after": {
            borderBottom: "3px sold blue",
            borderRadius: 3,
          },
        },
      },
      logoIcon: {
        zIndex: 1,
      },
      content: {
        zIndex: 1,
      },
    })
);

export const Wrapper = styled.div`
  > div:first-child {
    display: flex;
    background-position-y: 50%;
    background-size: cover;
    justify-content: center;
    z-index: 0;
    > div:first-child {
      position: relative;
      z-index: 1;
    }
    > div:nth-child(2) {
      position: absolute;
      top: 0;
      left: 40px;
      right: 40px;
    }
    div:nth-child(3) {
      height: 70%;
      position: absolute;
      top: 30%;
      button {
        z-index: 1;
        margin-top: 10px;
      }
    }
  }
`;

export const Container = styled.div`
  > div:first-child {
    padding-left: 40px;
  }
`;
