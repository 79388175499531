import { Button, Divider, Link, Typography } from "@material-ui/core";
import React, { useState } from "react";
import { useStyles } from "./FooterOrganismStyles";
import { default as BriefcaseIcon } from "../../assets/briefcase.svg";
import { default as Mail } from "../../assets/mail.svg";
import { default as Telephone } from "../../assets/telephone.svg";
import { default as Fax } from "../../assets/fax.svg";
import { default as Facebook } from "../../assets/facebook.svg";
import { default as Instagram } from "../../assets/instagram.svg";
import { default as Twitter } from "../../assets/twitter.svg";
import { Logo } from "../../atoms/HeaderContent/Logo/Logo";
import { TextIcon } from "../../molecules/Text with Icon/TextIcon";
import {
  TextButton,
  TextButtonVersion,
} from "../../atoms/TextButton/TextButton";
import { IconButtonAtom } from "../../atoms/IconButton/IconButtonAtom";
import { useHistory } from "react-router-dom";
import * as strings from "../../utilities/strings";
import ModalWithText from "../../molecules/ModalWithText/ModalWithText";
import { default as LogoIcon } from '../../assets/logo.svg'
import { default as EPAlogo } from '../../assets/epa-seal.svg'
import { default as terms } from '../../assets/TermsConditions.pdf'
import { default as policy } from "../../assets/Policy.pdf";
import { default as PedigreeStatement } from "../../assets/PedigreeStatement.pdf"


export interface FooterOrganismProps { }

export const FooterOrganism: React.FC<FooterOrganismProps> = (
  props: FooterOrganismProps
) => {
  let history = useHistory();
  const [open, setOpen] = useState(false);
  const [idTimeout, setIdTimeout] = useState<any>();

  const classes = useStyles(props);

  return (
    <div className={classes.container}>
      <div className={classes.root}>
        <div className={classes.sectionLogo}>
          <Logo height={""} width={""} imgSource={LogoIcon} />
        </div>
        <Divider
          className={classes.divider}
          orientation={window.screen.availWidth > 300 ? "vertical" : "horizontal"}
        />
        <div className={classes.sectionContent}>
          <Link
            underline="none"
            component="button"
            onClick={() => history.push("/contact")}
          >
            <Typography className={classes.subtitle}>
              Contact Info & Details
            </Typography>
          </Link>
          <div className={classes.entry}>
            <TextIcon
              altText={"sd"}
              icon={BriefcaseIcon}
              text={strings.footAddress}
            />
          </div>
          <div className={classes.entry}>
            <TextIcon
              altText={"Phone number"}
              icon={Telephone}
              text={strings.footMobile}
              isTextLink={true}
              link="tel: (201) 252-8841"
            />
          </div>
          <div className={classes.entry}>
            <TextIcon altText={"Fax number"} icon={Fax} text={strings.footFax} />
          </div>
          <div className={classes.entry}>
            <TextIcon altText={"Email"} icon={Mail} text={strings.footEmail} />
          </div>
        </div>
        <Divider
          className={classes.divider}
          orientation={window.screen.availWidth > 300 ? "vertical" : "horizontal"}
        />
        <div className={classes.sectionLinks}>
          <Typography className={classes.subtitle}>Quick Links</Typography>
          <Button
            onClick={() => history.push("/about")}
            disableRipple
            className={classes.content}
          >
            {/* {strings.whoWeAre} */}
            About Us
          </Button>
          <Button
            onClick={() => history.push("/services")}
            disableRipple
            className={classes.content}
          >
            Services
            {/* {strings.whatAreWeTitle} */}
          </Button>
          <Button
            onClick={() => history.push("/contact")}
            disableRipple
            className={classes.content}
          >
            Contact Info & Details
          </Button>
          <Link
            href={terms}
            target="_blank"
            underline="none"
            className={classes.content}
            style={{ textAlign: "start" }}
          >
            <Button disableRipple className={classes.content}>
              Terms & Conditions
            </Button>
          </Link>
          <Link
            href={policy}
            target="_blank"
            underline="none"
            className={classes.content}
            style={{ textAlign: "start" }}
          >
            <Button disableRipple className={classes.content}>
              Policy
            </Button>
          </Link>
          <Link
            href={PedigreeStatement}
            target="_blank"
            underline="none"
            className={classes.content}
            style={{ textAlign: "start" }}
          >
            <Button disableRipple className={classes.content}>
              Pedigree Statement
            </Button>
          </Link>
          {/*
          <Button
            onClick={() => history.push("/compliance")}
            disableRipple
            className={classes.content}
          >

          <Link to="/files/myfile.pdf" target="_blank" download>Download</Link>
            Compliance
          </Button>*/}
        </div>
        <Divider
          className={classes.divider}
          orientation={window.screen.availWidth > 300 ? "vertical" : "horizontal"}
        />
        <div className={classes.sectionContent}>
          <div
            className={classes.epaLogo}
            onClick={(e) => {
              setOpen(true);
              setIdTimeout(
                setTimeout(() => {
                  setOpen(false);
                }, 5000)
              );
            }}
          >
            <Logo height={""} width={"8rem"} imgSource={EPAlogo} />
          </div>
          <TextButton
            onClick={() => history.push("/pharmacymanagement")}
            text={strings.startReturnButton}
            version={TextButtonVersion.MEDIUM}
          />
          <div className={classes.socials}>
            {/* <IconButtonAtom icon={Twitter} onClick={() => console.log(2)} />
            <IconButtonAtom icon={Facebook} onClick={() => console.log(2)} />
            <IconButtonAtom icon={Instagram} onClick={() => console.log(2)} /> */}
          </div>
        </div>
        {open && (
          <ModalWithText
            open={open}
            onClose={() => {
              setOpen(false);
              clearTimeout(idTimeout);
            }}
          />
        )}
      </div>
      <div className={classes.copyrightContainer}>
        <Typography>&#169; 2022 RxMax and Inventory Services LLC.</Typography>
      </div>
    </div>
  );
};
