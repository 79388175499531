import React, { useEffect } from "react";
import { useMemo } from "react";
import { Container } from "./TableWidgetsListMoleculeStyle";
import TableWidget from "../../atoms/TableWidget/TableWidgetAtom";
import { ReactComponent as NewIcon } from "../../assets/new.svg";
import { ReactComponent as PharmaciesIcon } from "../../assets/pharmacies.svg";
import { ReactComponent as NotificationbellIcon } from "../../assets/notificationbell.svg";
import { useHistory } from "react-router-dom";
import NotificationBuzzer from "../../atoms/NotificationBuzzer/NotificationBuzzer";
import { useAuthState } from "../../context/AuthContext";
import { UserRole } from "../../utilities/Enums";
import { isUserRoleEqual } from "../../utilities/checkUserRole";
export interface TableWidgetsListMoleculeProps {}

const TableWidgetsList: React.FC<TableWidgetsListMoleculeProps> = () => {
  const history = useHistory();
  const { user } = useAuthState();

  const renderList = useMemo(() => {
    const widgets = [
      {
        backgroundColor: "#B32326",
        iconBackgroundColor: "rgba(255, 255, 255, 0.2)",
        label: "Home",
        number: "120",
        icon: <PharmaciesIcon />,
        onClick: () => {
          history.push("/admin/home");
        },
      },
      {
        backgroundColor: "#636ED8",
        iconBackgroundColor: "rgba(255, 255, 255, 0.2)",
        label: "Return Requests",
        number: "120",
        icon: <NewIcon />,
        onClick: () => {
          history.push("/admin/all/pharmacies/returns");
        },
      },
      {
        backgroundColor: "#323A87",
        iconBackgroundColor: "rgba(255, 255, 255, 0.2)",
        label: "Notifications",
        number: "120",
        icon: <NotificationbellIcon />,
        onClick: () => {
          history.push("/admin/notifications");
        },
        buzzer: <NotificationBuzzer />,
      },
    ];

    const filteredWidgets = widgets.filter((item) => {
      if (isUserRoleEqual(user?.role, UserRole.FieldProcessor)) {
        return (
          item.label !== "Upload Drugs Info" && item.label !== "Notifications"
        );
      }
      return true;
    });

    return filteredWidgets.map((item, i) => (
      <TableWidget
        backgroundColor={item.backgroundColor}
        iconBackgroundColor={item.iconBackgroundColor}
        label={item.label}
        icon={item.icon}
        number={item.number}
        key={i}
        onClick={item.onClick}
        buzzer={item.buzzer}
      />
    ));
  }, [history, user?.role]);

  return <Container>{renderList}</Container>;
};
export default TableWidgetsList;
