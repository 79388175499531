import { UserRole } from "./Enums";

export const isAdmin = (userRole: string) => {
  return userRole === UserRole.Admin;
};

export const isWholesalerUser = (userRole: string) =>
  userRole === UserRole.WholesalerUser;

export const isNormalUser = (userRole: string) => userRole === UserRole.User;

export const isWarehouseUser = (userRole: string) =>
  userRole === UserRole.Warehouse;

export const isFieldProcessorUser = (userRole: string) =>
  userRole === UserRole.FieldProcessor;

export const isUserRoleEqual = (
  role: UserRole | string | undefined,
  userRole: UserRole
) => role === userRole;

export const isUserRoleNotEqual = (
  role: UserRole | string | undefined,
  userRole: UserRole
) => role !== userRole;

export const isRoleIncluded = (
  roles: UserRole[],
  userRole: UserRole | string
) => roles.includes(userRole as UserRole);
