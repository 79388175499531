import styled from "styled-components";
import { smallFontSize, mediumFontSize } from "../../utilities/style";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 80%;
  align-self: center;
  @media screen and (max-width: 1130px){
    flex-direction: column;
    justify-content: center;
    align-items: center;
    >div {
        width: 50%;
        margin-bottom: 30px;
    }
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  background-color: #efefef;
  width: 30%;
  cursor: pointer;
  min-height: 350px;
  justify-content: start;
  align-items: center;
  padding: 1.5rem;
`;

export const ImageWrapper = styled.div`
  width: 8rem;
`

export const Title = styled.div`
  color: #323a87;
  font-weight: bold;
  font-size: ${mediumFontSize.fontSize}px;
  margin: 10px 0 10px 0;
`;

export const Text = styled.div`
  color: #4a4747;
  padding: 0 10px 0 10px;
`;
