import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import styled from "styled-components";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      display: "flex",
      alignItems: "start",
    },
    checkbox: {
      paddingTop: 0
    }
  })
);


