import { createStyles, makeStyles, Theme } from "@material-ui/core";
import {
  largeFontSize,
  mediumFontSize,
  smallFontSize,
} from "../../utilities/style";

export const useStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    input: {
      fontSize: largeFontSize.fontSize,
      color: "#403F3F",
      [theme.breakpoints.down("xs")]: {
        fontSize: "21px",
      },
    },
    label: {
      color: "#403F3F",
      fontSize: mediumFontSize.fontSize,
      [theme.breakpoints.down("xs")]: {
        fontSize: smallFontSize.fontSize,
      },
    },
    errorMessage: {
      color: "red",
      marginTop: 10,
    },
    textField: {
      width: "480px",
      [theme.breakpoints.down("xs")]: {
        width: "250px",
      },
    },
  })
);
