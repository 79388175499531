import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { BaseCSSProperties } from '@material-ui/core/styles/withStyles';
import { smallFontSize } from '../../utilities/style';
import { TextIconProps } from './TextIcon';

export interface TextIconStylesProps {
    icon: BaseCSSProperties,
    entry: BaseCSSProperties,
    conent: BaseCSSProperties
}
export const useStyles = makeStyles<Theme, TextIconProps>((theme: Theme) =>
    createStyles({
        entry: {
            display: 'flex',
            alignItems: 'center',
            alignContent: 'left',
            justifyContent: 'flex-start',
            textAlign: 'left',
            margin: theme.spacing(1)
        },
        icon: {
            marginRight: theme.spacing(2)
        },
        content: {
            fontSize: smallFontSize.fontSize,
            textTransform: 'capitalize',
            borderRadius: 15,
            color: '#ffffff',
            justifyContent:'flex-start',
            '&:hover': {
                background: 'transparent' 
            },
            '&:active': {
                background: 'transparent'
            }
        },
    }),
);