import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useStyles } from './DatePickerStyles';

interface DatePickerAtomProps {
  value?: Date;
  onChange: (newValue: Date) => void;
  minDate?: Date; //e.g. moment() current day
  maxDate?: Date; //e.g. moment().add(7, 'days') after 7 days
  filterDate?:(date: Date) => boolean; //e.g. (date) => date.getDay() !== 0 //exclude sunday
  placeholderText?: string;
  className?: string;
}

const DatePickerAtom: React.FC<DatePickerAtomProps> = ({
  value,
  onChange,
  minDate,
  maxDate,
  filterDate,
  placeholderText
}) => {
  const classes = useStyles();

  return (
    <DatePicker
      selected={value}
      onChange={onChange}
      placeholderText={"Select a date"}
      minDate={minDate}
      maxDate={maxDate}
      filterDate={filterDate}
      className={classes.datePicker}
      dateFormat="MMMM d, yyyy"
    />
  );
};

export default DatePickerAtom;
