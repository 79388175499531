import React, { useEffect } from "react";
import { useQuery, useMutation, useQueryClient } from "react-query";
import {
  Container,
  TitleWrapper,
  DialogButtonsWrapper,
} from "../adminGenerateCheck/AdminGeneratePageStyles";
import { Button } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import { useForm } from "react-hook-form";
import { TextBox } from "../../atoms/Text Box/TextBox";
import {
  BigRedButton,
  ButtonVersion,
} from "../../atoms/BigRedButton/BigRedButtonAtom";
import FormMolecule from "../../molecules/Form/FormMolecule";
import useApiService from "../../utilities/useApiService";
import { IUpdateWholesalerUser } from "../../network/models/FormInterfaces";
import { IWholesalerUser } from "../../network/models/Interfaces";
import { createFormForWholesalerUserUpdating } from "./EditWholesalerUserFormData";

export interface EditWholesalerUserDialogProps {
  open: boolean;
  onClose: () => void;
  currentWholesalerUser: IWholesalerUser | undefined;
  wholesalerUsers: Array<IWholesalerUser>
}

const EditWholesalerUserDialog: React.FC<EditWholesalerUserDialogProps> = ({
  open,
  onClose,
  currentWholesalerUser,
  wholesalerUsers
}) => {
  const { getMethod, putMethod } = useApiService();
  const queryClient = useQueryClient();
  const { handleSubmit, control, getValues, setValue } = useForm();

  useEffect(() => {
    console.log("Current: ", currentWholesalerUser)
    if (currentWholesalerUser) {
      setValue('username', currentWholesalerUser.user.username);
      setValue('email', currentWholesalerUser.user.email);
      setValue('wholesaler', currentWholesalerUser.wholesaler.id);
    }
  }, [])

  const getWholesalers = async () => {
    const response = await getMethod('/wholesalers');
    return response.data;
  }
  const { data: wholesalers } = useQuery('wholesalers', getWholesalers)

  const updateWholesalerUserApi = async (bodyData: IUpdateWholesalerUser) => {
    const response = await putMethod(`/admin/wholesaler-users/${currentWholesalerUser?.user.id}`, bodyData);
    return response.data;
  }
  const onSuccessfulUpdatingOfWholesalerUser = (updatedWholesalerUser: IWholesalerUser) => {
    const newData = [...wholesalerUsers];
    newData.forEach((wholesalerUser) => {
      if (wholesalerUser.user.id === updatedWholesalerUser.user.id) {
        wholesalerUser.user = { ...updatedWholesalerUser.user };
        wholesalerUser.wholesaler = { ...updatedWholesalerUser.wholesaler };
        return;
      }
    });
    queryClient.setQueryData('wholesaler-users', newData);
    onClose();
  }
  const { mutate: updateWholesalerUser } = useMutation(updateWholesalerUserApi, {
    onSuccess: onSuccessfulUpdatingOfWholesalerUser
  })

  const onSubmit = handleSubmit((data) => {
    const requestBodyData = {
      username: data.username,
      email: data.email,
      wholesalerId: parseInt(data.wholesaler)
    }
    updateWholesalerUser(requestBodyData);
  });

  return (
    <Container>
      <Dialog
        open={open}
        fullWidth={true}
        maxWidth="md"
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <TitleWrapper>
          <TextBox
            bodyFontSize={18}
            headerFontSize={24}
            image={""}
            paragraphs={[""]}
            title={`Edit Wholesaler User with Id: ${currentWholesalerUser?.user.id}`}
            titleColor={"#A61C14"}
          />
        </TitleWrapper>
        <DialogContent>
          <form>
            <FormMolecule
              control={control as any}
              list={createFormForWholesalerUserUpdating(wholesalers, getValues)} />
          </form>
        </DialogContent>
        <DialogActions>
          <DialogButtonsWrapper>
            <BigRedButton
              text="CANCEL"
              version={ButtonVersion.SMALL}
              onClick={onClose}
            />
            <Button className="approveButton" onClick={onSubmit}>
              Confirm
            </Button>
          </DialogButtonsWrapper>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default EditWholesalerUserDialog;
