import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { BaseCSSProperties } from "@material-ui/core/styles/withStyles";
import styled from "styled-components";
import { mediumFontSize } from "../../../utilities/style";
import { HeaderMenuProps } from "./HeaderMenu";

export interface HeaderContentStylesProps {
  root: BaseCSSProperties;
}
export const useStyles = makeStyles<Theme, HeaderMenuProps>((theme: Theme) =>
  createStyles({
    menuContent: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
  })
);

export const Container = styled.div`
  .sidenav {
    display: none;
    height: 100%;
    position: fixed;
    z-index: 9999;
    top: 0;
    right: 0;
    background-color: #211D57;
    overflow-x: hidden;
    padding-top: 60px;
    transition: 0.5s;
  }

  .sidenav a {
    padding: 8px 8px 8px 32px;
    text-decoration: none;
    font-size: ${mediumFontSize.fontSize}px;
    color: #818181;
    display: block;
    transition: 0.3s;
  }

  .sidenav a:hover {
    color: #f1f1f1;
  }

  .sidenav .closebtn {
    display: none;
    position: absolute;
    top: 0;
    right: 25px;
    font-size: 36px;
    margin-left: 50px;
  }

  .burgerIconWrapper {
    display: none;
    color: white;
  }
  @media screen and (max-width: 959px) {
    .sidenav .closebtn {
      display: flex;
    }
    .sidenav {
      display: flex;
      flex-direction: column;
    }
    .burgerIconWrapper {
      display: flex;
    }
  }
`;
