import Switch from "@material-ui/core/Switch";
import { withStyles } from "@material-ui/core/styles";

export const GreenSwitch = withStyles({
  switchBase: {
    color: "white",
    "&$checked": {
      color: "white",
    },
    "&$checked + $track": {
      backgroundColor: "#0FBF3E",
    },
  },
  checked: {},
  track: {
    height: 17,
    borderRadius: 15,
  },
})(Switch);
