import React from "react";

interface TextFieldProps {
  label: string;
  className?: string;
}

const Label: React.FC<TextFieldProps> = ({ label, className = "label" }) => {
  return <span className={className}>{label}</span>;
};

export default Label;
