import {
  IloginDataParam,
  IRegister,
  IForgetPassword,
  IRequestNewPassword,
} from "./models/LoginInterfaces";
import axios, { axiosCallUS } from "./ApiConfig";
import {
  ICreatePharmacy,
  ICreatePharmacyFullInfo,
} from "./models/PharmacyInterfaces";

import {
  loginUserAttempt,
  loginUserPassed,
} from "../context/actions/authActions";
import { startLoading, stopLoading } from "../context/actions/loaderActions";
import { toastFail, toastSuccess } from "../utilities/toast";
import { ICallUsForm } from "./models/FormInterfaces";
import { ReturnRequestServiceType, UserRole } from "../utilities/Enums";
import { isUserRoleEqual } from "../utilities/checkUserRole";

export const loginUser = async (loginParam: IloginDataParam) => {
  startLoading(loginParam.loaderDispatch);
  loginUserAttempt(loginParam.dispatch);
  const data = await axios
    .post("/auth", loginParam)
    .then((res) => {
      const token = `Bearer ${res.data.token}`;
      axios.defaults.headers.Authorization = token;
      localStorage.setItem("token", `Bearer ${res.data.token}`);
      localStorage.setItem("currentUser", JSON.stringify(res.data.userInfo));
      let payload = {
        token: token,
        user: res.data.userInfo,
      };    

      loginUserPassed(loginParam.dispatch, payload);
      if (isUserRoleEqual(res.data.userInfo.role, UserRole.Warehouse) || isUserRoleEqual(res.data.userInfo.role, UserRole.FieldProcessor) ) {
        loginParam.history.push("/admin/home");
      } else {
        loginParam.history.push("/pharmacymanagement");
      }
      stopLoading(loginParam.loaderDispatch);
      return res.data;
    })
    .catch((err) => {
      if (err.response.status === 401) {
        toastFail("Wrong User Name or Password");
      }
      stopLoading(loginParam.loaderDispatch);
    });
  return data;
};

export const loginAdmin = async (loginParam: IloginDataParam) => {
  startLoading(loginParam.loaderDispatch);
  loginUserAttempt(loginParam.dispatch);
  const data = await axios
    .post("/admin/auth", loginParam)
    .then((res) => {
      const token = `Bearer ${res.data.token}`;
      axios.defaults.headers.Authorization = token;
      localStorage.setItem("token", `Bearer ${res.data.token}`);
      localStorage.setItem("currentUser", JSON.stringify(res.data.userInfo));
      let payload = {
        token: token,
        user: res.data.userInfo,
      };
      loginUserPassed(loginParam.dispatch, payload);
      loginParam.history.push("/admin/home");
      return res.data;
    })
    .catch((err) => {
      if (err.response.status === 401) {
        toastFail("Wrong User Name or Password");
      }
      stopLoading(loginParam.loaderDispatch);
    });
  stopLoading(loginParam.loaderDispatch);
  return data;
};

export const loginWholesalerUser = async (loginParam: IloginDataParam) => {
  startLoading(loginParam.loaderDispatch);
  loginUserAttempt(loginParam.dispatch);
  const data = await axios
    .post("/wholesaler-user/auth", loginParam)
    .then((res) => {
      const token = `Bearer ${res.data.token}`;
      axios.defaults.headers.Authorization = token;
      localStorage.setItem("token", `Bearer ${res.data.token}`);
      localStorage.setItem("currentUser", JSON.stringify(res.data.userInfo));
      let payload = {
        token: token,
        user: res.data.userInfo,
      };
      loginUserPassed(loginParam.dispatch, payload);
      loginParam.history.push("/wholesaler-user/pharmacymanagement");
      stopLoading(loginParam.loaderDispatch);
      return res.data;
    })
    .catch((err) => {
      if (err.response.status === 401) {
        toastFail("Wrong User Name or Password");
      }
      stopLoading(loginParam.loaderDispatch);
    });
  return data;
};

export const registerUser = (
  IRegister: IRegister,
  history: any,
  loaderDispatch: any
) => {
  startLoading(loaderDispatch);
  axios
    .post("/users", IRegister)
    .then((res) => {
      stopLoading(loaderDispatch);
      toastSuccess("User is registered successfully!");
      history.push("/signin");
    })
    .catch((err) => {
      if (err.response.status === 409) {
        toastFail("Username or Email already exists");
      }
      stopLoading(loaderDispatch);
    });
};

export const createPharmacy = async (
  createPharmacyParam: ICreatePharmacyFullInfo,
  loaderDispatch: any,
  userId: number | any
) => {
  startLoading(loaderDispatch);
  const data = await axios
    .post(`/pharmacies/users/${userId}`, createPharmacyParam)
    .then((res) => {
      stopLoading(loaderDispatch);
      return res.data;
    })
    .catch((err) => {
      if (err.response.status === 400 || err.response.status === 409) {
        toastFail(err.response.data);
    }

          stopLoading(loaderDispatch);
      console.log(err);
    });
  return data;
};

export const createReturnRequestApi = async (
  loaderDispatch: any,
  params: any,
) => {
  startLoading(loaderDispatch);
  const response = await axios.post(
    `/pharmacies/${params.pharmacyId}/returnrequests`,
    {
      serviceType: ReturnRequestServiceType.ExpressService,
    }
  );
  stopLoading(loaderDispatch);
  return response.data;
};

export const forgetPassword = async (
  forgetPassword: IForgetPassword,
  loaderDispatch: any
) => {
  startLoading(loaderDispatch);
  const data = await axios
    .post("/password/resetPassword", forgetPassword)
    .then((res) => {
      toastSuccess("Please, Check Your Email");
      stopLoading(loaderDispatch);
      return res.data;
    })
    .catch((err) => {
      if (err.response.status === 404) {
        toastFail("Email does not exist");
      }
      stopLoading(loaderDispatch);
      console.log(err);
    });
  return data;
};

export const requestNewPassword = async (
  requestNewPassword: IRequestNewPassword,
  loaderDispatch: any
) => {
  startLoading(loaderDispatch);
  const data = await axios
    .post("/password/updatePassword", requestNewPassword)
    .then((res) => {
      toastSuccess("Password changed successfully!");
      stopLoading(loaderDispatch);
      return res.data;
    })
    .catch((err) => {
      stopLoading(loaderDispatch);
      console.log(err);
    });
  return data;
};

export const fetchItem = async (ndc: string, loaderDispatch: any) => {
  startLoading(loaderDispatch);
  const data = await axios
    .post("/items/fetchDrugDetails", { ndc })
    .then((res) => {
      stopLoading(loaderDispatch);

      return res.data;
    })
    .catch((err) => {
      stopLoading(loaderDispatch);
      console.log(err);
    });
  stopLoading(loaderDispatch);

  return data;
};

export const callUsForm = async (callUsFormData: ICallUsForm) => {
  // startLoading(loaderDispatch);
  const data = await axiosCallUS
    .get("/contactus", { params: callUsFormData })
    .then((res) => {
      // stopLoading(loaderDispatch);
      console.log(res.data);
      toastSuccess(
        "We have received your message we will get back to you as soon as we can"
      );
      return res.data;
    })
    .catch((err) => {
      // stopLoading(loaderDispatch);
      console.log(err);
      toastFail(err);
    });
  return data;
};
