import React, { useState } from "react";
import { useStyles, ButtonWrapper, Container } from "./RequestNewPasswordStyle";
import { useForm } from "react-hook-form";
import {
  BigRedButton,
  ButtonVersion,
} from "../../atoms/BigRedButton/BigRedButtonAtom";
import {
  TextButton,
  TextButtonVersion,
} from "../../atoms/TextButton/TextButton";
import { requestNewPassword } from "../../network/Services";
import FormMolecule from "../../molecules/Form/FormMolecule";
import { useHistory } from "react-router-dom";
import { useLoaderDispatch, useLoaderState } from "../../context/LoaderContext";
import Loader from "../../atoms/Loader/LoaderAtom";
export interface RequestNewPasswordPageProps {}

const RequestNewPassword: React.FC<RequestNewPasswordPageProps> = (props) => {
  const { handleSubmit, control, getValues } = useForm();
  const history = useHistory();
  const [token] = useState<string>(history.location.state as string);
  const { loading } = useLoaderState();
  const loaderDispatch = useLoaderDispatch();
  const onSubmit = handleSubmit((data) => {
    requestNewPassword(
      { newPassword: data.newPassword, token: token },
      loaderDispatch
    );
    history.push("/signin");
  });
  const classes = useStyles(props);
  return loading ? (
    <Loader />
  ) : (
    <Container>
      <form onSubmit={onSubmit}>
        <div>
          <FormMolecule
            control={control}
            list={[
              [
                {
                  controllingName: "newPassword",
                  placeHolder: "New Password",
                  rules: {
                    required: "This Field is Required !",
                    validate: (val: string) => {
                      if (val.trim().length >= 6) {
                        return true;
                      }
                      return "Password should have a minimum length of 6 characters";
                    },
                  },
                  type: "password",
                },
              ],
              [
                {
                  controllingName: "confirmNewPassword",
                  placeHolder: "Confirm New Password",
                  type: "password",
                  rules: {
                    required: "This Field is Required !",
                    validate: () => {
                      return getValues("confirmNewPassword") !==
                        getValues("newPassword")
                        ? "Confirm Password does not match !"
                        : true;
                    },
                  },
                },
              ],
            ]}
          />
          <div className={classes.outerMargin}>
            <ButtonWrapper className={classes.marginInBetween}>
              <BigRedButton
                version={ButtonVersion.SMALL}
                text={"Update"}
                type="submit"
                onClick={() => {
                  console.log("submitted");
                }}
              />
            </ButtonWrapper>
            <ButtonWrapper className={classes.marginInBetween}>
              <TextButton
                text={"Cancel"}
                version={TextButtonVersion.PRIMARY}
                onClick={() => {}}
              ></TextButton>
            </ButtonWrapper>
          </div>
        </div>
      </form>
    </Container>
  );
};
export default RequestNewPassword;
