import { Theme } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/core";
import { BaseCSSProperties } from "@material-ui/core/styles/withStyles";
import { ButtonProps } from "./HeaderButton";


export interface StyleProps {
    button: BaseCSSProperties
}

export const useStyles = makeStyles<Theme, ButtonProps>((theme: Theme) =>
    createStyles({
        button: (props: ButtonProps) => ({
            width: props.width,
            borderRadius: 0,
            fontSize: props.fontSize,
            textTransform: 'capitalize',
            height: '100%',
            backgroundColor: props.background ? '' : 'transparent',
            background: props.background,
            color: props.textColor,

        })
    }));