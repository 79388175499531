import { TableRow } from "@material-ui/core";
import React from "react";
import {
  TableCellAtom,
  TableCellAtomProps,
} from "../../atoms/Table Cell/TableCellAtom";

export interface TableRowProps {
  cells: Array<TableCellAtomProps>;
}

export const TableRowAtom: React.FC<TableRowProps> = (props: TableRowProps) => {
  const { cells } = props;

  const loadCells = () => {
    return cells.map((cell) => {
      return (
        <TableCellAtom
          bold={cell.bold}
          clickable={cell.clickable}
          header={cell.header}
          onClick={cell.onClick}
          text={cell.text}
          isSwitch={cell.isSwitch}
          checked={cell.checked}
          isCustom={cell.isCustom}
          children={cell.children}
          isRounded={cell.isRounded}
          isSelect={cell.isSelect}
          selectOnChange={cell.selectOnChange}
          selectValue={cell.selectValue}
          selectOptions={cell.selectOptions}
          haveBorder={cell.haveBorder}
          shouldSort={cell.shouldSort}
          handleSort={cell.handleSort}
          isCheckBox={cell.isCheckBox}
        />
      );
    });
  };
  return (
    <TableRow style={{ backgroundColor: "#fbfbfb" }}>{loadCells()}</TableRow>
  );
};
