import { ReturnRequest } from '../../atoms/HeaderContent/Background/Background.stories';
import { TableRowProps } from "../../molecules/TableRow/TableRowAtom";
import {
  createInfoCell,
  createClickableCell
} from "../../utilities/CreateCell";
import { createRow } from "../../utilities/CreateRow";

export const createLabelsRows = (
  data: any,
  isAdmin: boolean,
  history: any
) => {
  const rows: Array<TableRowProps> = [];

  data.forEach((entry: any) => {
    const row: TableRowProps = createRow(
      createInfoCell(entry.pickupDate), //Pickup Date
      createInfoCell(entry.trackingNumber), //Tracking Number
      createClickableCell((event) => {
        history.push(
          `/pharmacies/${entry.returnRequest.pharmacy.id}/returnrequests/${entry.returnRequest.id}/shipments/${entry.id}/label`
        );
      }, "View Shipping Label"), // View Shipping Label
      createInfoCell((!entry.shipmentStatus || entry.shipmentStatus.includes("null")) ? "-" : entry.shipmentStatus) // Status
    );
    rows.push(row);
  });
  return rows;
};
