import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { AccountManagmentPageProps } from './AccountManagmentPage';

export interface AccountManagmentPageStylesProps {}

export const useStyles = makeStyles<Theme, AccountManagmentPageProps>((theme: Theme) =>
    createStyles({
        buttonWrapper: {
            display: 'flex',
            justifyContent: 'center',
            margin: theme.spacing(2),
            width: 'auto',
            marginBottom: 50
        }
    }),
);