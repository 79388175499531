import React from "react";
import "./SelectStyles.ts";
import { useStyles, SelectWrapper } from "./SelectStyles";
import { Controller } from "react-hook-form";
import { useMemo } from "react";
import Select from "@material-ui/core/Select";

interface SelectAtomProps {
  control: any;
  controllingName: string;
  defaultValue?: string;
  placeHolder?: string;
  disabled?: boolean;
  className?: {  
    root: string,
    select: string
  };
  rules?: any;
  options?: Array<{
    value: string;
    label: string;
  }>;
}

const SelectAtom: React.FC<SelectAtomProps> = ({
  control,
  controllingName,
  defaultValue='',
  placeHolder,
  options = [],
  disabled = false,
  className,
  rules
}) => {
  const classes = useStyles();
  const renderOptions = useMemo(() => {
    return options.map((option) => {
      return <option value={option.value}>{option.label}</option>;
    });
  }, [options]);
  return (
    <Controller
      control={control}
      name={controllingName}
      rules={rules}
      defaultValue={defaultValue}
      render={({ field, fieldState: { error } }) => (
        <SelectWrapper className={classes.wrapper}>
          <Select
            displayEmpty
            native
            disabled={disabled}
            classes={className ? { root: className.root, select: className.select } : { root: classes.root, select: classes.select }}
            defaultValue={defaultValue}
            inputProps={{ "aria-label": "Without label" }}
            { ...field }
          >
            <option value="" disabled>
              {placeHolder}
            </option>
            {renderOptions}
          </Select>
          <span className={classes.errorMessage}>{error && error.message}</span>
        </SelectWrapper>
      )}
    />
  );
};

export default SelectAtom;
