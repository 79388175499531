import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { PharmacyInfoTagProps } from './PharmacyInfoTag';


export const useStyles = makeStyles<Theme, PharmacyInfoTagProps>((theme: Theme) =>
    createStyles({
        container: (props: PharmacyInfoTagProps) => ({
            backgroundColor: '#F5F5F5',
            width: props.width,
            padding: theme.spacing(2),
            borderRadius: "22px",
            [theme.breakpoints.down('xs')]: {
                width: ( props.width * 60/100)
            }
        }),
    }),
);