import React, { useState } from "react";
import {
  Container,
  TitleWrapper,
  DialogButtonsWrapper
} from "./AreYouSureStyle";
import { Button } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { useForm } from "react-hook-form";
import { TextBox } from "../../atoms/Text Box/TextBox";
import {
  BigRedButton,
  ButtonVersion,
} from "../../atoms/BigRedButton/BigRedButtonAtom";

export interface AreYouSureProps {
  open: boolean;
  onClose: () => void;
  deleteObject: any;
  toBeDeletedObject: any;
}

const AreYouSure: React.FC<AreYouSureProps> = ({
  open,
  onClose,
  deleteObject,
  toBeDeletedObject
}) => {
  const handleClick = () => {
    deleteObject(toBeDeletedObject);
    onClose();
  }
  
  return (
    <Container>
      <Dialog
        open={open}
        fullWidth={true}
        maxWidth="xs"
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <TitleWrapper>
            <TextBox
              headerFontSize={24}
              title={"Are you sure?"}
              titleColor={"#A61C14"}
            />
          </TitleWrapper>
        </DialogContent>
        <DialogButtonsWrapper>
          <BigRedButton
            text="NO"
            version={ButtonVersion.SMALL}
            onClick={onClose}
          />
          <Button className="yesButton" onClick={handleClick}>
            Yes
          </Button>
        </DialogButtonsWrapper>
      </Dialog>
    </Container>
  );
};

export default AreYouSure;
