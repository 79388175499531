import { Card, CardContent} from '@material-ui/core';
import React from 'react'
import { TextBox } from '../../atoms/Text Box/TextBox';
import { useStyles } from './CardStyles';

export interface CardProps {
    image: string,
    title: string,
    paragraphs: Array<string>,
    titleColor: '#A61C14' | '#403F3F',
    height?: number
}

export const CardMolecule: React.FC<CardProps> = (props: CardProps) => {
    const { image, title, paragraphs, titleColor} = props

    const classes = useStyles(props)
    return (
        <Card elevation={3} className={classes.cardCarousel}>
            <CardContent>
                <TextBox
                    headerFontSize={21}
                    bodyFontSize={18}
                    title={title}
                    paragraphs={paragraphs}
                    image={image}
                    titleColor={titleColor}
                />
            </CardContent>
        </Card>
    );
}