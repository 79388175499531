import { IField } from "./FieldListener";

export const commonAI: Record<string, string> = {
  "01": "GTIN",
  "21": "SN",
  "17": "EXP",
  "10": "LOT",
};

export const modifiers = [
  "alt",
  "shift",
  "ctrl",
  "win",
  "fn",
  "cmd",
  "meta",
  "super",
  "hyper",
  "altgr",
];
export const skipChars = ["delete", "backspace"];
export const groupSeparatorChars = ["F8", "^"];
export const parseCompletedChar = "Enter";
export const clearChars = ["Delete", "Backspace"];
export const fields: IField[] = [
  { identifier: "01", size: 14 },
  { identifier: "17", size: 6 },
  { identifier: "21" },
  { identifier: "10" },
];
