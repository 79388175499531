import React from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import { useMemo } from "react";
import { useStyles } from "./ExpandableListAtomStyle";

export interface ExpandableListAtomProps {
  options: Array<{
    onClick: () => void;
    icon: any;
    label: string;
  } | undefined> ;
  headerLabel: string;
  headerIcon: any;
}

const ExpandableList: React.FC<ExpandableListAtomProps> = ({
  options = [],
  headerLabel,
  headerIcon,
}) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  const renderOptions = useMemo(() => {
    return options.map((option) => {
      if (option !== undefined) {
        return (
          <ListItem button onClick={option.onClick} className={classes.nested}>
          <ListItemIcon classes={{ root: classes.iconRoot }}>
            {option.icon}
          </ListItemIcon>
          <ListItemText primary={option.label} />
        </ListItem>
        )
      }
    });
  }, [options, classes.iconRoot, classes.nested]);

  return (
    <List
      component="nav"
      aria-labelledby="nested-list-subheader"
      className={classes.root}
    >
      <ListItem button onClick={handleClick}>
        <ListItemIcon classes={{ root: classes.iconRoot }}>
          {headerIcon}
        </ListItemIcon>
        <ListItemText
          classes={{ root: classes.itemListRoot }}
          primary={headerLabel}
        />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {renderOptions}
        </List>
      </Collapse>
    </List>
  );
};
export default ExpandableList;
