import styled from "styled-components";

export const Container = styled.div`
margin: 0 40px 0 40px;
`;

export const HeaderWrapper = styled.div`
    margin-top: 74px;
    margin-bottom: 54px;
`;
