import { toast } from "react-toastify";

export const toastSuccess = (message: string) => {
  toast(message, {
    hideProgressBar: true,
    type: "success",
    autoClose: 5000,
  });
};

export const toastFail = (message: string) => {
  toast(message, {
    hideProgressBar: true,
    type: "error",
    autoClose: 5000,
  });
};

export const toastInfo = (message: string) => {
  toast(message, {
    hideProgressBar: true,
    type: "info",
    autoClose: 5000,
  });
};

export const toastInfoDelayedClose = (message: string) => {
  toast(message, {
    hideProgressBar: true,
    type: "info",
    autoClose: 10000,
  });
};
