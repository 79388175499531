import styled from "styled-components";

export const FooterWrapper = styled.div`
  font: normal normal normal 19px/23px Roboto;
  letter-spacing: 0px;
  color: #403f3f;
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: auto;
  padding-top: 30px;
  margin-bottom: 30px;
`;
