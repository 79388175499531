import { createContext, useContext, useState } from 'react';

type JobStatusContextType = {
  jobStatus: string | null;
  setJobStatus: React.Dispatch<React.SetStateAction<string | null>>;
};

const JobStatusContext = createContext<JobStatusContextType>({
  jobStatus: null,
  setJobStatus: () => {},
});

export const JobStatusProvider: React.FC = ({ children }) => {
  const [jobStatus, setJobStatus] = useState<string | null>(null);

  return (
    <JobStatusContext.Provider value={{ jobStatus, setJobStatus }}>
      {children}
    </JobStatusContext.Provider>
  );
};

export const useJobStatus = () => useContext(JobStatusContext);