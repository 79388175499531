import { createStyles, makeStyles, Theme } from "@material-ui/core";
import styled from "styled-components";
import { largeFontSize } from "../../utilities/style";
import { PharmacyProfilePageProps } from "./PharmacyProfilePage";

export const useStyles = makeStyles<Theme, PharmacyProfilePageProps>(
  (theme: Theme) => createStyles({})
);

export const Wrapper = styled.div`
  .MuiInputBase-root.Mui-disabled {
    color: rgba(0, 0, 0, 0.38) !important;
  }
  @media screen and (max-width: 400px) {
    .MuiGrid-spacing-xs-3 {
      margin: 0px;
    }
  }
`;

export const CardWrapper = styled.div`
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 25px;
  margin-bottom: 30px;
  padding: 75px;
  @media screen and (max-width: 400px) {
    .MuiGrid-spacing-xs-3 {
      margin: 0px;
    }
  }
`;

export const ButtonWrapper = styled.div`
  .MuiButton-root {
    width: 100%;
    height: 70px;
    margin-top: 30px;
  }
  width: 40%;
  button:first-child {
    margin-right: 25px;
  }
  .MuiButton-text {
    margin-left: 25px;
    margin-right: 0px !important;
  }
  @media screen and (max-width: 600px) {
    button:first-child {
      margin-right: 10px;
      font-size: 20px;
      height: 50px;
    }
    .MuiButton-text {
      margin-left: 10px;
      font-size: 20px;
    }
  }
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const EditIconWrapper = styled.div`
  cursor: pointer;
  > svg {
    float: right;
    font-size: ${largeFontSize.fontSize}px;
    margin-top: 20px;
    margin-right: 20px;
  }
  @media screen and (max-width: 600px) {
    > svg {
      position: absolute;
      right: 10px;
    }
  }
`;
