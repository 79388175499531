import { createStyles, makeStyles, Theme } from "@material-ui/core";
import styled from "styled-components";
import { largeFontSize } from "../../utilities/style";

export const useStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    mainContent: {
      width: "100%",
      height: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
    },
    mainContentText: {
      fontSize: 35,
      fontWeight: "bold",
      color: "#ffffff",
      textShadow: "0px 3px 6px #000000DE",
      zIndex: 1,
      [theme.breakpoints.down("xs")]: {
        fontSize: largeFontSize.fontSize,
        textAlign: "center",
      },
    },
    logoIcon: {
      zIndex: 1,
    },
  })
);

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  > div:first-child {
    margin-top: 365px;
  }
  > div:nth-child(2){
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  }
  > div:nth-child(3) {
    position: absolute;
    top: 65px;
    left: 0;
    right: 0;
    height: 352px;
    display: flex;
    z-index: 1;
    background-position-y: 50%;
    background-size: cover;
    justify-content: center;
    > div:first-child {
      position: relative;
      z-index: 1;
    }
    > div:nth-child(2) {
      position: absolute;
      top: 0;
      left: 40px;
      right: 40px;
    }
    div:nth-child(3) {
      height: 70%;
      position: absolute;
      top: 30%;
    }
  }
`;

export const CardWrapper = styled.div`
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 25px;
  margin-bottom: 30px;
  z-index: 10;
  width: 70%;
  padding: 75px;
  .MuiPaper-elevation1 {
    border-radius: 25px;
  }
`;
