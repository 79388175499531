import React, { useState, useRef } from "react";
import { Typography } from "@material-ui/core";
import { useStyles, Wrapper, CardWrapper } from "./SmallHeaderOrganismStyles";
import { default as LoginImage } from "../../assets/loginHeaderImage.png";
import { Background } from "../../atoms/HeaderContent/Background/Background";
import { HeaderNavbar } from "../../molecules/HeaderContent/HeaderLogo+Menu/HeaderNavbar";
import { MenuItemProps } from "../../atoms/HeaderContent/MenuItem/MenuItem";
import { HeaderMolecule } from "../../molecules/Header/HeaderMolecule";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import moment from "moment";

export interface SmallHeaderOrganismProps {
  title: string;
  menuItems?: Array<MenuItemProps>;
  image: string;
}

const SmallHeaderOrganism: React.FC<SmallHeaderOrganismProps> = ({
  title = "Login",
  menuItems,
  children,
  image = LoginImage,
  ...props
}) => {
  const params: any = useParams();
  const classes = useStyles();
  const [width, setWidth] = useState("0%");
  const [name, setName] = useState(title);
  const [date, setDate] = useState(moment().format("YYYY-MM-DD"));
  const [zIndex, setZIndex] = useState(10);

  const openNav = () => {
    setWidth("100%");
    document.body.style.overflow = "hidden";
    setZIndex(-1);
  };

  useEffect(() => {
    document.body.style.overflow = "unset";
  });

  const closeNav = () => {
    setWidth("0%");
    document.body.style.overflow = "unset";
    setZIndex(10);
  };

  // useEffect(() => {
  //   if (title === "Return Request") {
  //     setTimeout(() => {
  //       let date = window.sessionStorage.getItem("returnRequestDate");
  //       window.sessionStorage.removeItem("returnRequestDate")
  //       setName(title + ' ' + date)
  //       console.log("after timeout")
  //     }, 1000)
  //   }
  // }, [window.sessionStorage]);
  useEffect(() => {
    console.log("in useeffect smallheaderoganism")
    if (title === "Return Request") {
      setName(title + " Date: " + date)
    }
  }, [date]);

  console.log("rendered");

  return (
    <Wrapper>
      <CardWrapper style={{ zIndex }}>{children}</CardWrapper>
      <HeaderMolecule openNav={openNav} closeNav={closeNav} width={width} />
      <Background imageUrl={image}>
        <div className={classes.logoIcon}>
          <HeaderNavbar
            openNav={openNav}
            closeNav={closeNav}
            menuItems={menuItems}
            width={width}
            showBurger={false}
          />
        </div>
        <div style={{ zIndex }} className={classes.mainContent}>
          <Typography className={classes.mainContentText}>
            {name === "requestId" ? params.requestId : name === "Return Request" ? "" : name}
          </Typography>
        </div>
      </Background>
    </Wrapper>
  );
};

export default SmallHeaderOrganism;
