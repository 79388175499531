import { useQuery } from "react-query";
import TableOrganism from "../../organisms/Table/TableOrganism";
import { createHeaderRow } from "../../utilities/CreateHeaderRow";
import { createNotificationsRows } from "./CreateNotificationsTable";
import { TableRowProps } from "../../molecules/TableRow/TableRowAtom";
import { useLoaderDispatch, useLoaderState } from "../../context/LoaderContext";
import { startLoading, stopLoading } from "../../context/actions/loaderActions";
import Loader from "../../atoms/Loader/LoaderAtom";
import useApiService from "../../utilities/useApiService";
import { UserRole } from "../../utilities/Enums";
import { useAuthState } from "../../context/AuthContext";

export const NotificationsPage = (
) => {
  const { user } = useAuthState();
  const userRole = user?.role ?? "";
  const { getMethod, putMethod } = useApiService();
  const loaderDispatch = useLoaderDispatch();
  const { loading } = useLoaderState();


  const getNotificationsApi = async () => {
    startLoading(loaderDispatch);
    let response;
    switch (userRole) {
      case UserRole.Admin:
      case UserRole.Warehouse:
        response = await getMethod(`admin/notifications`);
      break;
    }
    stopLoading(loaderDispatch);
    console.log("response is", response)
    if(response) {
      putMethod(`admin/notifications/update`, response.data);
    }
    return response.data;
  };
  const { data: notifications } = useQuery("notifications", getNotificationsApi);

  const getHeaderTitles = () => {
    let headerTitles: string[] = ["Number", "Created At", "Notification Type", "User"];
    return headerTitles;
  };

  const rows: Array<TableRowProps> = notifications
    ? createNotificationsRows(
      notifications,
    )
    : [];

  return loading ? (
    <Loader />
  ) : (
    <div>
      <TableOrganism
        header={createHeaderRow(getHeaderTitles())}
        rows={rows}
      />
    </div>
  );
};
