import styled from "styled-components";
import { SxProps } from "@mui/system";
import { Theme } from "@mui/material/styles";

export const statusContainer: SxProps<Theme> = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "1vh",
};

export const statusAlert: SxProps<Theme> = {
  borderRadius: "15px",
};

export const HeaderWrapper = styled.div`
  margin-top: 74px;
  margin-bottom: 54px;
  margin-left: 40px;
  margin-right: 40px;
`;