import React from 'react';
import { useQuery } from "react-query";
import TableOrganism from '../../organisms/Table/TableOrganism';
import { createHeaderRow } from '../../utilities/CreateHeaderRow';
import Loader from '../../atoms/Loader/LoaderAtom';
import { useLoaderDispatch, useLoaderState } from '../../context/LoaderContext';
import { startLoading, stopLoading } from "../../context/actions/loaderActions";
import { useHistory, useParams } from 'react-router-dom';
import { TableRowProps } from '../../molecules/TableRow/TableRowAtom';
import { createLabelsRows } from './CreateLabelsTable'
import { CallToActionButton } from '../../atoms/callToActionButton/CallToActionButton';
import { largeButton } from '../../utilities/style';
import useApiService from '../../utilities/useApiService';


export interface LabelsPageProps { }

const LabelsPage: React.FC<LabelsPageProps> = (
    props: LabelsPageProps
) => {
    const { getMethod } = useApiService();
    const history = useHistory();
    const params: any = useParams();
    const { pharmacyId, requestId } = params;
    const loaderDispatch = useLoaderDispatch();
    const { loading } = useLoaderState();

    const headerTitles = ['Pickup Date', 'Tracking Number', 'Shipping Label', 'Status'];

    const fetchShipments = async () => {
        let response;
        startLoading(loaderDispatch);
        response = await getMethod(`/pharmacies/${pharmacyId}/returnrequests/${requestId}/shipments`);
        stopLoading(loaderDispatch);
        return response.data;
    }
    const { data: shipments } = useQuery('shipments', fetchShipments);

    console.log("SHIPMENTS: ", shipments)

    const rows: Array<TableRowProps> = shipments
        ? createLabelsRows(
            shipments,
            history.location.pathname.indexOf('admin') !== -1,
            history
        )
        : [];

    return loading ? (
        <Loader />
    ) : (
        <div>
            <TableOrganism
                header={createHeaderRow([...headerTitles])}
                rows={rows}
            />
            <div style={{ marginTop: 20, marginBottom: 20 }}>
                <CallToActionButton
                    height={'80px'}
                    text='Create New Shipment'
                    width={largeButton.width}
                    onClick={() => {
                        history.location.pathname.indexOf("admin") !== -1
                            ? history.push(
                                `/admin/pharmacies/${pharmacyId}/returnrequests/${requestId}/shipments/create`
                            )
                            : history.push(
                                `/pharmacies/${pharmacyId}/returnrequests/${requestId}/shipments/create`
                            );
                    }}
                />
            </div>
        </div>
    );
};

export default LabelsPage;


// const labels = [
//     {
//         pickupDay: '7/2/2022',
//         trackingNumber: '#12313123',
//         label: 'View Shipping Label',
//         status: 'Delivered'
//     },
//     {
//         pickupDay: '7/2/2022',
//         trackingNumber: '#12313123',
//         label: 'View Shipping Label',
//         status: 'Delivered'
//     },
//     {
//         pickupDay: '7/2/2022',
//         trackingNumber: '#12313123',
//         label: 'View Shipping Label',
//         status: 'Delivered'
//     }
// ]