import { TableCellAtomProps } from "../atoms/Table Cell/TableCellAtom"

export const createRow = (...args: any[]) => {
    const row: Array<TableCellAtomProps> = []
    args.forEach( arg => {
        if(arg){
            row.push(arg)
        }
    })

    return { cells: row };
}
