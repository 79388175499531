import React from "react";
import { FooterWrapper } from "./AdminFooterOrganismStyles";

export interface AdminFooterProps {}

const AdminFooter: React.FC<AdminFooterProps> = () => {
  return (
    <FooterWrapper>&#169; 2022 RxMax and Inventory Services LLC.</FooterWrapper>
  );
};

export default AdminFooter;
