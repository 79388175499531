import React from "react";
import ExpandableList from "../../atoms/ExpandableList/ExpandableListAtom";
import styled from "styled-components";
import { ReactComponent as LogoAlternate } from "../../assets/logoAlternate.svg";
import { useHistory } from "react-router-dom";

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  .MuiAutocomplete-root {
    width: 400px;
    margin-left: 40px;
  }
  .MuiList-padding {
    padding: 0px;
  }
  height: 60px;
  align-items: center;
`;

const Wrapper = styled.div`
  display: flex;
  svg {
    cursor: pointer;
    width: 168px;
    height: 68px;
  }
`;
export interface TablePageHeaderMoleculeProps {
  searchOptions: Array<{ title: string }>;
  selectHeaderLabel: string;
  selectHeaderIcon: any;
  selectOptions: Array<{
    onClick: () => void;
    icon: any;
    label: string;
    hidden?: boolean;
  }>;
}

const AdminTablePageHeaderMolecule: React.FC<TablePageHeaderMoleculeProps> = ({
  searchOptions,
  selectHeaderLabel,
  selectHeaderIcon,
  selectOptions,
}) => {
  const history = useHistory();
  return (
    <Container>
      <Wrapper>
        <LogoAlternate
          onClick={() => {
            history.push(`/admin/home`);
          }}
        />
        {/* <AutoCompleteAtom options={searchOptions} /> */}
      </Wrapper>
      <ExpandableList
        options={selectOptions.filter((option) => !option.hidden)}
        headerLabel={selectHeaderLabel}
        headerIcon={selectHeaderIcon}
      />
    </Container>
  );
};

export default AdminTablePageHeaderMolecule;
