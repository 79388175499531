import React from 'react'
import { useStyles } from "./BackgroundStyles"

export interface BackgroundProps {
    imageUrl: string,
}

export const Background: React.FC<BackgroundProps> = (props) => {

    const { backgroundHue, backgroundImage } = useStyles(props)

    return (
        <div className={backgroundImage}>
            <div className={backgroundHue}></div>
            {props.children}
        </div>

    );
}