import { createContext, useContext, useState } from 'react';

type NotificationCountContextType = {
  notificationCount: number;
  setNotificationCount: React.Dispatch<React.SetStateAction<number>>;
};

const NotificationCountContext = createContext<NotificationCountContextType>({
  notificationCount: 0,
  setNotificationCount: () => {},
});

export const NotificationCountProvider: React.FC = ({ children }) => {
  const [notificationCount, setNotificationCount] = useState<number>(0);

  return (
    <NotificationCountContext.Provider value={{ notificationCount, setNotificationCount }}>
      {children}
    </NotificationCountContext.Provider>
  );
};

export const useNotificationCount = () => useContext(NotificationCountContext);