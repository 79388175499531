import React from "react";
import TextFieldM, { TextFieldVersion } from "../../atoms/InputText/TextFieldM";
import { ReactComponent as LogoAlternate } from "../../assets/logoAlternate.svg";
import {
  BigRedButton,
  ButtonVersion,
} from "../../atoms/BigRedButton/BigRedButtonAtom";
import Label from "../../atoms/Label/Label";
import { FormEventHandler } from "react";
import {
  FormWrapper,
  ButtonsWrapper,
  Text,
} from "./AdminForgetPasswordFormOrganismStyles";
import {
  TextButton,
  TextButtonVersion,
} from "../../atoms/TextButton/TextButton";
import { useHistory } from "react-router-dom";

export interface AdminLoginFormOrganismProps {
  onSubmit: FormEventHandler;
  control: any;
}

const AdminForgetPasswordForm: React.FC<AdminLoginFormOrganismProps> = ({
  onSubmit,
  control,
}) => {
  const history = useHistory();
  return (
    <FormWrapper onSubmit={onSubmit}>
      <LogoAlternate />
      <Label label="Forget Password" />
      <Text>Enter the email address associated with your account below</Text>
      <TextFieldM
        control={control}
        controlingName={"email"}
        placeHolder="Email Address"
        version={TextFieldVersion.AUTO}
      />
      <ButtonsWrapper>
        <BigRedButton
          text="Continue"
          version={ButtonVersion.LARGE}
          type=""
          onClick={() => { }}
        />
        <TextButton
          text={"Cancel"}
          version={TextButtonVersion.PRIMARY}
          onClick={() => history.push('/admin/login')}
        ></TextButton>
      </ButtonsWrapper>
    </FormWrapper>
  );
};

export default AdminForgetPasswordForm;
