export const initialState = {
  loading: false,
};

export const LoaderReducer = (initialState: any, action: any) => {
  switch (action.type) {
    case "START_LOADING":
      return {
        loading: true,
      };
    case "STOP_LOADING":
      return {
        loading: false,
      };
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};
