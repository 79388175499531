import { createStyles, makeStyles, Theme } from '@material-ui/core';
import styled from 'styled-components';

export interface CreateLabelPageStylesProps { }

export const useStyles = makeStyles<Theme, CreateLabelPageStylesProps>((theme: Theme) =>
    createStyles({
        form: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center"
        },
        InputWithLabelWrapper: {
            display: "flex",
            justifyContent: "center",
            marginBottom: "1.5rem",
        },
        InputWithLabel: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "#323a87",
            padding: "1rem 1.2rem",
            border: "1px solid rgba(0, 0, 0, 0.23)",
            borderRadius: 15,
            [theme.breakpoints.down("xs")]: {
                flexDirection: "column",
                paddingBottom: "1.3rem"
            },
            [theme.breakpoints.down(430)]: {
                padding: "0.5rem 0.5rem 0.7rem 0.5rem",
            },
        },
        label: {
            fontSize: "22px",
            marginRight: "1rem",
            color: "white",
            [theme.breakpoints.down("xs")]: {
                marginRight: "0",
                marginBottom: "1rem"
            },
            [theme.breakpoints.down(430)]: {
                fontSize: "20px",
            },
        },
        rootSelect: {
            border: "1px solid",
            borderRadius: 15,
            borderColor: "rgba(0, 0, 0, 0.23)",
            backgroundColor: "white",
            "&:focus": {
                borderColor: "rgba(0, 0, 0, 0.23) !important",
                borderRadius: 15,
                backgroundColor: "white",
            },
            [theme.breakpoints.down(430)]: {
                borderRadius: 10,
            },
        },
        select: {
            padding: "0 !important",
            paddingLeft: "18px !important",
            width: "100%",
            font: "normal normal normal 20px/34px Roboto, Arial",
            color: "#403F3F",
            height: "40px",
            [theme.breakpoints.down(430)]: {
                height: "35px",
                fontSize: "18px",
            },
        },
        agreement: {
            display: "flex",
            alignItems: "center",
            [theme.breakpoints.down(454)]: {
                alignItems: "start"
            },
        },
        link: {
            fontWeight: "bold",
            color: "#323A87",
            "&:hover": {
                color: "#4551ba"
            },
        },
        checkbox: {
            [theme.breakpoints.down(454)]: {
                paddingTop: "0"
            },
        }
    }),
);

export const SelectWrapper = styled.div`
    height: 40px;
    > div {
        > div {
            width: 5.5rem;
        }
    }
`;
