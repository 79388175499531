import styled from "styled-components";
import { createStyles, makeStyles, Theme } from "@material-ui/core";
export const useStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    tabs: {
      background: "#EDEDED 0% 0% no-repeat padding-box",
      borderRadius: 5,
      width: "fit-content",
      marginBottom: 24.5,
    },
  })
);
export const Container = styled.div`
  margin: 0 40px 0 40px;
  .MuiTab-wrapper {
    flex-direction: row !important;
    svg {
      margin-bottom: 0 !important;
      margin-right: 9px;
      .a {
        fill: gray;
      }
    }
  }
  .Mui-selected svg .a {
    fill: #a61c14;
  }
  .Mui-selected {
    color: #a61c14;
  }
`;

export const HeaderWrapper = styled.div`
  margin-top: 74px;
  margin-bottom: 54px;
`;

export const ButtonsWrapper = styled.div`
  display: table-cell;
  border-bottom: 1px solid rgba(224, 224, 224, 1);
  border-top: 1px solid rgba(224, 224, 224, 1);
  // border-right: 2px solid rgb(199, 196, 196);
  vertical-align: middle;
  width: 400px;
  text-align: center;
  .approveButton {
    background: #323a87 0% 0% no-repeat padding-box;
  }
  .commentButton {
    background: #403f3f 0% 0% no-repeat padding-box;
  }
  .MuiButtonBase-root {
    width: 120px !important;
    border-radius: 10px;
    margin-right: 11px;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .MuiButton-label {
    font: normal normal bold 21px/25px Roboto, Bold;
    letter-spacing: 0px;
    color: #ffffff;
    font-family: "Arial";
  }
`;

export const TitleWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 15px;
  margin-bottom: 10px;
`;

export const DialogButtonsWrapper = styled.div`
  .approveButton {
    background: #323a87 0% 0% no-repeat padding-box;
  }
  .commentButton {
    background: #403f3f 0% 0% no-repeat padding-box;
  }
  .MuiButtonBase-root {
    width: 120px !important;
    border-radius: 10px;
    margin-right: 11px;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .MuiButton-label {
    font: normal normal bold 21px/25px Roboto, Bold;
    letter-spacing: 0px;
    color: #ffffff;
    font-family: "Arial";
  }
`;

export const TextFieldWrapper = styled.div`
span {
  width: 70%;
  align-self: center;
}
`;