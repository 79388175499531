import styled from "styled-components";
export const Container = styled.div`
  margin: 0 40px 0 40px;
`;

export const TitleWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 15px;
  margin-bottom: 10px;
`;

export const DialogButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding-bottom: 1.5rem;

  .MuiButtonBase-root {
    width: 120px !important;
    border-radius: 10px;
    margin-right: 11px;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .MuiButton-label {
    font: normal normal bold 21px/25px Roboto, Bold;
    letter-spacing: 0px;
    color: #ffffff;
    font-family: "Arial";
  }
  .yesButton {
    background: #323a87 0% 0% no-repeat padding-box;
    margin-right: 0px;
  }
`;
