import { FieldListeners, IField, IFieldData } from "./FieldListener";
import {
  clearChars,
  modifiers,
  parseCompletedChar,
  skipChars,
} from "./constants";

export type ParseMode = "ai" | "value";
export class Parser {
  private readonly modifiers: string[] = modifiers;
  private readonly skipChars: string[] = skipChars;
  private readonly parseCompletedChar: string = parseCompletedChar;
  private readonly clearChars: string[] = clearChars;
  private isParserExited: boolean = false;
  private parseMode: ParseMode = "ai";
  private subscribers: FieldListeners[] = [];
  private targetSubscriber: FieldListeners | undefined;
  constructor(fields: IField[]) {
    this.subscribers = fields.map(
      (field) => new FieldListeners(this, field.identifier, field?.size)
    );
  }
  public parseChar(char: string): void {
    if (this.isChar(char, this.parseCompletedChar)) {
      this.isParserExited = true;
      return;
    }
    if (this.isCharIncluded(char, [...this.modifiers, ...this.skipChars]))
      return;
    if (this.targetSubscriber) {
      this.targetSubscriber.streamChars(char);
      return;
    }
    this.publish(char);
  }

  public resetParser(): void {
    this.isParserExited = false;
    this.removeTargetSubscriber();
    this.hardResetSubscribers();
  }

  public quickScan(char: string): void {
    if (!this.isChar(char, this.parseCompletedChar)) return;
    this.isParserExited = true;
  }

  public getParseMode() {
    return this.parseMode;
  }

  public removeTargetSubscriber() {
    this.parseMode = "ai";
    this.targetSubscriber = undefined;
  }
  public setTargetSubscriber(subscriber: FieldListeners) {
    this.resetFields();
    this.targetSubscriber = subscriber;
    this.parseMode = "value";
  }

  public shouldParserExit(): boolean {
    return this.isParserExited;
  }

  public getFields(): IFieldData[] {
    return this.subscribers.map((subscriber) => subscriber.getData());
  }

  private isChar(char: string, ref: string): boolean {
    return char.toLowerCase() === ref.toLowerCase();
  }

  private isCharIncluded(char: string, refs: string[]): boolean {
    return refs.includes(char.toLowerCase());
  }

  private publish(char: string) {
    for (let i = 0, n = this.subscribers.length; i < n; i++) {
      const isMatched = this.subscribers[i].streamChars(char);
      if (isMatched) break;
    }
  }

  private resetFields() {
    this.subscribers.forEach((subscriber) => subscriber.clearAIField());
  }

  public hardResetSubscribers() {
    this.subscribers.forEach((subscriber) => subscriber.forceClear());
  }
}
