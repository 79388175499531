import { createStyles, makeStyles, Theme } from '@material-ui/core';

export interface LabelsPageStylesProps {}

export const useStyles = makeStyles<Theme, LabelsPageStylesProps>((theme: Theme) =>
    createStyles({
        container: {
            display: "flex",
            justifyContent: "center",
            padding: "7.5rem",
            [theme.breakpoints.down("md")]: {
                padding: "5rem",
            },
            [theme.breakpoints.down("sm")]: {
                padding: "3rem",
            },
            [theme.breakpoints.down("xs")]: {
                padding: "2rem",
            },
        },
        rotate: {
            transform: "rotate(90deg)"
        },
        label: {
            width: "50%",
            margin: "1rem",
            [theme.breakpoints.down("md")]: {
                width: "60%"
            },
            [theme.breakpoints.down("sm")]: {
                width: "80%"
            },
            [theme.breakpoints.down("xs")]: {
                width: "100%"
            }
        },

    }),
);