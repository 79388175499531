import { Box, Paper } from '@material-ui/core';
import React from 'react'
import { IconAtom } from '../../atoms/Icon/IconAtom';
import { TextBox } from '../../atoms/Text Box/TextBox';
import { largeFontSize, smallFontSize } from '../../utilities/style';
import { useStyles } from './PharmacyInfoTagStyle';

export interface PharmacyInfoTagProps {
    icon: string,
    text: string,
    width: 362 | 500 
}

export const PharmacyInfoTag: React.FC<PharmacyInfoTagProps> = (props: PharmacyInfoTagProps) => {

    const { icon, text } = props

    const classes = useStyles(props)

    return (
        <Paper elevation={3} className={classes.container}>
            <Box display='flex' justifyContent="space-around" alignItems="center">
                <IconAtom altText={'lol'} icon={icon} />
                <TextBox bodyFontSize={smallFontSize.fontSize} headerFontSize={largeFontSize.fontSize} image={''} paragraphs={['']} title={text} titleColor={'#A61C14'} backgroundColor={'#F5F5F5'} />
            </Box>
        </Paper>
    );
}