import React, { useState } from "react";
import TableOrganism from "../../organisms/Table/TableOrganism";
import { TableRowProps } from "../../molecules/TableRow/TableRowAtom";
import { Container } from "./WholesalerUsersListPageStyle";
import { useHistory, withRouter } from "react-router-dom";
import { createHeaderRow } from "../../utilities/CreateHeaderRow";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { useLoaderDispatch, useLoaderState } from "../../context/LoaderContext";
import { startLoading, stopLoading } from "../../context/actions/loaderActions";
import Loader from "../../atoms/Loader/LoaderAtom";
import useApiService from "../../utilities/useApiService";
import AreYouSure from "../../molecules/AreYouSure/AreYouSure";
import { createWholesalerUsersTable } from "./CreateWholesalerUsersTable";
import EditWholesalerUserDialog from "./EditWholesalerUserDialog";
import { IWholesalerUser } from './../../network/models/Interfaces';

export interface WholesalerUsersListPageProps { }

const WholesalerUsersListPage: React.FC<WholesalerUsersListPageProps> = (props) => {
  const loaderDispatch = useLoaderDispatch();
  const { loading } = useLoaderState();
  const { getMethod, deleteMethod } = useApiService();
  const queryClient = useQueryClient();

  const [openConfirmDeleteDialog, setOpenConfirmDeleteDialog] = useState(false);
  const [openEditWholesalerUserDialog, setOpenEditWholesalerUserDialog] = useState(false);
  const [toBeDeletedObject, setToBeDeletedObject] = useState({});
  const [currentWholesalerUser, setCurrentWholesalerUser] = useState<IWholesalerUser | undefined>(undefined);

  const headerTitles = ["Number", "Username", "Associated Wholesaler", "", ""];

  const getWholesalerUsers = async () => {
    startLoading(loaderDispatch);
    const response = await getMethod('/admin/wholesaler-users');
    stopLoading(loaderDispatch);
    return response.data;
  }
  const { data: wholesalerUsers } = useQuery('wholesaler-users', getWholesalerUsers)

  const deleteWholesalerUserApi = async ({ userId }: { userId: number }) => {
    startLoading(loaderDispatch);
    await deleteMethod(`/admin/wholesaler-users/${userId}`);
    stopLoading(loaderDispatch);
    return userId;
  }
  const onSuccessfulDeletionOfWholesalerUser = (userId: number) => {
    const newData = [...wholesalerUsers];
    newData.forEach((wholesalerUser, i) => {
      if (wholesalerUser.user.id === userId) {
        newData.splice(i, 1);
        return;
      }
    });
    queryClient.setQueryData('wholesaler-users', newData);
  }
  const { mutate: deleteWholesalerUser } = useMutation(deleteWholesalerUserApi, { onSuccess: onSuccessfulDeletionOfWholesalerUser });

  const rows: Array<TableRowProps> = wholesalerUsers ? createWholesalerUsersTable(
    wholesalerUsers,
    setOpenConfirmDeleteDialog,
    setToBeDeletedObject,
    setOpenEditWholesalerUserDialog,
    setCurrentWholesalerUser
  ) : [];

  const renderEditWholesalerUserDialog = () => {
    if (openEditWholesalerUserDialog) {
      return (
        <EditWholesalerUserDialog
          open = {openEditWholesalerUserDialog}
          onClose = {() => {
            setOpenEditWholesalerUserDialog(false)
          }}
          currentWholesalerUser = {currentWholesalerUser}
          wholesalerUsers = {wholesalerUsers}
        />
      )
    }
  }

  const renderAreYouSureDialog = () => {
    if (openConfirmDeleteDialog) {
      return (
        <AreYouSure
        open={openConfirmDeleteDialog}
        onClose={() => {
          setOpenConfirmDeleteDialog(false);
        }}
        deleteObject={deleteWholesalerUser}
        toBeDeletedObject={toBeDeletedObject}
      />
      )
    }
  }

  return loading ? (
    <Loader />
  ) : (
    <Container>
      <TableOrganism
        rows={rows}
        header={createHeaderRow(headerTitles)}
      />
      {renderEditWholesalerUserDialog()}
      {renderAreYouSureDialog()}
    </Container>
  );
};

export default withRouter(WholesalerUsersListPage);
