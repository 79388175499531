import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { AlternateLogoProps } from "./AlternateLogo";

export const useStyles = makeStyles<Theme, AlternateLogoProps>((theme: Theme) =>
  createStyles({
    image: (props: AlternateLogoProps) => ({
      width: props.width,
      height: props.height,
      cursor: "pointer",
    }),
  })
);
