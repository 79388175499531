import { createStyles, makeStyles, Theme } from "@material-ui/core";
import styled from "styled-components";

export interface AddItemPageStylesProps {}
export const useStyles = makeStyles<Theme, AddItemPageStylesProps>(
  (theme: Theme) =>
    createStyles({
      buttonWrapper: {
        display: "flex",
        justifyContent: "center",
        margin: theme.spacing(2),
        width: "auto",
        marginTop: 50,
        marginBottom: 73,
      },
      buttonWrapper2: {
        marginTop: 50,
      },
    })
);

export const ImageWrapper = styled.div`
  width: 40%;
  flex-direction: column;
  display: flex;
  align-items: flex-start;
  > img {
    width: 100%;
    height: 100%;
  }
  @media screen and (max-width: 850px) {
    width: 80%;
  }
`;

export const CardsWrapper = styled.div`
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  flex-direction: row;
  display: flex;
  width: 100%;
  @media screen and (max-width: 850px) {
    flex-direction: column;
    align-items: center;
    img {
    }
    > div:first-child {
      flex-direction: column;
      width: 80%;
      margin-right: 0px;
      align-items: center;
      > div:nth-child(2) {
        width: 100%;
      }
    }
    > div:nth-child(2) {
      width: 80%;
    }
  }
`;

export const ItemEntry = styled.div`
  display: flex;
  margin-top: 50px;
  width: 60%;
  margin-right: 30px;
  img {
    height: 60%;
    margin-top: 30px;
  }
`;

export const Product = styled.div`
  width: 40%;
`;

export const ButtonWrapper = styled.div`
  .MuiButton-root {
    width: 100%;
    height: 70px;
    margin-top: 30px;
  }
  width: 40%;
  button:first-child {
    margin-right: 25px;
  }
  .MuiButton-text {
    margin-left: 25px;
    margin-right: 0px !important;
  }
  @media screen and (max-width: 600px) {
    button:first-child {
      margin-right: 10px;
      font-size: 16px;
      height: 60px;
    }
    .MuiButton-text {
      margin-left: 10px;
      font-size: 20px;
    }
  }
`;

export const ButtonsWrapper = styled.div`
display: flex;
justify-content: center;
`;