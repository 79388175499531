import { ParseMode, Parser } from "./Parser";
import { commonAI } from "./constants";

export interface IField {
  identifier: string;
  size?: number;
}
export interface IFieldData {
  fieldAI: string;
  fieldName: string;
  data: string;
}

export interface IFieldProcessor {
  streamChars: (char: string) => boolean;
  getData: () => IFieldData;
  clearAIField: () => void;
}

export class FieldListeners implements IFieldProcessor {
  private parsedChars: string = "";
  private data: string = "";
  private isCompleted: boolean = false;
  constructor(
    private readonly parser: Parser,
    private readonly identifier: string,
    private readonly size?: number
  ) {}

  public getData(): IFieldData {
    return {
      fieldAI: this.identifier,
      fieldName: commonAI[this.identifier],
      data: this.data,
    };
  }

  public streamChars(char: string): boolean {
    const parseMode = this.getParseMode();
    if (parseMode === "ai") {
      return this.matchIdentifier(char);
    }
    this.addChars(char);
    return false;
  }

  public clearAIField(): void {
    if (this.isCompleted) return;
    this.parsedChars = "";
    this.data = "";
  }

  public forceClear(): void {
    this.parsedChars = "";
    this.data = "";
  }

  private matchIdentifier(char: string) {
    this.parsedChars += char;
    if (this.parsedChars === this.identifier) {
      this.subscribe();
      return true;
    }
    return false;
  }
  private isValidChar(char: string) {
    const regex = /^[a-zA-Z0-9]$/;
    return regex.test(char);
  }

  private addChars(char: string) {
    if (!this.isValidChar(char)) {
      this.unsubscribe();
      return;
    }
    this.data += char;
    if (this.data.length === this.size) {
      this.unsubscribe();
      return;
    }
  }

  private getParseMode(): ParseMode {
    return this.parser.getParseMode();
  }

  private unsubscribe(): void {
    this.parser.removeTargetSubscriber();
    this.isCompleted = true;
  }

  private subscribe() {
    this.parser.setTargetSubscriber(this);
  }
}
