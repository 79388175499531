import React from "react";
import { useStyles, Container } from "./CreateWholesalerUserPageStyle";
import { useForm } from "react-hook-form";
import {
  BigRedButton,
  ButtonVersion,
} from "../../atoms/BigRedButton/BigRedButtonAtom";
import { useHistory, withRouter } from "react-router-dom";
import FormMolecule from "../../molecules/Form/FormMolecule";
import { useLoaderDispatch, useLoaderState } from "../../context/LoaderContext";
import { startLoading, stopLoading } from "../../context/actions/loaderActions";
import Loader from "../../atoms/Loader/LoaderAtom";
import { createFormForWholesalerUserCreation } from "./CreateWholesalerUserFormData"
import { useMutation, useQuery } from "react-query";
import useApiService from "../../utilities/useApiService";
import { ICreateWholesalerUser } from "../../../src/network/models/FormInterfaces"

export interface CreateWholesalerUserPageProps {}

export const CreateWholesalerUserPage: React.FC<CreateWholesalerUserPageProps> = (props) => {
  const history = useHistory();
  const loaderDispatch = useLoaderDispatch();
  const { loading } = useLoaderState();
  const { getMethod, postMethod } = useApiService();
  const { handleSubmit, control, getValues } = useForm();

  const getWholesalers = async () => {
    startLoading(loaderDispatch);
    const response = await getMethod('/wholesalers');
    stopLoading(loaderDispatch);
    return response.data;
  }
  const { data: wholesalers } = useQuery('wholesalers', getWholesalers)

  const postCreateWholesalerUser = async (bodyData: ICreateWholesalerUser) => {
    startLoading(loaderDispatch);
    const response = await postMethod('/admin/wholesaler-users', bodyData);
    stopLoading(loaderDispatch);
    return response.data;
  }
  const onSuccessfulCreationOfWholesalerUser = (data: any) => {
    history.push('/admin/wholesaler-users');
  }
  const { mutate: createWholesalerUser } = useMutation(postCreateWholesalerUser, {
    onSuccess: onSuccessfulCreationOfWholesalerUser
  })

  const onSubmit = handleSubmit((data) => {
    console.log("Data: ", data)
    const requestBodyData = {
      username: data.username,
      password: data.password,
      email: data.email,
      wholesalerId: parseInt(data.wholesaler)
    }
    createWholesalerUser(requestBodyData);
  });

  const classes = useStyles(props);

  return loading ? (
    <Loader />
  ) : (
    <Container>
      <form onSubmit={onSubmit}>
        <div className={classes.cardStyle}>
          <FormMolecule
            control={control}
            list={createFormForWholesalerUserCreation(wholesalers, getValues)}
          />
          <div className={classes.outerMarginSecondCard}>
            <BigRedButton
              version={ButtonVersion.LARGE}
              text={"Create Wholesaler User"}
              type="submit"
              onClick={() => {}}
            />
          </div>
        </div>
      </form>
    </Container>
  );
};

export default withRouter(CreateWholesalerUserPage);
