import React from "react";
import { useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import { CallToActionButton } from "../../atoms/callToActionButton/CallToActionButton";
import FormMolecule from "../../molecules/Form/FormMolecule";
import moment from "moment";
import { largeButton } from "../../utilities/style";
import { useLoaderDispatch, useLoaderState } from "../../context/LoaderContext";
import { startLoading, stopLoading } from "../../context/actions/loaderActions";
import Loader from "../../atoms/Loader/LoaderAtom";
import useApiService from "../../utilities/useApiService";

export interface calendarPageProps {}

const Calendar: React.FC<calendarPageProps> = (props: calendarPageProps) => {
  const { postMethod } = useApiService();

  const params: any = useParams();
  const history = useHistory();
  const { loading } = useLoaderState();
  const loaderDispatch = useLoaderDispatch();
  const createFullServiceRequest = (preferredDate: number) => {
    startLoading(loaderDispatch);
    if (history.location.pathname.indexOf("admin") !== -1) {
      postMethod(`/pharmacies/${params.pharmacyId}/returnrequests/`, {
        requestType: "ey",
        requestStatus: "nonon",
        dateDispatched: null,
        dateFulfilled: null,
        disbursements: null,
        serviceFee: "555%",
        serviceType: "FULL_SERVICE",
        preferredDate: preferredDate,
      }).then(() => {
        history.push({
          pathname: `/admin/pharmacies/${params.pharmacyId}/returns`,
          state: params.pharmacyId,
        });
      });
    } else {
      postMethod(`/pharmacies/${params.pharmacyId}/returnrequests/`, {
        requestType: "ey",
        requestStatus: "nonon",
        dateDispatched: null,
        dateFulfilled: null,
        disbursements: null,
        serviceFee: "555%",
        serviceType: "FULL_SERVICE",
        preferredDate: preferredDate,
      }).then(() => {
        history.push({
          pathname: `/pharmacies/${params.pharmacyId}/returns`,
          state: params.pharmacyId,
        });
      });
    }
    stopLoading(loaderDispatch);
  };
  const { control, handleSubmit } = useForm();
  const onSubmit = handleSubmit((data: any) => {
    let dateTimeStamp = new Date(data.preferredDate).valueOf();
    createFullServiceRequest(dateTimeStamp);
  });
  return loading ? (
    <Loader />
  ) : (
    <div>
      <form onSubmit={onSubmit}>
        <FormMolecule
          control={control}
          list={[
            [
              {
                controllingName: "preferredDate",
                type: "date",
                placeHolder: "select your preferred date",
                minDate: moment().format("YYYY-MM-DD"),
                rules: {
                  required: "This Field is Required",
                },
              },
            ],
          ]}
        />
        <CallToActionButton
          height={"80px"}
          text="Create Full Service Request"
          width={largeButton.width}
          type="submit"
        />
      </form>
    </div>
  );
};

export default Calendar;
