import { TableRowProps } from "../../molecules/TableRow/TableRowAtom";
import {
  createInfoCell,
  createClickableCell
} from "../../utilities/CreateCell";
import { createRow } from "../../utilities/CreateRow";
import { isAdmin, isNormalUser } from "../../utilities/checkUserRole";
import { IReport } from '../../network/models/Interfaces';

export const createReportsRows = (
  data: Array<IReport>,
  userRole: string,
  setOpenConfirmDelete: React.Dispatch<React.SetStateAction<boolean>>,
  setToBeDeletedObject: any, //TODO: Specify types
  setCurrentReport: React.Dispatch<React.SetStateAction<{ id: number, originalFileName: string } | undefined>>
) => {
  const rows: Array<TableRowProps> = [];
  data.forEach((entry: IReport) => {
    const row: TableRowProps = createRow(
      createInfoCell(entry.reportType), //reportType
      createInfoCell(entry.description), //Description
      createWholesalerCell(entry, userRole), //Wholesaler 
      createDownloadCell(entry, setCurrentReport), //Download
      createRemoveCell(entry, userRole, setOpenConfirmDelete, setToBeDeletedObject) //Remove
    );
    rows.push(row);
  });
  return rows;
};

const createWholesalerCell = (entry: IReport, userRole: string) => {
  if(entry.wholesaler == null && isAdmin(userRole)){
    return createInfoCell("")
  }
  if (isAdmin(userRole)) {
    return createInfoCell(entry.wholesaler.name)
  }
}

const createDownloadCell = (
  entry: IReport,
  setCurrentReport: React.Dispatch<React.SetStateAction<{ id: number, originalFileName: string } | undefined>>
) => {
  return createClickableCell(() => {
    setCurrentReport({
      id: entry.id,
      originalFileName: entry.originalFileName
    })
  }, "Download");
}

const createRemoveCell = (
  entry: IReport,
  userRole: string,
  setOpenConfirmDelete: React.Dispatch<React.SetStateAction<boolean>>,
  setToBeDeletedObject: any
) => {
  if (isAdmin(userRole)) {
    return createClickableCell(() => {
      setOpenConfirmDelete(true);
      setToBeDeletedObject({
        id: entry.id
      })
    }, "Remove")
  }
}
