import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from "@mui/icons-material/Delete"

interface AccordionProps {
    name: string;
    title: {};
    subTitle: any;
    body?: {}
    onClickEvent?: ((clicked: boolean) => void) | null;
    deleteIcon?: boolean;
    onDelete?: ((accordion: string) => void) | null;
    id?: string
    hasError?: number
    alwaysOpened?: boolean
    openClosed?: boolean
}

export default function ControlledAccordions({
    name,
    title,
    subTitle,
    body = false,
    onClickEvent,
    deleteIcon,
    onDelete,
    hasError = 0,
    alwaysOpened = false,
    openClosed
}: AccordionProps) {
    let x: string | boolean;
    if (openClosed) {
        x = false
    } else {
        x = name
    }

    const [expanded, setExpanded] = React.useState<string | false>(x);
    const [openAccHasError, setOpenAccHasError] = React.useState<boolean>(hasError ? true : false);

    React.useEffect(() => {
        setOpenAccHasError(hasError ? true : false)
    }, [hasError])

    const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
        openClosed = false;
        if (!alwaysOpened) {
            if (openAccHasError) {
                setOpenAccHasError(false)
            }
            else {
                setExpanded(isExpanded ? panel : false);
            }
        }
    };

    const handleOnClick = (e: React.MouseEvent<HTMLDivElement>) => {
        onClickEvent && onClickEvent(true)
    }

    const handleDelete = () => {
        onDelete && onDelete(name)
    }
    return (
        <div>
            <Accordion
                expanded={((expanded === name) || (true && openAccHasError))}
                onChange={handleChange(name)}
                onClick={handleOnClick}
                id={name}
                style={{ marginBottom: "0.5rem" }}
            >
                <AccordionSummary
                    expandIcon={body ? <ExpandMoreIcon /> : <AddIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                >
                    <div style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                        <Typography sx={{ fontSize: 20, color: "#a61c14", fontWeight: 'bold' }}>
                            {title}
                        </Typography>
                        <Typography sx={{ color: 'green', fontWeight: "bold" }}>{subTitle}</Typography>
                    </div>
                    {deleteIcon && (
                        <DeleteIcon sx={{ color: 'text.secondary' }} onClick={handleDelete} />
                    )}
                </AccordionSummary>
                <AccordionDetails >
                    <Typography>
                        {body}
                    </Typography>
                </AccordionDetails>
            </Accordion>

        </div>
    );
}
