import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { BaseCSSProperties } from "@material-ui/core/styles/withStyles";
import { BackgroundProps } from "./Background";

export interface BackgroundStylesStyleProps {
  backgroundImage: BaseCSSProperties;
  backgroundHue: BaseCSSProperties;
}

export const useStyles = makeStyles<Theme, BackgroundProps>((theme: Theme) =>
  createStyles({
    backgroundHue: {
      background: "#211D57 0% 0% no-repeat padding-box",
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      mixBlendMode: "multiply",
      opacity: 0.5,
      zIndex: -1,
    },
    backgroundImage: (props: BackgroundProps) => ({
      background: `transparent url(${props.imageUrl}) 0% 0% no-repeat padding-box`,
      height: 866,
      position: "relative",
      zIndex: -5,
      [theme.breakpoints.down("xs")]: {
        height: 500,
      },
    }),
  })
);
