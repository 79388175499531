export const buzzerStyles = `
  .notification-buzzer {
    position: absolute;
    display: flex;
    align-items: center;
  }
  
  .notification-buzzer .count {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    background-color: red;
    color: white;
    font-size: 14px;
    border-radius: 50%;
    margin-right: 156px;
    margin-top: -22px;
  }
`;