import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { largeFontSize } from "../../../utilities/style";
import { OurServicesOrganismProps } from "./OurServicesOrganism";

export interface OurServicesOrganismStylesProps {}
export const useStyles = makeStyles<Theme, OurServicesOrganismProps>(
  (theme: Theme) =>
    createStyles({
      title: {
        color: "#403F3F",
        fontSize: 35,
        fontWeight: "bold",
        paddingBottom: theme.spacing(6),
      },
      container: (props: OurServicesOrganismProps) => ({
        backgroundColor: props.backgroundColor,

        [theme.breakpoints.down("xs")]: {
          padding: theme.spacing(1),
          paddingLeft: theme.spacing(3),
          paddingRight: theme.spacing(4),
          paddingTop: theme.spacing(2),
        },
      }),
      imageWrapper: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      },
      image: {
        width: "65%",
      },
      readmoreRed: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        [theme.breakpoints.down("xs")]: {},
      },
      subtitleRed: {
        fontSize: largeFontSize.fontSize,
        color: "#A61C14",
        fontWeight: "bold",
        paddingTop: theme.spacing(3),
        [theme.breakpoints.down("xs")]: {},
      },
      arrow: {
        paddingLeft: theme.spacing(2),
        [theme.breakpoints.down("xs")]: {
          padding: theme.spacing(3),
        },
      },
    })
);
