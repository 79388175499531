import React, { useState } from "react";
import TableOrganism from "../../organisms/Table/TableOrganism";
import { Container, useStyles } from "./AdminTablePageStyles";
import { useHistory, withRouter } from "react-router-dom";
import { createAdminTableRows } from "./AdminTableData";

import { createHeaderRow } from "../../utilities/CreateHeaderRow";
import { useQuery, useQueryClient } from "react-query";
import { AdminTable } from "../../network/models/TableInterfaces";
import { useLoaderDispatch } from "../../context/LoaderContext";
import { startLoading, stopLoading } from "../../context/actions/loaderActions";
import useApiService from "../../utilities/useApiService";
import { usePharmacyEnabledStatus } from "../../context/PharmacyEnabledStatusContext";
import { useNotificationCount } from "../../context/NotificationContext";
import { Box, Pagination } from "@mui/material";
import { Input } from "@material-ui/core";
import { useAuthState } from "../../context/AuthContext";
import { UserRole } from "../../utilities/Enums";
import { isUserRoleEqual } from "../../utilities/checkUserRole";

export interface AdminTablePageProps {}
const AdminTablePage: React.FC<AdminTablePageProps> = (props) => {
  const { user } = useAuthState();
  const [sortedData, setSortedData] = useState<any>([]);
  const [sortDirection, setSortDirection] = useState("asc");
  const [currentPage, setCurrentPage] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const pageSize = 10;
  const {
    pharmacyEnabledStatusMap,
    setPharmacyEnabledStatus,
    setInitialValues,
  } = usePharmacyEnabledStatus();
  const { setNotificationCount } = useNotificationCount();
  const loaderDispatch = useLoaderDispatch();
  let history = useHistory();
  const queryClient = useQueryClient();
  const { getMethod } = useApiService();

  const fetchPharmacies = async (page: number, size: number) => {
    startLoading(loaderDispatch);
    let countResponse = await getMethod(`admin/notifications/unseen-count`);
    let count = countResponse.data;
    setNotificationCount(count);

    const response = await getMethod(
      `/admin/dashboard?page=${page}&size=${size}&searchValue=${searchValue}`
    );
    stopLoading(loaderDispatch);
    setInitialValues(response.data.content);
    setSortedData(response.data.content);
    return response.data;
  };
  const { data: adminTableData } = useQuery(
    ["adminTable", currentPage, pageSize, searchValue],
    () => fetchPharmacies(currentPage - 1, pageSize)
  );
  const returnedData: Array<AdminTable> = sortedData;
  const rows: Array<any> = sortedData
    ? createAdminTableRows(
          returnedData,
          history,
          setPharmacyEnabledStatus,
          pharmacyEnabledStatusMap,
          queryClient
        )
    : [];
  const headerTitles = [
    "Pharmacies",
    "User Account",
    "Return Requests",
    "Enable/Disable",
  ];
  const handleSort = () => {
    if (sortDirection === "asc") {
      const newData = [
        ...sortedData.sort((a: any, b: any) =>
          b.accountUsername.localeCompare(a.accountUsername)
        ),
      ];
      setSortDirection("desc");
      setSortedData(newData);
    } else {
      const newData = [
        ...sortedData.sort((a: any, b: any) =>
          a.accountUsername.localeCompare(b.accountUsername)
        ),
      ];
      setSortDirection("asc");
      setSortedData(newData);
    }
  };
  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    page: number
  ) => {
    setCurrentPage(page);
  };
  const classes = useStyles();

  return (
    <Container>
      <Box>
        <Input
          id="searchField"
          type="text"
          value={searchValue}
          onChange={(e) => {
            setCurrentPage(1);
            setSearchValue(e.target.value);
          }}
          placeholder="Search By User Account or By Pharmacy Name"
          className={classes.searchField}
        />
      </Box>
      <TableOrganism
        rows={rows}
        header={createHeaderRow(headerTitles, handleSort)}
      />
      <Box className={classes.paginationContainer}>
        <Pagination
          count={adminTableData?.totalPages}
          page={currentPage}
          onChange={handlePageChange}
        />
      </Box>
    </Container>
  );
};
export default withRouter(AdminTablePage);
