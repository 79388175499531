import React from 'react'
import { useStyles } from './LogoStyles'

export interface LogoProps {
    height: string,
    width: string,
    imgSource: any
}

export const Logo: React.FC<LogoProps> = (props: LogoProps) => {

    const {image} = useStyles(props)

    return (
        <div > 
            <img className={image} src={ props.imgSource } alt="" />
        </div>
    );
}