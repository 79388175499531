import { TableRowProps } from "../../molecules/TableRow/TableRowAtom";
import { ItemsTable } from "../../network/models/TableInterfaces";
import { createInfoCell } from "../../utilities/CreateCell";
import { createRow } from "../../utilities/CreateRow";

export const createVerifiedItemsRows = (data: Array<ItemsTable>) => {
  const rows: Array<TableRowProps> = [];
  data.forEach((entry) => {
    console.log(entry);
    const row: TableRowProps = createRow(
      createInfoCell(entry.ndc), //NDC
      createInfoCell(entry.manufacturer), //Manufacturer
      createInfoCell(entry.description), //Product
      createInfoCell(entry.packageSize), //Pkg Size
      // createInfoCell(entry.requestType), //Type
      createInfoCell(entry.fullQuantity.toString(10)), //Full Quantity
      createInfoCell(entry.partialQuantity.toString(10)), //Partial Quantity
      createInfoCell(entry.controlledSubstanceCode), //CSC
      createInfoCell(parseFloat(entry.extendedPrice).toFixed(2)), //Extended Price
      createInfoCell(parseFloat(entry.extendedUnitPrice).toFixed(2)), //Extended Unit Price
      createInfoCell(entry.expirationDate), //Expiration Date
      createInfoCell(entry.lotNumber), //Lot #
      createInfoCell(entry.status),
      createInfoCell(entry.createdAt)
    );
    rows.push(row);
  });
  return rows;
};
