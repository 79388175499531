import { TableCellAtomProps } from "../atoms/Table Cell/TableCellAtom";
import { createHeaderCell } from "./CreateCell";

export const createHeaderRow = (headerTitles: any[], handleSort?: any) => {
  const headerCells: Array<TableCellAtomProps> = [];
  headerTitles.forEach((item: string) => {
    let headCell;
    if (item === "User Account") {
      headCell = createHeaderCell(item, true, handleSort);
    } else {
      headCell = createHeaderCell(item);
    }
    headerCells.push(headCell);
  });
  return { cells: headerCells };
};
