import React from "react";
import { default as Logo } from "../../../assets/logoSmall.svg";
import { useStyles } from "./AlternateLogoStyles";
import { useHistory } from "react-router-dom";

export interface AlternateLogoProps {
  height: string;
  width: string;
}

export const AlternateLogo: React.FC<AlternateLogoProps> = (
  props: AlternateLogoProps
) => {
  const history = useHistory();
  const { image } = useStyles(props);

  return (
    <div>
      <img
        onClick={() => {
          history.push("/");
        }}
        className={image}
        src={Logo}
        alt="logo"
      />
    </div>
  );
};
