import React from "react";
import TextFieldM, { TextFieldVersion } from "../../atoms/InputText/TextFieldM";
import { SmallBlueButtonAtom } from "../../atoms/SmallBlueButton/SmallBlueButtonAtom";
import { ReactComponent as LogoAlternate } from "../../assets/logoAlternate.svg";
import {
  BigRedButton,
  ButtonVersion,
} from "../../atoms/BigRedButton/BigRedButtonAtom";
import Label from "../../atoms/Label/Label";
import { FormEventHandler } from "react";
import { FormWrapper } from "./AdminLoginFormOrganismStyles";

export interface AdminLoginFormOrganismProps {
  onSubmit: FormEventHandler;
  control: any;
  onForgetPasswordClick: () => void;
}

const AdminLoginFormOrganism: React.FC<AdminLoginFormOrganismProps> = ({
  onSubmit,
  control,
  onForgetPasswordClick,
}) => {
  return (
    <FormWrapper onSubmit={onSubmit}>
      <LogoAlternate />
      <Label label="Login" />
      <TextFieldM
        control={control}
        controlingName={"firstName"}
        placeHolder="User Name"
        version={TextFieldVersion.AUTO}
      />
      <TextFieldM
        control={control}
        controlingName={"password"}
        placeHolder="Password"
        type="password"
        version={TextFieldVersion.AUTO}
      />
      <SmallBlueButtonAtom
        onClick={onForgetPasswordClick}
        text="Forgot your password?"
      />
      <BigRedButton
        text="Login"
        version={ButtonVersion.LARGE}
        type="submit"
        onClick={() => ""}
      />
    </FormWrapper>
  );
};

export default AdminLoginFormOrganism;
