import React, { useState, useEffect } from "react";
import Dropzone from "react-dropzone";
import { DropzoneOptions } from "react-dropzone";
import { DropzoneStyled, ErrorWrapper } from "./UploadFileAreaStyle";
import { Controller, useForm } from "react-hook-form";
import { Typography } from "@material-ui/core";

export interface UploadFileAreaProps extends DropzoneOptions {
  control: any
  controllingName: string
  onDrop: any
  rules?:any
  file: any
  error?: boolean
  errorMessage?: string
  fileSupported?: '.pdf' | '.csv' //TODO: Change to filesSupported when we enable multiple uploads
}

export const UploadFileArea: React.FC<UploadFileAreaProps> = ({
  control,
  controllingName,
  onDrop,
  file,
  rules,
  error,
  errorMessage,
  fileSupported
}) => {

  const renderFileSupported = () => {
    if (fileSupported) {
      return (
        <h5>File type supported: {fileSupported}</h5>
      )
    } else {
      return (
        <h5>File type supported: .pdf</h5>
      )
    }
  }

  return (
    <>
      <DropzoneStyled>
        <Dropzone
          onDrop={onDrop}
          maxFiles={1}
        >
          {({ getRootProps, getInputProps }) => (
            <div {...getRootProps()}>
              <Controller
                control={control}
                name={controllingName}
                rules={rules}
                render={(): any => (
                  <input {...getInputProps({})} />
                )}
              />
              <h4>Drag and drop a file OR click here to select a file</h4>
              {renderFileSupported()}
              {file && (
                <div>
                  <strong>Selected file:</strong> {file.name}
                </div>
              )}
            </div>
          )}
        </Dropzone>
      </DropzoneStyled>
      {error && (
        <ErrorWrapper>
          <Typography>
            {errorMessage}
          </Typography>
        </ErrorWrapper>
      )}
    </>

  );
};

export default UploadFileArea;
