import { Button } from '@material-ui/core';
import React from 'react'
import { useStyles } from './BigRedButtonAtomStyle';

export interface BigRedButtonAtomProps {
    text: string,
    onClick: () => void,
    type?: string,
    version: ButtonVersion,
    disabled?: boolean
}
export enum ButtonVersion {
    SMALL,
    LARGE,

}

export const BigRedButton: React.FC<BigRedButtonAtomProps> = (props) => {

    const { text, onClick, disabled, version } = props

    const classes = useStyles(props)

    return (
        <Button disabled={disabled} className={version === ButtonVersion.LARGE ? classes.buttonLarge : classes.buttonSmall} disableElevation variant="contained" type="submit" onClick={onClick}>{text}</Button>
    );
}