import { createStyles, makeStyles, Theme } from "@material-ui/core";
import styled from "styled-components";
import { smallFontSize } from '../../utilities/style';

export interface SignInPage {}
export const useStyles = makeStyles<Theme, SignInPage>((theme: Theme) =>
  createStyles({
    containerStyle: {
      flex: 1,
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    },
    outerMargin: {
      marginBottom: 20,
      display: "flex",
      justifyContent: "start",
      marginLeft: "15%",
      [theme.breakpoints.down("sm")]: {
        display: "flex",
        alignItems: "center",
        marginLeft: 0,
        justifyContent: "center",
      },
    },
    // outerMarginSecondCard: {
    //   marginBottom: 20,
    //   alignItems: "center",
    //   justifyContent: "center",
    //   flexDirection: "row",
    //   display: "flex",
    // },
    text: {
      fontSize: smallFontSize.fontSize,
      fontWeight: 'bold',
      color: '#323A87'
    },
    loginPart: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center"
    }
  })
);

export const Container = styled.div`
  form > div > div {
    align-items: center;
    > div {
      width: 70%;
      margin-top: 10px;
    }
  }
`;
