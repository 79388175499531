import React from "react";
import { useForm } from "react-hook-form";
import { Container, Wrapper, ButtonWrapper } from "./AdminAddPharmacyPageStyle";
import useApiService from "../../utilities/useApiService";
import { useQuery } from "react-query";
import { CallToActionButton } from "../../atoms/callToActionButton/CallToActionButton";
import { largeButton } from "../../utilities/style";
import { useHistory } from "react-router-dom";
import FormMolecule from "../../molecules/Form/FormMolecule";
import { createSelectUserForm } from "./FormData";

export interface AdminAddPharmacyPageProps { }

const AdminAddPharmacyPage: React.FC<AdminAddPharmacyPageProps> = (props) => {
  const { control, handleSubmit } = useForm();
  const { getMethod } = useApiService();
  let history = useHistory();

  const fetchUsers = async () => {
    const response = await getMethod('/admin/users');
    return response.data;
  }
  const { data: users } = useQuery('users', fetchUsers);

  const mapUsersToOptions = () => {
    const filteredUsers = users?.filter((user: any) => user.role === 'USER');
    
    return filteredUsers?.map((user: any) => {
      return { label: user.username, value: user.id };
    }) || [];
  }

  const handleClick = handleSubmit((data) => {
    history.push(`/admin/users/${data.user}/pharmacy/add`)
  })
  

  return (
    <Container>
      <Wrapper>
        <FormMolecule
          control={control}
          list={createSelectUserForm(mapUsersToOptions())}
        />
        <ButtonWrapper>
          <CallToActionButton
            height={"80px"}
            text="Add Pharmacy"
            width={largeButton.width}
            onClick={handleClick}
          />
        </ButtonWrapper>
      </Wrapper>
    </Container>
  );
};
export default AdminAddPharmacyPage;
