import { createStyles, makeStyles, Theme } from "@material-ui/core";
import styled from "styled-components";
import { smallFontSize } from "../../utilities/style";

export interface CompliancePageStylesProps {}
export const useStyles = makeStyles<Theme, CompliancePageStylesProps>(
  (theme: Theme) =>
    createStyles({
      disclaimer: {
        color: "#A61C14",
        fontSize: smallFontSize.fontSize,
        marginTop: 28,
      },
      flexContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexWrap: "wrap",
      },
      colFlex: {
        flexDirection: "column",
      },
      rowFlex: {
        flexDirection: "row",
      },
      fitContentWidth: {
        width: "fit-content",
      },
      statesGap: {
        margin: "10px",
      },
    })
);

export const Wrapper = styled.div`
  @media screen and (max-width: 600px) {
    .MuiGrid-container {
      flex-direction: column;
      align-items: center;
    }
    img {
      margin-top: 10px;
    }
  }
`;
