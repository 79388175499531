import { TableRowProps } from "../../molecules/TableRow/TableRowAtom";
import { AdminTable } from "../../network/models/TableInterfaces";
import {
  createClickableCell,
  createSwitchCell,
} from "../../utilities/CreateCell";
import { createRow } from "../../utilities/CreateRow";
import axios from "../../network/ApiConfig";
import { BaseSyntheticEvent } from "react";
import { QueryClient } from "react-query";

export const createAdminTableRows = (
  data: Array<AdminTable>,
  history: any,
  setStatus: any,
  status: Record<number, boolean>,
  queryClient: QueryClient
) => {
  const rows: Array<TableRowProps> = [];
  data.forEach((entry) => {
    const row: TableRowProps = createRow(
      createClickableCell(
        () =>
          history.push({
            pathname: `/admin/pharmacies/${entry.pharmacyId}`,
            state: "admin",
          }),
        entry.pharmacyName
      ), //Pharmacies
      createClickableCell(
        () =>
          history.push({
            pathname: `/admin/${entry.userId}/pharmacy/management`,
            state: "admin",
          }),
        entry.accountUsername
      ), //User Account
      createClickableCell(
        () =>
          history.push({
            pathname: `/admin/pharmacies/${entry.pharmacyId}/returns`,
            state: entry.pharmacyId,
          }),
        entry.numberOfReturns.toString()
      ), //New Request
      createSwitchCell(
        entry.enabled,
        entry.pharmacyId,
        async (e: BaseSyntheticEvent) => {
          try {
            const response = await axios.put(
              `/pharmacies/${entry.pharmacyId}/enable-disable?enable=${e.target.checked}`
            );
            if (response.status === 200) {
              queryClient.invalidateQueries("adminTable");
            } else {
              console.log("Failed to toggle pharmacy");
            }
            return response.data;
          } catch (error) {
            console.log(error);
          }
        }
      ) // Enable/Disable
    );
    rows.push(row);
  });
  return rows;
};
