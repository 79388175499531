import React from "react";
import {
  Container,
  TitleWrapper,
  DialogButtonsWrapper,
} from "./AdminTableRequestsPageStyles";
import { Button } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import TextFieldM, { TextFieldVersion } from "../../atoms/InputText/TextFieldM";
import { useForm } from "react-hook-form";
import { TextBox } from "../../atoms/Text Box/TextBox";
import {
  BigRedButton,
  ButtonVersion,
} from "../../atoms/BigRedButton/BigRedButtonAtom";
import { setConstantValue } from "typescript";

export interface AdminTableRejectDialogProps {
  open: boolean;
  selectedItem: any;
  updateStatus: any;
  addComment: any;
  onClose: () => void;
}

const AdminTableRejectDialog: React.FC<AdminTableRejectDialogProps> = ({
  open,
  selectedItem,
  updateStatus,
  onClose
}) => {
  const { control, handleSubmit, setValue } = useForm();

  const onSubmit = handleSubmit((data) => {
    updateStatus({
      selectedOption: "N_RETURNABLE",
      itemId: selectedItem.id,
      comment: data.comment
    });
    onClose();
    setValue('comment', '')
  });

  return (
    <Container>
      <Dialog
        open={open}
        fullWidth={true}
        maxWidth="md"
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <TitleWrapper>
          <TextBox
            bodyFontSize={18}
            headerFontSize={24}
            image={""}
            paragraphs={[""]}
            title={"Comment"}
            titleColor={"#A61C14"}
          />
        </TitleWrapper>
        <DialogContent>
          <form onSubmit={onSubmit}>
            <TextFieldM
              placeHolder="Comment"
              controlingName="comment"
              control={control}
              version={TextFieldVersion.AUTO}
              rules={{
                required: "This Field is Required",
              }}
            />
          </form>
        </DialogContent>
        <DialogActions>
          <DialogButtonsWrapper>
            <BigRedButton
              text="CANCEL"
              version={ButtonVersion.SMALL}
              onClick={onClose}
            />
            <Button onClick={onSubmit} className="approveButton">
              Confirm
            </Button>
          </DialogButtonsWrapper>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default AdminTableRejectDialog;
