import React from 'react'

export interface IconAtomProps {

    icon: string,
    altText: string
}

export const IconAtom: React.FC<IconAtomProps> = (props: IconAtomProps) => {
    const { icon, altText } = props

    return (
        <img src={icon} alt={altText} />
    );
}