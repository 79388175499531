import { Container, Typography } from '@material-ui/core';
import React from 'react'
import { useHistory } from 'react-router-dom';
import { TextButton, TextButtonVersion } from '../../../atoms/TextButton/TextButton';
import { useStyles } from './RedStripStyles';

export interface RedStripProps {
    text: string,
    buttonText: string
}

export const RedStrip: React.FC<RedStripProps> = (props: RedStripProps) => {

    let history = useHistory()

    const { text, buttonText } = props

    const classes = useStyles(props)

    return (
        <Container className={classes.redStrip} maxWidth='xl'>
            <Typography className={classes.stripText}>
                {text}
            </Typography>
            <TextButton onClick={() => history.push('/callus') } text={buttonText} version={TextButtonVersion.LARGE} />
        </Container>
    );
}