import { makeStyles } from "@material-ui/core";
import styled from "styled-components";

export const Container = styled.div`
margin: 0 40px 0 40px;
`;

export const HeaderWrapper = styled.div`
    margin-top: 74px;
    margin-bottom: 54px;
`;

export const useStyles = makeStyles((theme) => ({
  paginationContainer: {
    display: "flex",
    justifyContent: "center",
    marginTop: theme.spacing(2),
  },
  searchField: {
    width: "50%",
    padding: "10px",
    borderRadius: "5px",
    border: "1px solid #323A87",
    fontSize: "16px",
    marginBottom: "10px",
    backgroundColor:"rgba(0, 0, 0, 0.04)",
  },
}));
