import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { largeFontSize, mediumFontSize } from '../../utilities/style';

export interface TextButtonStylesProps {

}
export const useStyles = makeStyles<Theme, TextButtonStylesProps>((theme: Theme) =>
    createStyles({
        mainContentButton: {
            border: '3px solid #FFFFFF',
            borderRadius: '15px',
            width: 400,
            height: 91,
            color: '#ffffff',
            fontSize: largeFontSize.fontSize,
            fontWeight: 'bold',
            textTransform: 'capitalize',
            [theme.breakpoints.down('xs')]: {
                marginTop: theme.spacing(3),
                width: 300,
                height: 70
            }
        },
        button: {
            width: 280,
            height: 58,
            border: '2px solid #ffffff',
            color: '#ffffff',
            textTransform: 'capitalize',
            borderRadius: 15,
            fontSize: mediumFontSize.fontSize,
        },
        buttonPrimary: {
            width: "40%",

            height: "15%",
            borderRadius: '15px',
            textTransform: 'capitalize',
            color: '#211D57',
            padding: 10,
            fontSize: mediumFontSize.fontSize,
            background: "transparent ",
            border: '2px solid #211D57',

        }

    }),
);

