import { Link } from '@material-ui/core';
import React from 'react'
import { useStyles } from './MenuItemStyles';

export interface MenuItemProps {
    text: string,
    underline: "none" | "always" | "hover" | undefined,
    onClick: React.MouseEventHandler
}

export const MenuItem: React.FC<MenuItemProps> = (props: MenuItemProps) => {
    const { text , underline, onClick} = props
    const { menuItems } = useStyles(props)
    return (
        <Link component="button" onClick={onClick} underline={underline} className={menuItems}>{text}</Link>
    );
}