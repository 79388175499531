export interface SelectOption {
  value: string;
  label: string
}

export const ReportTypeOptions: Array<SelectOption> = [
  {
    value: "DebitMemo Report",
    label: "DebitMemo Report",
  },
  {
    value: "Return Report",
    label: "Return Report",
  },
  {
    value: "Responses",
    label: "Responses",
  },
  {
    value: "Reconciliation Statement",
    label: "Reconciliation Statement",
  },
]

export const CompanyTypeOptions: Array<SelectOption> = [
  {
    value: "Independent Pharmacy",
    label: "Independent Pharmacy",
  },
  {
    value: "Long-term Care",
    label: "Long-term Care",
  },
]

export const UnitedStatesOptions: Array<SelectOption> = [
   {
     value: "ALABAMA",
     label: "Alabama",
   },
   {
     value: "ALASKA",
     label: "Alaska",
   },
   {
     value: "ARIZONA",
     label: "Arizona",
   },
   {
     value: "ARKANSAS",
     label: "Arkansas",
   },
   {
     value: "ARMED_FORCES_AMERICA",
     label: "Armed Forces America",
   },
   {
     value: "ARMED_FORCES_EUROPE",
     label: "Armed Forces Europe",
   },
   {
     value: "ARMED_FORCES_PACIFIC",
     label: "Armed Forces Pacific",
   },
   {
     value: "CALIFORNIA",
     label: "California",
   },
   {
     value: "COLORADO",
     label: "Colorado",
   },
   {
     value: "CONNECTICUT",
     label: "Connecticut",
   },
   {
     value: "DELAWARE",
     label: "Delaware",
   },
   {
     value: "DISTRICT_OF_COLUMBIA",
     label: "District of Columbia",
   },
   {
     value: "FLORIDA",
     label: "Florida",
   },
   {
     value: "GEORGIA",
     label: "Georgia",
   },
   {
     value: "HAWAII",
     label: "Hawaii",
   },
   {
     value: "IDAHO",
     label: "Idaho",
   },
   {
     value: "ILLINOIS",
     label: "Illinois",
   },
   {
     value: "INDIANA",
     label: "Indiana",
   },
   {
     value: "IOWA",
     label: "Iowa",
   },
   {
     value: "KANSAS",
     label: "Kansas",
   },
   {
     value: "KENTUCKY",
     label: "Kentucky",
   },
   {
     value: "LOUISIANA",
     label: "Louisiana",
   },
   {
     value: "MAINE",
     label: "Maine",
   },
   {
     value: "MARYLAND",
     label: "Maryland",
   },
   {
     value: "MASSACHUSETTS",
     label: "Massachusetts",
   },
   {
     value: "MICHIGAN",
     label: "Michigan",
   },
   {
     value: "MINNESOTA",
     label: "Minnesota",
   },
   {
     value: "MISSISSIPPI",
     label: "Mississippi",
   },
   {
     value: "MISSOURI",
     label: "Missouri",
   },
   {
     value: "MONTANA",
     label: "Montana",
   },
   {
     value: "NEBRASKA",
     label: "Nebraska",
   },
   {
     value: "NEVADA",
     label: "Nevada",
   },
   {
     value: "NEW_HAMPSHIRE",
     label: "New Hampshire",
   },
   {
     value: "NEW_JERSEY",
     label: "New Jersey",
   },
   {
     value: "NEW_MEXICO",
     label: "New Mexico",
   },
   {
     value: "NEW_YORK",
     label: "New York",
   },
   {
     value: "NORTH_CAROLINA",
     label: "North Carolina",
   },
   {
     value: "NORTH_DAKOTA",
     label: "North Dakota",
   },
   {
     value: "OHIO",
     label: "Ohio",
   },
   {
     value: "OKLAHOMA",
     label: "Oklahoma",
   },
   {
     value: "OREGON",
     label: "Oregon",
   },
   {
     value: "PENNSYLVANIA",
     label: "Pennsylvania",
   },
   {
     value: "RHODE_ISLAND",
     label: "Rhode Island",
   },
   {
     value: "SOUTH_CAROLINA",
     label: "South Carolina",
   },
   {
     value: "SOUTH_DAKOTA",
     label: "South Dakota",
   },
   {
     value: "TENNESSEE",
     label: "Tennessee",
   },
   {
     value: "TEXAS",
     label: "Texas",
   },
   {
     value: "UTAH",
     label: "Utah",
   },
   {
     value: "VERMONT",
     label: "Vermot",
   },
   {
     value: "WASHINGTON",
     label: "Washington",
   },
   {
     value: "WEST_VIRGINIA",
     label: "West Virginia",
   },
   {
     value: "WISCONSIN",
     label: "Wisconsin",
   },
   {
     value: "WYOMING",
     label: "Wyoming",
   }
 ]