import React, { useEffect, useState } from "react";
import { HeaderButton } from "../../atoms/HeaderButton/HeaderButton";
import { useStyles, ListWrapper } from "./HeaderMoleculeStyles";
import { default as Headphone } from "../../assets/headphone.svg";
import { default as UserIcon } from "../../assets/userIcon.svg";
import { default as LoginIcon } from "../../assets/loginIcon.svg";
import { Icon } from "../../atoms/Icon Button/Icon";
import { HeaderMenu } from "../HeaderContent/HeaderMenu/HeaderMenu";
import { MenuItemProps } from "../../atoms/HeaderContent/MenuItem/MenuItem";
import { useHistory } from "react-router-dom";
import { useAuthDispatch, useAuthState } from "../../context/AuthContext";
import ExpandableList from "../../atoms/ExpandableList/ExpandableListAtom";
import PersonIcon from "@material-ui/icons/Person";
import { logout } from "../../context/actions/authActions";
import { mediumFontSize, smallFontSize } from "../../utilities/style";
import { isNormalUser } from "../../utilities/checkUserRole";
import * as strings from '../../utilities/strings'
export interface HeaderMoleculeProps {
  openNav: () => void;
  closeNav: () => void;
  width: string;
  menuItems?: Array<MenuItemProps>;
}

export const HeaderMolecule: React.FC<HeaderMoleculeProps> = (
  props: HeaderMoleculeProps
) => {
  const {
    closeNav,
    openNav,
    width,
    menuItems = [
      {
        text: strings.homeTitle,
        underline: "always",
        onClick: () => history.push("/"),
      },
      {
        text: strings.whatAreWeTitle,
        underline: "none",
        onClick: () => history.push("/services"),
      },
      {
        text: strings.compliance,
        underline: "none",
        onClick: () => history.push("/about"),
      },
    ],
  } = props;
  const classes = useStyles(props);
  const [windowSize, setWindowSize] = useState<boolean>(
    window.innerWidth > 959
  );
  const { token, user } = useAuthState();
  const userRole = user?.role;
  const dispatch = useAuthDispatch();
  const history = useHistory();
  useEffect(() => {
    const handleWindowResize = () =>
      setWindowSize(window.innerWidth > 959 ? true : false);
    window.addEventListener("resize", handleWindowResize);

    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);
  return windowSize ? (
    <div className={classes.navbar}>
      <HeaderButton
        onClick={() => history.push("/contact")}
        fontSize={smallFontSize.fontSize}
        label={"Contact Info & Details"}
        textColor="#ffffff"
        background=""
        icon={Headphone}
        width=""
      />
      {token ? (
        <ListWrapper>
          <ExpandableList
            options={[
              isNormalUser(userRole!) ? {
                onClick: () => {
                  history.push("/pharmacy/add")
                },
                label: "Add New Pharmacy",
                icon: <></>,
              } : undefined,
              {
                onClick: () => {
                  history.push("/forget-password");
                },
                label: "Reset Password",
                icon: <></>,
              },
              {
                onClick: () => {
                  logout(dispatch);
                },
                label: "Log out",
                icon: <></>,
              },
            ]}
            headerLabel={`${user?.username}`}
            headerIcon={<PersonIcon />}
          />
        </ListWrapper>
      ) : (
        <div className={classes.menu}>
          <HeaderButton
            onClick={() => history.push("/register")}
            fontSize={mediumFontSize.fontSize}
            label="Register"
            textColor="#ffffff"
            background=""
            icon={UserIcon}
            width=""
          />
          <HeaderButton
            onClick={() => history.push("/signin")}
            fontSize={mediumFontSize.fontSize}
            label="Login"
            textColor="#ffffff"
            background="transparent linear-gradient(107deg, #A61C14 0%, #530E0A 100%) 0% 0% no-repeat padding-box"
            icon={LoginIcon}
            width="188px"
          />
        </div>
      )}
    </div>
  ) : (
    <div className={classes.navbarMob}>
      <div className={classes.menu}>
        <HeaderMenu
          openNav={openNav}
          closeNav={closeNav}
          items={menuItems}
          width={width}
        />
      </div>
      {token ? (
        <ListWrapper>
          <ExpandableList
            options={[
              {
                onClick: () => {
                  history.push("/forget-password");
                },
                label: "Reset Password",
                icon: <></>,
              },
              {
                onClick: () => {
                  logout(dispatch);
                },
                label: "Log out",
                icon: <></>,
              },
            ]}
            // headerLabel={`${user?.firstName} ${user?.lastName}`}
            headerLabel={`${user?.username}`}
            headerIcon={<PersonIcon />}
          />
        </ListWrapper>
      ) : (
        <Icon onClick={() => history.push("/signin")} icon={UserIcon} />
      )}
    </div>
  );
};
