import styled from "styled-components";
export const Container = styled.div`
  margin: 0 40px 0 40px;
`;

export const HeaderWrapper = styled.div`
  margin-top: 74px;
  margin-bottom: 54px;
`;

export const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  > div {
    width: 80%;
    margin-top: 20px;
  }
  > button {
    margin-top: 20px;
    margin-bottom: 10px;
  }
  > div {
    > div {
      width: 100%;
    }
  }
`;


export const TitleWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 15px;
  margin-bottom: 10px;
`;

export const DialogButtonsWrapper = styled.div`
  .approveButton {
    background: #323a87 0% 0% no-repeat padding-box;
  }
  .commentButton {
    background: #403f3f 0% 0% no-repeat padding-box;
  }
  .MuiButtonBase-root {
    width: 120px !important;
    border-radius: 10px;
    margin-right: 11px;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .MuiButton-label {
    font: normal normal bold 21px/25px Roboto, Bold;
    letter-spacing: 0px;
    color: #ffffff;
    font-family: "Arial";
  }
`;