import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { BaseCSSProperties } from "@material-ui/core/styles/withStyles";
import styled from "styled-components";
import { HeaderMoleculeProps } from "./HeaderMolecule";

export interface StyleProps {
  navbar: BaseCSSProperties;
  menu: BaseCSSProperties;
}

export const useStyles = makeStyles<Theme, HeaderMoleculeProps>(
  (theme: Theme) =>
    createStyles({
      navbar: () => ({
        width: "auto",
        height: "65px",
        backgroundColor: "#211D57",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        paddingLeft: "2%",
      }),
      navbarMob: () => ({
        width: "auto",
        height: "65px",
        backgroundColor: "#211D57",
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
      }),
      menu: () => ({
        height: "100%",
        display: "flex",
        alignItems: "center",
        [theme.breakpoints.down("xs")]: {
          marginRight: 20,
        },
      }),
    })
);

export const ListWrapper = styled.div`
  z-index: 9999;
  height: 64px;
  .MuiList-padding {
    width: unset;
    background: linear-gradient(90deg, #a61c14 80%, #530e0a 100%) 0% 0%
      no-repeat padding-box;
    border-radius: 0px;
  }
  .MuiList-padding ~ .MuiCollapse-root {
    margin-top: 90px;
  }
  .MuiList-padding + .MuiCollapse-root {
    margin-top: 90px;
  }
  .MuiListItemText-primary {
    color: white;
  }
  .MuiSvgIcon-root {
    fill: white;
  }
  .MuiList-root {
    width: unset;
  }
  .MuiCollapse-root {
    margin-bottom: -10px;
    background-color: white;
    .MuiListItemText-root > span {
      font: normal normal normal 18px/22px Roboto;
      letter-spacing: 0px;
      color: #403f3f;
    }
  }
  @media screen and (max-width: 959px) {
    margin-left: 20px;
  }
`;
