import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    datePicker: {
      style: '',
      width: "150px",
      height: "45px",
      padding: "0 !important",
      paddingLeft: "4px !important",
      paddingRight: "4px !important",
      font: "normal normal normal 20px/34px Roboto, Arial",
      textAlign: "center",
      border: "1px solid #ccc",
      borderRadius: "15px",
      borderColor: "rgba(0, 0, 0, 0.23) !important",
      color: "#403F3F",
      cursor: "pointer",
    },
  })
);



