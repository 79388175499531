import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  > div {
    width: 80%;
    margin-top: 20px;
  }
  > div {
    > div {
      width: 100%;
    }
  }
`;

export const Container = styled.div`
  margin: 0 40px 0 40px;
`;

export const ButtonWrapper = styled.div`
  margin-top: 5rem
`
