export const smallFontSize = {
  fontSize: 16,
};

export const mediumFontSize = {
  fontSize: 20,
};

export const largeFontSize = {
  fontSize: 24,
};

export const xLargeFontSize = {
  fontSize: 28,
};

export const largeButton = {
  width: "55%",
};

export const mediumButton = {
  width: "30%",
};
