import React from "react";
import { useMutation } from "react-query";
import { useStyles, Container } from "./SignInPageStyle";
import { useForm } from "react-hook-form";
import { SmallBlueButtonAtom } from "../../atoms/SmallBlueButton/SmallBlueButtonAtom";
import {
  BigRedButton,
  ButtonVersion,
} from "../../atoms/BigRedButton/BigRedButtonAtom";
import { loginUser } from "../../network/Services";
import * as strings from "../../utilities/strings";
import { smallFontSize } from '../../utilities/style';
import { withRouter, useHistory } from "react-router-dom";
import { useAuthDispatch, useAuthState } from "../../context/AuthContext";
import FormMolecule from "../../molecules/Form/FormMolecule";
import { useEffect } from "react";
import { useLoaderDispatch, useLoaderState } from "../../context/LoaderContext";
import Loader from "../../atoms/Loader/LoaderAtom";
import { TextBox } from "../../atoms/Text Box/TextBox";
import { Typography } from "@material-ui/core";

export interface SignInPageProps { }

export const SignIn: React.FC<SignInPageProps> = (props) => {
  const { handleSubmit, control, watch } = useForm();
  const username = watch(strings.username);
  const password = watch(strings.password);
  const dispatch = useAuthDispatch();
  const loaderDispatch = useLoaderDispatch();
  const { loading } = useLoaderState();

  const history = useHistory();

  const { mutate } = useMutation(() =>
    loginUser({ username, password, dispatch, loaderDispatch, history })
  );

  const onSubmit = handleSubmit((data) => {
    mutate();
  });
  
  useEffect(() => {
    const listener = (event: any) => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        event.preventDefault();
        onSubmit();
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, [onSubmit]);

  const classes = useStyles(props);

  return (
    <Container>
      {loading ? (
        <Loader />
      ) : (
        <form onSubmit={onSubmit}>
          <div className={classes.cardStyle}>
            <FormMolecule
              control={control}
              list={[
                [
                  {
                    controllingName: strings.username,
                    placeHolder: strings.usernamePlaceholder,
                    rules: {
                      required: "This Field is Required",
                    },
                    disabled: loading,
                  },
                ],
                [
                  {
                    controllingName: strings.password,
                    placeHolder: strings.password,
                    rules: {
                      required: "This Field is Required",
                    },
                    type: "password",
                    disabled: loading,
                  },
                ],
              ]}
            />
            <div className={classes.outerMargin}>
              <SmallBlueButtonAtom
                text={strings.forgetPasswordText}
                onClick={() => {
                  history.push("/forget-password");
                }}
              />
            </div>
            <div className={classes.loginPart}>
              <BigRedButton
                version={ButtonVersion.LARGE}
                text={strings.loginText}
                type="submit"
                disabled={loading}
                onClick={() => {
                  console.log("submitted");
                }}
              />
              <div>
                <Typography className={classes.text} gutterBottom={false} display="inline">
                  Don't have an account?{' '}
                </Typography>
                <SmallBlueButtonAtom
                  text={strings.RegisterHereText}
                  onClick={() => {
                    history.push("/register");
                  }}
                />
              </div>
            </div>
          </div>
        </form>
      )}
    </Container>
  );
};

export default withRouter(SignIn);
