import React from "react";
import { useMemo } from "react";
import {
  MenuItem,
  MenuItemProps,
} from "../../../atoms/HeaderContent/MenuItem/MenuItem";
import { useStyles, Container } from "./HeaderMenuStyles";
import MenuIcon from "@material-ui/icons/Menu";

export interface HeaderMenuProps {
  items: Array<MenuItemProps>;
  openNav: () => void;
  closeNav: () => void;
  width: string;
  showBurger?: boolean;
}

export const HeaderMenu: React.FC<HeaderMenuProps> = (props) => {
  const { items, openNav, closeNav, width, showBurger = true } = props;
  const { menuContent } = useStyles(props);

  const renderMenuItems = () => {
    return items.map((item) => {
      return (
        <MenuItem
          onClick={item.onClick}
          key={item.text}
          text={item.text}
          underline={item.underline}
        />
      );
    });
  };

  const renderBurgerMenuItems = useMemo(() => {
    return items.map((item) => {
      return (
        <a
          onClick={item.onClick}
          key={item.text}
          href=" "
        >
          {item.text}
        </a>
      );
    });
  }, [items]);

  return (
    <Container>
      <div className={menuContent}>{renderMenuItems()}</div>
      <div style={{ width }} className="sidenav">
        <a
          className="closebtn"
          onClick={() => closeNav()}
          href=" "
        >
          &times;
        </a>
        {renderBurgerMenuItems}
      </div>
      {showBurger && (
        <div className="burgerIconWrapper" onClick={() => openNav()}>
          <MenuIcon />
        </div>
      )}
    </Container>
  );
};
